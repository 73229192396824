import React, { useEffect,useState } from 'react';
import { useLocation,useHistory } from 'react-router-dom';
import { useAuth } from '../../services/authContext';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';
import {CreateCheckoutSessionSuccess} from "services/api";


export default function SuccessStripePayment() {
     const history = useHistory();
     const location = useLocation();
    const { user } = useAuth();
    const [successMessage, setSuccessMessage] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const subscriptionId = queryParams.get('subscriptionId');
    const handleGoBack = () => {
        history.push('/admin/dashboard');
    };
    useEffect(() => {
        console.log("user", user);
        const handleSubscriptionSuccess = async () => {
            var successPayment = await CreateCheckoutSessionSuccess(subscriptionId);
            console.log("successPayment", successPayment.message);
            //setSuccessMessage(successPayment);
            if (successPayment.message == "Subscription updated successfully") {
                setSuccessMessage(true);
            }
        }
        handleSubscriptionSuccess();
    }, [subscriptionId]);
    return (
        <div className="success-stripe-payment">
            {successMessage ? (
                <>
                    <h1>Payment Successful!</h1>
                    <p>Thank you for your purchase. Your payment has been processed successfully.</p>
                    <button onClick={handleGoBack}>Go to Home</button>
                </>
            ) : (
                <p>Processing your payment...</p>
            )}
        </div>
    );
};

