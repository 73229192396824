import React from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../services/authContext';
import { Button } from '@material-ui/core';
import { SaveRounded } from "@material-ui/icons";
import { getUsersFolder, updateFileFolder } from '../../services/api';
import 'rc-tree/assets/index.css';
import { TreeView, TreeItem } from '@material-ui/lab'
//import { Delete } from '@material-ui/icons';
import { ArrowRight, ArrowDropDown } from '@material-ui/icons';
import { Folder, LibraryMusic } from '@material-ui/icons';
import CustomFolderOpenIcon from "assets/img/FolderOpenIcon.png";
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';
import 'rc-tree/assets/index.css';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { getCookie } from "components/Utils/cookies";
import { getModelTranscriptionResource1 } from "services/api";
import { Card, IconButton, TextField, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { CloudUpload } from '@material-ui/icons';
import start from "assets/img/startIcon.jpg";
import stop from "assets/img/stopIcon.png";
import { MicNone, MicOff } from '@material-ui/icons';


const StartRecord = () => (
    <img src={start} alt="Start Recording" style={{ width: 20, height: 20 }} />
);
const StopRecord = () => (
    <img src={stop} alt="Stop Recording" style={{ width: 20, height: 20 }} />
);
const FolderOpenIcon = () => (
    <img src={CustomFolderOpenIcon} alt="Open Folder" style={{ width: 20, height: 20 }} />
);
const RecordNowAudio = ({ isOpenRecordNow, onRequestCloseRecordNow }) => {
    const { user } = useAuth();
    const [usersFolder, setUsersFolder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFolderId, setSelectedFolderId] = useState('');
    const [editingFolderId2, setEditingFolderId2] = useState(null);
    const [expanded, setExpanded] = useState([]);
    const [treeData2, setTreeData2] = useState([]);
    const [parentFolderValue, setParentFolderValue] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [finalTranscription, setFinalTranscription] = useState("");
    const [currentTranscription, setCurrentTranscription] = useState("");
    const [audioBlob, setAudioBlob] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const transcriberRef = useRef(null);
    const speakerMapRef = useRef(new Map());
    const speakerCounterRef = useRef(0);
    const lastSpeakerIdRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    const [isUploading, setUpLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [models, setModels] = useState([]);
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const CHUNK_SIZE = 1024 * 1024;

    const LoadingIndicator = () => (
        <div className="uploading-indicator">
            {isUploading && <p style={{ fontWeight: 'bolder', fontSize: '20px' }}>Uploading<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>}
        </div>
    );

    const startMicRecording = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Your browser does not supported audio recording.');
            return;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);

            recorder.ondataavailable = (e) => setAudioBlob(e.data);
            recorder.start();

            setMediaRecorder(recorder);
            setIsRecording(true);

        }
        catch (err) {
            console.error("Error accessing microphone", err);
        }
    }

    const stopMicRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setMediaRecorder(null);
            setIsRecording(false);
        }
    }

    const startTranscription = async () => {
        if (isListening) return;

        try {
            const serviceRegion = "eastus2";
            const apiKey = "f74e2a29e67943ffbf21a151f6087d40";

            const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(apiKey, serviceRegion);
            speechConfig.speechRecognitionLanguage = 'en-US';


            speechConfig.setProperty("ConversationTranscription_SpeakerDiarizationEnabled", 'true');

            const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
            const transcriber = new SpeechSDK.ConversationTranscriber(speechConfig, audioConfig);

            transcriberRef.current = transcriber;

            await startMicRecording();

            setIsListening(true);

            // Handle real-time transcription
            transcriber.transcribing = (sender, e) => {
                console.log(sender);
                const result = e.result;
                if (result.reason === SpeechSDK.ResultReason.RecognizingSpeech) {
                    const json = JSON.parse(result.json);
                    const speakerId = json.SpeakerId || 0;

                    // Assign a new label if SpeakerId changes or is missing
                    if (!speakerMapRef.current.has(speakerId) || lastSpeakerIdRef.current !== speakerId) {
                        if (!speakerMapRef.current.has(speakerId)) {
                            speakerMapRef.current.set(speakerId, `Speaker ${speakerCounterRef.current}`);
                            speakerCounterRef.current += 1;
                        }
                        lastSpeakerIdRef.current = speakerId;
                    }

                    setCurrentTranscription(`Speaker...: ${result.text}`);
                }
            };

            // Handle finalized transcription
            transcriber.transcribed = (sender, e) => {
                console.log(sender);
                const result = e.result;
                if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
                    const json = JSON.parse(result.json);
                    const speakerId = json.SpeakerId || 0;

                    // Assign a new label if SpeakerId changes or is missing
                    if (!speakerMapRef.current.has(speakerId)) {
                        speakerMapRef.current.set(speakerId, `Speaker ${speakerCounterRef.current}`);
                        speakerCounterRef.current += 1;
                    }

                    const speakerLabel = speakerMapRef.current.get(speakerId);

                    // Append finalized transcription
                    setFinalTranscription((prev) => `${prev}\n${speakerLabel}: ${result.text}`.trim());
                    setCurrentTranscription(""); // Clear live updates for this segment
                }
            };

            transcriber.sessionStopped = () => {
                console.log("Session stopped.");

                if (transcriberRef.current) {
                    transcriberRef.current.close();
                    transcriberRef.current = null;
                }
                setIsListening(false);
            };

            transcriber.startTranscribingAsync();


        }
        catch (err) {
            console.log(err);
        }
    }

    const stopTranscription = () => {
        if (transcriberRef.current) {
            transcriberRef.current.stopTranscribingAsync(() => {
                console.log("Transcription stopped.");
                if (transcriberRef.current) {
                    transcriberRef.current.close();
                    transcriberRef.current = null;
                }
                setIsListening(false);
            });
        }

        stopMicRecording();
    };
    const uploadRecordings = async () => {
        if (!audioBlob) {
            alert("No audio recorded to upload.");
            return;
        }

        setUpLoading(true);
        const file = new File([audioBlob], `Recordings_audio_${Date.now()}.webm`);

        const fileSize = file.size;
        const fileName = file.name;
        const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
        let completedChunks = 0;

        const token = getCookie('jwtToken');
        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, fileSize);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append("chunk", chunk);
            formData.append("fileName", fileName);
            formData.append("fileSize", fileSize);
            formData.append("chunkIndex", chunkIndex);
            formData.append("totalChunks", totalChunks);

            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/UploadChunk", {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    console.error("Failed to upload chunk:", chunkIndex);
                    setUpLoading(false);
                    setUploadProgress(0); // Reset progress on failure
                    return; // Stop if chunk upload fails
                }

                completedChunks++;
                const progressPercentage = Math.floor((completedChunks / totalChunks) * 100);
                setUploadProgress(progressPercentage); // Update progress
            } catch (error) {
                console.error("Error uploading chunk:", error);
                setUpLoading(false);
                setUploadProgress(0); // Reset progress on error
                return; // Stop on error
            }
        }


        const completeResponse = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/CompleteUpload", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                FileName: fileName,
                FileSize: fileSize,
                AudioTitleName: "My Audio Title",
                UserEmail: user.email,
                UserName: user.name,
                CustomModel: selectedModel,
                FolderId: null,
                IsUploadFromOneDrive: false,
                FileUrl: ""
            })
        });
        console.log("Complete response1:", completeResponse);
        if (!completeResponse.ok) {
            onRequestCloseRecordNow();
            console.error("Failed to complete upload");
            return;
        }
        onRequestCloseRecordNow();
        const responseData = await completeResponse.json();
        console.log("Complete response:", responseData);
        setUploadProgress(0);
        return { url: 'https://httpbin.org/post' };
    }
    const fetchModels = async () => {
        try {
            setLoading(true);
            const modelsData = await getModelTranscriptionResource1();
            console.log("Fetched models:", modelsData);
            const sortedModels = modelsData.sort((a, b) =>
                b.displayName.localeCompare(a.displayName)
            );

            setModels(sortedModels);
            console.log("Fetched models:", sortedModels);
            setSelectedModel(sortedModels[2]?.self || '');
            console.log("Selected model:", selectedModel);
        } catch (error) {
            console.error("Error fetching models:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchModels();
    }, []);

    //----------------------------------------Not needed-------------------------------------------------
    useEffect(() => {
        const fetchUsersFolder = async () => {
            setLoading(true);

            try {
                if (user == null) {
                    return console.error('user is empty');
                }

                const result = await getUsersFolder(user.email);
                console.log(result);
                setTreeData2(buildTree2(result));
                setUsersFolder(result);
            }
            catch (error) {
                console.error("Error fetching folders:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchUsersFolder();
    }, [user]);
    useEffect(() => {
        const transformedData = buildTree2(usersFolder);
        setTreeData2(transformedData);
    }, [usersFolder, editingFolderId2])


    function buildTree2(data) {
        console.log(data);
        const idToNodeMap = {};
        const resultTree = [];

        // First, map all items (folders) into a dictionary
        data.forEach((item) => {
            idToNodeMap[item.id] = {
                id: item.id,
                name: item.name,
                isParent: item.isParent,
                children: [],
            };

            // If this item has files, add them to children
            if (Array.isArray(item.files) && item.files.length > 0) {
                item.files.forEach((file) => {
                    idToNodeMap[item.id].children.push({
                        id: file.id,
                        name: file.name || `File ${file.id}`,
                        isParent: false,
                    });
                });
            }
        });

        // Second, assign folders to their parents
        data.forEach((item) => {
            if (item.parentId && idToNodeMap[item.parentId]) {
                idToNodeMap[item.parentId].children.push(idToNodeMap[item.id]);
            } else {
                resultTree.push(idToNodeMap[item.id]);
            }
        });

        return resultTree;
    }

    const handleUpdateFileFolder = async () => {
        if (selectedFolderId == '') {
            console.log('No selected folder');
            return;
        }

        const payload =
        {
            id: selectedFolderId,
            name: parentFolderValue,
            isParent: false,
            parentId: null,
            userId: 0,
            email: user.email,
            fileId: null
        };

        var response = await updateFileFolder(payload);

        setUsersFolder(response);

        const updatedTreeData = buildTree2(response);
        setTreeData2(updatedTreeData);
    }
    const renderTree2 = (nodes) =>
        nodes.map((node) => (
            <TreeItem
                key={node.id}
                nodeId={node.id.toString()}
                label={
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        {/* Expand/Collapse Arrow - Default Click Behavior */}
                        <span
                            onClick={(e) => e.stopPropagation()} // Prevents propagation to prevent unwanted expand/collapse
                            style={{ cursor: "pointer" }}
                        >
                            {node.isParent ? (
                                expanded.includes(node.id) ? (
                                    <Folder style={{ color: "#6c757d" }} />
                                ) : (
                                    <FolderOpenIcon style={{ color: "#6c757d" }} />
                                )
                            ) : (
                                <LibraryMusic
                                    style={{
                                        color: node.name.endsWith(".mp3")
                                            ? "purple"
                                            : node.name.endsWith(".mp4")
                                                ? "blue"
                                                : node.name.endsWith(".wav")
                                                    ? "indigo"
                                                    : "teal",
                                    }}
                                />
                            )}
                        </span>

                        {/* Folder Name - Prevent Expand/Collapse & Capture Click */}
                        <span
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents the default expand/collapse behavior
                                if (node.isParent) {
                                    console.log("Clicked folder ID:", node.id);
                                    setSelectedFolderId(node.id);// Capture Folder ID only
                                    setParentFolderValue(node.name);
                                }// Capture Folder ID on Click
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault(); // Prevent default right-click menu
                                setEditingFolderId2(node.id);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {node.name}
                        </span>
                    </div>
                }
            >
                {Array.isArray(node.children) && node.children.length > 0 && renderTree2(node.children)}
            </TreeItem>
        ));



    return (
        <Modal
            open={isOpenRecordNow}
            onClose={onRequestCloseRecordNow}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
                onClick: (e) => e.stopPropagation(),
            }}
        >

            <div className="modalContainer">
                <div className="modalContentUpload" style={{ width: "60%", background: "#FFFF", borderRadius: "20px", maxWidth: "600px", left: "56%", top: "45vh" }}>

                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                        <div>
                            <span className='upload-modal-header'>Record Now</span>
                        </div>
                        <div>
                            <button onClick={onRequestCloseRecordNow} className="modalBtnClose" style={{ fontSize: "30px", color: "rgb(57 56 56)" }}>
                                &times;
                            </button>
                        </div>
                    </div>

                    {/*  */}
                    <div className='select-model-upload' style={{ marginTop: '20px' }}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="modelSelectLabel">Select Custom Model</InputLabel>
                            <Select
                                labelId="modelSelectLabel"
                                id="modelSelect"
                                value={selectedModel}
                                onChange={(e) => setSelectedModel(e.target.value)}
                            >
                                {loading ? (
                                    <MenuItem disabled>
                                        <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                        Loading models...
                                    </MenuItem>
                                ) : (
                                    models.map((model) => (
                                        <MenuItem key={model.self} value={model.self}>
                                            {model.displayName}
                                        </MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <Card style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                              <div style={{ display: 'none' }}>
                              <IconButton>
                                    {isRecording ?
                                        <StopRecord onClick={stopTranscription} /> :
                                        <StartRecord onClick={startTranscription} />
                                    }
                                </IconButton>
                              </div>
                            

                                <IconButton>
                                    {isRecording ?
                                        <MicOff style={{ color: '#FF9800' }} onClick={stopTranscription} /> :
                                        <MicNone style={{ color: '#E91E63' }} onClick={startTranscription} />}
                                </IconButton>
                                {isRecording ?
                                    <Typography style={{ paddingTop: '10px',fontSize:'15px',color:'#676' }}>Recording <span className="dotRecording"></span><span className="dotRecording"></span><span className="dotRecording"></span></Typography> :
                                    <Typography style={{ paddingTop: '10px' }}>Record Now</Typography>}

                            </div>
                            <div style={{ marginBottom: '15px', marginLeft: '20px', marginRight: '20px' }}>

                                <TextField
                                    className='rn-transcription-textarea'
                                    multiline minRows={5}
                                    variant='outlined'
                                    fullWidth
                                    value={`${finalTranscription}\n${currentTranscription}`.trim()}
                                    InputProps={{ readOnly: true }}>

                                </TextField>

                                <div style={{
                                    display: finalTranscription && !isListening ? 'flex' : 'none',
                                    justifyContent: 'end'
                                }}>

                                    <Button
                                        style={{
                                            marginTop: '20px',
                                            backgroundColor: '#009688',
                                            color: '#FAFAFA',
                                            display: isUploading ? 'none' : ''
                                        }}
                                        variant='outlined'
                                        onClick={uploadRecordings}
                                        startIcon={<CloudUpload />}>Upload</Button>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div>{isUploading && <LoadingIndicator />}</div>
                    {uploadProgress > 0 && uploadProgress < 100 && (
                        <div style={{ marginTop: '10px' }}>
                            <progress value={uploadProgress} max={100} style={{ width: '100%' }}>
                                {uploadProgress}%
                            </progress>
                            <span>{uploadProgress}%</span>
                        </div>
                    )}

                    {/*  */}







                    <div style={{ margin: "40px", display: 'none' }}>

                        <div className="folderTreeView">
                            {loading ? "Loading..." : (
                                <TreeView
                                    expanded={expanded}
                                    onNodeToggle={handleToggle}
                                    defaultCollapseIcon={<ArrowDropDown />}
                                    defaultExpandIcon={<ArrowRight />}
                                >
                                    {renderTree2(treeData2)}
                                </TreeView>
                            )}
                        </div>
                    </div>
                    <div style={{ float: 'right', marginTop: '20px', display: 'none' }}>
                        {treeData2.length == 0 ? '' :
                            <Button
                                className='createfolderBtn'
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<SaveRounded />}
                                onClick={handleUpdateFileFolder}
                            >
                                Save
                            </Button>
                        }

                    </div>
                </div>
            </div>
        </Modal>
    );
}

RecordNowAudio.propTypes = {
    isOpenRecordNow: PropTypes.bool.isRequired,
    onRequestCloseRecordNow: PropTypes.func.isRequired
};

export default RecordNowAudio;