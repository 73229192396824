console.log('Backend URL:', process.env.REACT_APP_API_URL);
import axios from "axios";
const baseURL2 = process.env.REACT_APP_BASE_URL || 'https://localhost:7218';
//production
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

console.log(baseURL2); 
//development
// const apiClient = axios.create({
//   baseURL: "https://wa-audioly-dev-aueast-002.azurewebsites.net",
// });

//local
// const apiClientlocalhost = axios.create({
//   baseURL: "https://localhost:7218",
// });

export const loginAccount = async (credentials) => {
  console.log('Backend URL:', process.env.REACT_APP_API_URL);
  // Make a POST request to the login endpoint with the provided credentials
  console.log("Logging in with credentials:", credentials); // Log credentials
  const response = await apiClient.post("/api/User/Login", credentials);
  return response.data; // Return user data or token
};

export const fetchData = async () => {
  try {
    const response = await apiClient.get("/data"); // Replace with your API endpoint
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postData = async (data) => {
  try {
    const response = await apiClient.post("/data", data); 
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const createAccount = async (data) => {
  try {
    const response = await apiClient.post("/api/User/CreateAccount", data); 
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const fetchFilesByEmail = async (email) => {
  try {
    // const response = await fetch(
    //   `https://wa-audioly-dev-aueast-002.azurewebsites.net/api/File/GetAllByEmail?email=${email}`
    // );
    const response = await apiClient.get("/api/File/GetAllByEmail", {
      params: { email },
    });

    const data = response.data; // Axios automatically parses JSON
    if (!Array.isArray(data)) {
      throw new Error("Expected data to be an array");
    }
    return data;
  } catch (error) {
    console.error("Error in fetchFilesByEmail:", error);
    throw error;
  }
};
export const getFileWithSasUrl = async (fileId) => {
  try {
    const response = await apiClient.post("/api/Upload/GetFileWithSasUrl", null,
      {
        params: { fileId }
      });

    return response.data;
  }
  catch (error) {
    console.error("Error fetching transcription status:", error);
    throw error;
  }
};

export const getUser = async (email, name) => {
  try {
    const response = await apiClient.get("/api/User/GetUser", {
      params: { email, name },
    });

    const data = response.data; // Axios automatically parses JSON
    return data;
  } catch (error) {
    console.error("Error in getUser:", error);
    throw error;
  } // <-- Ensure this closing brace is correctly placed
};

// Call CheckConvertedFile API
export const checkConvertedFile = async (email) => {
  try {
    const response = await apiClient.post("/api/Upload/CheckConvertedFile", null, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkConvertedFile:", error);
    throw error;
  }
};

// Call CheckRunningBatchTranscriptionV2 API
export const checkRunningBatchTranscription = async (email) => {
  try {
    const response = await apiClient.post("/api/Transcription/CheckRunningBatchTranscriptionV2", null, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkRunningBatchTranscription:", error);
    throw error;
  }
};

export const checkRunningBatchReTranscription = async (email) => {
  try {
    const response = await apiClient.post("/api/Transcription/CheckRunningBatchReTranscription", null, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkRunningBatchTranscription:", error);
    throw error;
  }
};
// Delete audio
export const deleteAudio = async (id, url) => {
  try {
    const response = await apiClient.post("/api/File/DeleteAudio", { id, url }); // Replace with your API endpoint
    console.log("response", response);
    return response;
  } catch (error) {
    console.error("Error deleting audio:", error);
    throw error;
  }
};

export const reTranscribe = async (fileId, transcriptionId) => {
  try {
    const response = await apiClient.post("/api/Transcription/ReTranscribe", null,
      {
        params: { fileId, transcriptionId }
      });

    console.log(response.data);

    return response;
  }
  catch (error) {
    console.error("Error retranscribing file:", error);
    throw error;
  }
}
export const reTranscribeV2 = async (fileId, transcriptionId, customModel, email) => {
  try {
    const response = await apiClient.post("/api/Upload/ReTranscribe", null,
      {
        params: { fileId, transcriptionId, customModel, email }
      });

    console.log(response.data);

    return response;
  }
  catch (error) {
    console.error("Error retranscribing file:", error);
    throw error;
  }
}

export const checkTranscriptionStatus = async (transcriptionId) => {
  try {
    const response = await apiClient.get("/api/transcription/GetTranscriptionStatus",
      {
        params: { transcriptionId }
      });

    return response.data;
  }
  catch (error) {
    console.error("Error fetching transcription status:", error);
    throw error;
  }
}

export const getDashboardDetails = async (email) => {
  try {
    const response = await apiClient.get("/api/dashboard/GetDashboardDetails",

      {
        params: { email }
      }
    );

    return response.data;
  }
  catch (error) {
    console.error("Error fetching dashboard details: ", error);
    throw error;
  }
}

export const getUserProfilePhoto = async (accessToken) => {
  try {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers: headers
    };

    return fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options)
      .then(response => {
        if (!response.ok) {
          return '';
        }

        return response.blob();
      })
      .then(blob => {
        // Create a URL for the image
        const imageUrl = blob != '' ? URL.createObjectURL(blob) : '';
        return imageUrl;
      });

  }
  catch (error) {
    console.error("Error fetching user photo:", error);
    throw error;
  }
}

export const getModelTranscriptionResource1 = async () => {
  try {
    const response = await apiClient.get("/api/Upload/GetModelTranscriptionResource1");
    return response.data;
  }
  catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export const UpsertTranscriptionWord = async (payload) => {
  try {
    const response = await apiClient.post("/api/TranscriptionWord/Upsert", payload);

    return response;
  }
  catch (error) {
    console.error(error);
  }
}

export const InsertCustomButton = async (payload) => {
  try {
    const response = await apiClient.post("/api/QandA/InserCustomButton", payload);

    return response;
  }
  catch (error) {
    console.error(error);
  }
}
export const UpdateCustomButton = async (payload) => {
  try {
    console.log("payload", payload);
    const response = await apiClient.post("/api/QandA/UpdateCustomButton", payload);

    return response;
  }
  catch (error) {
    console.error(error);
  }
}

export const SendtoAzureChat = async (transId, questionText, email) => {
  try {
    const response = await apiClient.post("/api/QandA/SendtoAzureAIchat", null,
      {
        params: { transId, questionText, email }
      });

    console.log(response.data);

    return response;
  }
  catch (error) {
    console.error("Error retranscribing file:", error);
    throw error;
  }
}
export const GetCustomButtons = async (email) => {
  try {
    const response = await apiClient.get("/api/QandA/GetCustomButton", {
      params: { email }
    });

    console.log(response.data);

    return response.data.map(btn => ({
      icon: btn.icon,
      text: btn.questionText,
      id: btn.id
    }));
  } catch (error) {
    console.error("Error fetching custom buttons:", error);
    throw error;
  }
}
export const getChatHistory = async (transId, email) => {
  try {
    const response = await apiClient.get("/api/QandA/GetConversationHistory", {
      params: { transId, email }
    });

    console.log(response.data);

    return response.data;
  } catch (error) { 
    console.error("Error fetching chat history:", error);
    throw error;
  }
}

export const Log = async (payload) => {
  try {
    const response = await apiClient.post("/api/log/log", payload);

    return response;
  }
  catch (error) {
    console.error(error)
  }
}
export const deleteCustomButton = async (id) => {
  try {
    console.log("response", id);
    const response = await apiClient.post("/api/QandA/DeleteCustomButton", null, {
      params: { id }
    });
    console.log("response", response);
    return response;
  } catch (error) {
    console.error("Error deleting custom button:", error);
    throw error;
  }
}


export const deleteTranscriptionWord = async (id, transcriptionId) =>
{
  try
  {
    const response = await apiClient.delete("/api/transcriptionWord/delete", {
      params: { 
        id : id, 
        transcriptionId: transcriptionId
       }
    });

    return response.data;
  }
  catch(error)
  {
    console.error("Error deleting custom button:", error);
    throw error;
  }
}

export const getTranscriptionDetailsByTimestamp = async (id, startTime, endTime) =>
{
  try
  {
    var response = await apiClient.get("/api/transcriptionWord/getTranscriptionDetailsByTimestamp",
      {
        params: 
        {
          transcriptionId: id,
          startTime: startTime,
          endTime: endTime
        }
      });

    console.log(response.data);
    return response.data;
  }
  catch(error)
  {
    console.error("Error deleting custom button:", error);
    throw error;
  }
}