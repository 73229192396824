import React from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { useState, useEffect } from 'react';
import { useAuth } from '../../services/authContext';
import { Button  } from '@material-ui/core';
// import { RichTreeView } from '@mui/x-tree-view/RichTreeView'
// import RichTreeView from '@material-ui/RichTreeView'

const MoveFile = ({ isOpenMoveFile, handleCloseMoveFile, selectedFile }) => {
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();
    const MUI_X_PRODUCTS = [
        {
          id: 'grid',
          label: 'Data Grid',
          children: [
            { id: 'grid-community', label: '@mui/x-data-grid' },
            { id: 'grid-pro', label: '@mui/x-data-grid-pro' },
            { id: 'grid-premium', label: '@mui/x-data-grid-premium' },
          ],
        },
        {
          id: 'pickers',
          label: 'Date and Time Pickers',
          children: [
            { id: 'pickers-community', label: '@mui/x-date-pickers' },
            { id: 'pickers-pro', label: '@mui/x-date-pickers-pro' },
          ],
        },
        {
          id: 'charts',
          label: 'Charts',
          children: [{ id: 'charts-community', label: '@mui/x-charts' }],
        },
        {
          id: 'tree-view',
          label: 'Tree View',
          children: [{ id: 'tree-view-community', label: '@mui/x-tree-view' }],
        },
      ];
      


    useEffect(() => {
        setLoading(true);
       console.log(user?.email);
       console.log(selectedFile);
       console.log(MUI_X_PRODUCTS);
    }, []);


    return (
        <Modal
            open={isOpenMoveFile}
            onClose={handleCloseMoveFile}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className="modalContent"
                style={{
                    width: '300px',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    margin: 'auto',
                }}>
                <div>
                    <button
                        onClick={handleCloseMoveFile}
                        className="modalBtnClose"
                    >
                        &times;
                    </button>
                </div>
                <div style={{ display: 'flex' }}>
                    Test Body Modal {loading}
                    {/* <RichTreeView items={MUI_X_PRODUCTS} /> */}
                </div>
                <div style={{ float: 'right' }}>
                  <Button>
                    Refresh
                  </Button>
                </div>
            </div>
        </Modal>
    );
}

MoveFile.propTypes = {
    isOpenMoveFile: PropTypes.bool.isRequired,
    handleCloseMoveFile: PropTypes.func.isRequired,
    selectedFile: PropTypes.object.isRequired
};

export default MoveFile;