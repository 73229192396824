import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {
  fetchFilesByEmail, getFileWithSasUrl, checkConvertedFile,
  deleteAudio, UpsertTranscriptionWord, InsertCustomButton, checkRunningBatchTranscription, checkRunningBatchReTranscription, UpdateCustomButton, SendtoAzureChat, clearChatHistory, GetCustomButtons,
  deleteCustomButton, deleteTranscriptionWord, getTranscriptionDetailsByTimestamp, getChatHistory, GetFilesAndFolders, addParentFolder
} from "services/api";
import UploadFile from "components/Modal/UploadFile";
import RecordNowAudio from "components/Modal/RecordNowAudio";
import FolderTreeView from "components/Modal/FolderTreeView";
import ImportTeams from "components/Modal/ImportTeams";
//import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl, InputLabel, Popper, Select } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AudioPlayer from 'react-h5-audio-player';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Tab, Tabs } from "@material-ui/core";
import { TextField, Checkbox, Link } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import { Check, CloudUpload, Undo, Edit, Close, SaveRounded, CreateNewFolderRounded } from "@material-ui/icons";
import 'react-h5-audio-player/lib/styles.css';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { useAuth } from '../../services/authContext';
import PropTypes from 'prop-types';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import SendIcon from '@material-ui/icons/Send';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import ReactMarkdown from 'react-markdown';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import DownloadTranscirption from "components/Modal/DownloadTranscirption";
import ReTranscribe from "components/Modal/ReTranscribe";
import { Mic } from "@material-ui/icons";
import { Folder } from "@material-ui/icons";
import MoveFile from "components/Modal/MoveFile";
import { throttle } from "lodash";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";
import { getCookie } from "components/Utils/cookies";
import { VariableSizeList as List } from 'react-window';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import teamsIcon from "assets/img/teamsIcon2.png";
import { TableCell } from "@material-ui/core";
import { ArrowDropUp, ArrowDropDown } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from '@material-ui/lab/Autocomplete';
import audiofile from "assets/img/audioFIleIcon2.png";
const AudioFileIcon = () => (
  <img src={audiofile} alt="Open Folder" style={{ width: 40, height: 40, marginBottom: '-2px' }} />
);

const iconList = [
  { name: 'Question', icon: <HelpOutlineIcon /> },
  { name: 'Chat', icon: <ChatBubbleOutlineIcon /> },
  { name: 'Send', icon: <SendIcon /> },
  { name: 'Feedback', icon: <FeedbackOutlinedIcon /> },
  { name: 'Add', icon: <AddIcon /> },
  { name: 'Edit', icon: <EditIcon /> },
  { name: 'Delete', icon: <DeleteIcon /> },
  { name: 'Download', icon: <GetAppIcon /> },
  { name: 'Upload', icon: <CloudUpload /> },
  { name: 'Undo', icon: <Undo /> }];
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center"
  },
};

const useStyles = makeStyles(styles);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
// Add PropTypes validation for TabPanel component
TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TableList() {
  useTokenExpirationCheck();
  const { showExpiredModal, handleModalCloseTokenExpired } = useTokenExpirationCheck();
  const [value, setValue] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState('');
  const [questionText, setQuestionText] = useState('');
  const [customButtons, setCustomButtons] = useState([]);
  const [modalOpenNewCustomButton, setNewCustomModalButton] = useState(false);
  const [isSuggested, setIsSuggested] = useState(false);
  const { user } = useAuth();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSpeaker, setModalOpenSpeaker] = useState(false);
  const [modalUploadv2, setModalUploadv2] = useState(false);
  const [modalFolderTreeview, setModalFolderTreeview] = useState(false);
  const [modalRetranscribe, setModalRetranscribe] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSpeaker, setAnchorElSpeaker] = useState(null);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(null);
  const [speakerName, setSpeakerName] = useState('');
  const [transcriptionData, setTranscriptionData] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [isEditing, setIsEditing] = useState({ segmentIndex: null, wordIndex: null, startTime: null, endTime: null });
  const [editableTextV2, setEditableTextV2] = useState('');
  const [isParagraphEditing, setIsParagraphEditing] = useState(false);
  const [editableParagraph, setEditableParagraph] = useState(null);
  const [editableParagraphv2, setEditableParagraphV2] = useState('');
  const [saveBntDisabled, setsaveBntDisabled] = useState(false);
  const [isEditModalOpenSingleWord, setEditSingleWordModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSingleSpeaker, setSingleSpeaker] = useState(false);
  const [isMutlipleSpeaker, setMutlipleSpeaker] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [highlightedSegmentIndex, setHighlightedSegmentIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const audioPlayerRef = useRef(null);
  const audioContextRef = useRef(null);
  const spanRef = useRef(null);
  const inputRef = useRef(null);
  const paragraphInputRef = useRef(null);
  const [selectedEditText, setEditValue] = useState(null);
  const [selectedSegmentIndexv2, setSelectedSegmentIndexv2] = useState(null);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openSuccessDeleteDialog, setOpenSuccessDeleteDialog] = useState(false);
  const [showTranscribeTextTimestamp, setshowTranscribeTextTimestamp] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isResponseChat, setisResponseChat] = useState(false);
  const [isOpenDownloadModal, setOpenDownloadModal] = useState(false);
  const [isOpenMoveFileModal, setOpenMoveFileModal] = useState(false);
  const transcriptionContainerRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [tableDataBackup, setTableDataBackup] = useState([]);
  const [displayFolderTxtField, setDisplayFolderTxtField] = useState(false);
  const [folderTxtValue, setFolderTxtValue] = useState('');

  const [selectedButton, setSelectedButton] = useState("");

  const [contextMenu, setContextMenu] = useState({
    mouseX: null,
    mouseY: null,
    button: null,
  });
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [editButton, setEditButton] = useState(null);
  const [newButtonText, setNewButtonText] = useState('');
  const messageEndRef = useRef(null);
  let isRestoreBtnClicked = false;
  const listRef = useRef(null);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [isEditParagraphModalOpen, setEditParagraphModalOpen] = useState(false);
  const [isSegmentIndexParagraph, setIsSegmentIndexParagraph] = useState(null);
  const [isSegmentParagraph, setIsSegmentParagraph] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  //const [breadcrumbs, setBreadcrumbs] = useState([{ title: "Files", key: null }]);
  const [treeData, setTreeData] = useState([]);
  const [userFiles, setUserFiles] = useState([]);
  const [hasSelectedNode, setHasSelectedNode] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [fileType, setFileType] = useState("View All");
  const [status, setStatus] = useState("View All");
  const [uploadDate, setUploadDate] = useState("");
  const [orderBy, setOrderBy] = useState(null);
  const [order, setOrder] = useState("desc");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [fileTypes, setFileTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isRecordNow, setIsRecordNow] = useState(false);
  const [isOpenImportTeams, setUploadImportTeamsModalOpen] = useState(false);
  const [isMSAccount, setIsMSAccount] = useState(false);
  const [input, setInput] = useState("");
  const handleSelectChange = (event) => {
    const selectedBtn = customButtons.find(btn => btn.text === event.target.value);
    setSelectedButton(selectedBtn.text);
    handleButtonClick(selectedBtn);
  };
  const handleSelectChangev2 = (event, event2) => {
    console.log(event2);
    const selectedBtn = customButtons.find(btn => btn.text === event2.text);
    setSelectedButton(selectedBtn.text);
    handleButtonClick(selectedBtn);
  };

  // const handleContextMenu = (event, btn, index) => {
  //   event.preventDefault();
  //   setContextMenu(
  //     contextMenu === null
  //       ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
  //       : null
  //   );
  //  // setSelectedItem({ btn, index });
  //  console.log('Selected Button:', btn,index);
  // };

  //-----------------Loading Indicator-----------------------------------

  const handleScroll = (e) => {
    const container = e.target;
    if (container) {
      setScrollOffset(container.scrollTop);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = scrollOffset;
    }
  }, [scrollOffset]);
  useEffect(() => {
    return () => {
      if (audioPlayerRef.current?.audio?.current) {
        audioPlayerRef.current.audio.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  //------------------------download transcript-----------------------------------
  const downloadModal = () => {
    setOpenDownloadModal(true);
  }
  const handleModalDownloadClose = () => {
    setOpenDownloadModal(false);
  }

  // ----------------------------Customize------------------------------------------------------- 
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      scrollToBottom();
    }
  };
  const handleIconChange = (event) => {
    setSelectedIcon(event.target.value);
  };


  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
    let isMsLoggedIn = localStorage.getItem('isMSAccountLoggedIn');
    if (isMsLoggedIn == 'true') {
      setIsMSAccount(true);
    }
  }, [messages]);

  const handleTextChange = (event) => {
    const inputValue = event.target.value;
    setQuestionText(inputValue);
  };
  const handleCreateNewCustomButton = () => {
    setNewCustomModalButton(true);
  };
  const handleCreateButton = async () => {
    if (selectedIcon && questionText) {
      const selectedIconObj = iconList.find((iconObj) => iconObj.name === selectedIcon);
      const newButton = { icon: selectedIconObj.icon, text: questionText };
      setCustomButtons((prevButtons) => [...prevButtons, newButton]);
      setSelectedIcon('');
      setQuestionText('');
      const transId = selectedFile.transcriptions[0].id;
      const payload = {
        TranscriptionId: transId,
        Icon: selectedIconObj.name,
        QuestionText: questionText,
        Email: user.email,
      };

      try {
        if (!isSuggested) {
          try {
            await InsertCustomButton(payload);
          } catch (error) {
            console.error('Error creating button:', error);
          }
        }
      } catch (error) {
        console.error('Error creating button:', error);
      }
      setIsSuggested(false);
      setNewCustomModalButton(false);
    }
  };
  const handleSend = () => {
    if (input.trim()) {
      //(input);
      setInput("");
    }
    handleSendToAI(input);
  };
  const handleSendToAI = async (inputText) => {
    setisResponseChat(true);
    try {
      const transId = selectedFile.transcriptions[0].id;
      const userMessage = {
        type: 'user',
        content: inputText,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      const result = await SendtoAzureChat(transId, inputText, user.email);
      const formattedResponse = formatAzureResponseToMarkdown(result.data.response);
      const azureMessage = {
        type: 'azure',
        question: result.data.question,
        response: formattedResponse,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, azureMessage]);
    } catch (error) {
      setisResponseChat(false);
    }
    finally {
      setisResponseChat(false);
    }
  }
  const handleButtonClick = async (btn) => {
    setisResponseChat(true);
    try {
      const transId = selectedFile.transcriptions[0].id;
      const userMessage = {
        type: 'user',
        content: btn.text,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      const result = await SendtoAzureChat(transId, btn.text, user.email);
      const formattedResponse = formatAzureResponseToMarkdown(result.data.response);
      const azureMessage = {
        type: 'azure',
        question: result.data.question,
        response: formattedResponse,
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, azureMessage]);
    } catch (error) {
      setisResponseChat(false);
    }
    finally {
      setisResponseChat(false);
    }
  }
  const clearChat = async () => {
    //setIsLoading(true);
    const transId = selectedFile.transcriptions[0].id;
    var result = await clearChatHistory(transId, user.email);
    if (result) {
      setMessages([]);
      //setIsLoading(false);
    }
  }
  const handleModalCloseNewCustomButton = () => {
    setNewCustomModalButton(false);
  };
  //  format a response from Azure to markdown format here is the link guide = https://www.markdownguide.org/basic-syntax/
  const formatAzureResponseToMarkdown = (response) => {
    let formattedResponse = response;

    formattedResponse = formattedResponse.replace(/(\*\*|__)(.*?)\1/g, '**$2**');

    formattedResponse = formattedResponse.replace(/(\*|_)(.*?)\1/g, '*$2*');

    formattedResponse = formattedResponse.replace(/(#+)\s(.+)/g, (match, hashes, text) => {
      const level = hashes.length;
      return `${'#'.repeat(level)} ${text}`;
    });

    formattedResponse = formattedResponse.replace(/^(\d+)\.\s(.+)/gm, '$1. $2');

    formattedResponse = formattedResponse.replace(/^\s*[-*]\s(.+)/gm, '- $1');

    formattedResponse = formattedResponse.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '[$1]($2)');

    formattedResponse = formattedResponse.replace(/`([^`]+)`/g, '`$1`');

    formattedResponse = formattedResponse.replace(/```([^`]+)```/gs, '```\n$1\n```');

    return formattedResponse;
  };

  const handleEditDeleteCustomButton = async (event, btn, index) => {
    setNewButtonText(btn.text);
    setEditButton({ btn: btn, index: index });
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      button: btn,
    });
  }
  const handleEditDeleteCustomButtonV2 = async (event, btn, index) => {
    console.log('Option:', btn);
    console.log('event:', event);
    setNewButtonText(btn.text);
    setEditButton({ btn: btn, index: index });
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      button: btn,
    });
  }
  // Open the edit modal
  const handleEdit = () => {
    setEditButton(contextMenu.button);
    setEditModalOpen(true);
    handleCloseContextMenu();
  };

  // Close the context menu
  const handleCloseContextMenu = () => {
    setContextMenu({
      mouseX: null,
      mouseY: null,
      button: null,
    });
  };
  // const handleDelete = async () => {
  //   handleCloseContextMenu();
  //   setCustomButtons((prevButtons) => prevButtons.filter((_, i) => i !== editButton.id));
  //   const id = editButton.btn.id
  //   await deleteCustomButton(id);

  // }

  const handleDeleteVe = async () => {
    handleCloseContextMenu(); // Close the context menu first

    if (!editButton || !editButton.btn) {
      console.error("No button selected for deletion.");
      return;
    }

    const id = editButton.btn.id; // Get the actual ID of the button

    // Remove the button from state using its ID, not the index
    setCustomButtons((prevButtons) =>
      prevButtons.filter((btn) => btn.id !== id) // ✅ Filter by button ID, not index
    );

    try {
      await deleteCustomButton(id); // Call API to delete from database
    } catch (error) {
      console.error("Error deleting button:", error);
    }
  };
  const handleSaveEdit = async () => {
    console.log('Edit Button:', editButton);
    const id = editButton.id;
    const transId = selectedFile.transcriptions[0].id;
    const payload = {
      id: id,
      TranscriptionId: transId,
      Icon: "",
      QuestionText: newButtonText,
      Email: user.email,
    };
    try {
      setEditModalOpen(false);
      const response = await UpdateCustomButton(payload);
      if (response && response.status === 200) {
        // Re-fetch the updated list of custom buttons after update
        const savedButtons = await GetCustomButtons(user.email);
        console.log('Saved buttons:', savedButtons);
        const newSavedButtons = savedButtons.map((btn) => {
          const selectedIconObj = iconList.find((iconObj) => iconObj.name === btn.icon);
          return { icon: selectedIconObj ? selectedIconObj.icon : null, text: btn.text, id: btn.id };
        });
        setCustomButtons(newSavedButtons);
      }
    } catch (error) {
      setEditModalOpen(false);
      console.error("Error updating the button", error);
    }

  }
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  }
  const fetchChatHistory = async (transcriptionId) => {
    const chatHistory = await getChatHistory(transcriptionId, user.email);

    if (chatHistory.conversationHistory && chatHistory.conversationHistory.length > 0) {
      // Collect new messages in an array
      const newMessages = [];
      chatHistory.conversationHistory.forEach((history) => {
        if (history.role === 'user') {
          newMessages.push({
            type: 'user',
            content: history.text,
            timestamp: history.timeStamp,
          });
        } else if (history.role === 'assistant') {
          const formattedResponse = formatAzureResponseToMarkdown(history.text);
          newMessages.push({
            type: 'azure',
            question: '',
            response: formattedResponse,
            timestamp: history.timeStamp,
          });
        }
      });
      console.log('newMessages:', newMessages);
      // Append the new messages to the current messages state
      setMessages(newMessages);
      scrollToBottom();
    }
  }

  // ----------------------------------------------------------------------------------- 

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
  
      return `${day}/${month}/${year}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };
  
  

  const formatDuration = (durationInSeconds) => {
    // Ensure the duration is a number
    const duration = Number(durationInSeconds);

    if (isNaN(duration) || duration < 0) {
      return 'Invalid duration';
    }

    // Calculate minutes and seconds
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration % 60);

    // Format minutes and seconds with leading zeroes if necessary
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  let isDebouncing = false;
  const handleTimeUpdate = throttle(() => {
    if (audioPlayerRef.current && audioPlayerRef.current.audio && transcriptionData.length > 0) {
      const currentTime = audioPlayerRef.current.audio.current.currentTime;
      const timeBuffer = 0.05; // Allow for a 0.05 second tolerance
      let currentSegment = null;
      // Loop through each transcription segment

      for (let i = 0; i < transcriptionData.length; i++) {
        const words = transcriptionData[i].words;
        // Find the word within the segment that matches the current time
        currentSegment = words.find(
          (word) => currentTime >= word.startTime - timeBuffer && currentTime <= word.endTime + timeBuffer
        );

        // If a matching word is found, break out of the loop
        if (currentSegment) {
          setHighlightedSegmentIndex({ startTime: currentSegment.startTime, endTime: currentSegment.endTime });
          break;
        }
      }

      // If no segment is found, reset the highlight
      if (!currentSegment) {
        console.log('No active word found at the current time.');
        setHighlightedSegmentIndex((prev) => (prev ? null : prev));
      }
    }
  }, 200);

  const UploadModalV2 = () => { setModalUploadv2(true); }

  const FolderTreeViewModal = () => { setModalFolderTreeview(true); }
  const RecordNowModal = () => { setIsRecordNow(true); }
  const RecordNowClose = () => { setIsRecordNow(false); }
  const handleModalUploadCloseV2 = () => { setModalUploadv2(false); }
  const handleModalFolderTreeviewClose = () => { setModalFolderTreeview(false); }
  const UploadImportTeams = () => { setUploadImportTeamsModalOpen(true); }
  const handleUploadImportTeamsModalClose = () => { setUploadImportTeamsModalOpen(false); }

  const editSpeaker = (segment, wordIndex, event) => {
    event.preventDefault();
    const { clientX, clientY } = event;
    setSelectedSegmentIndex({ segment, wordIndex });
    setSpeakerName(segment.speakerOnly);
    setAnchorElSpeaker(event.currentTarget);
    setAnchorElSpeaker({ top: clientY, left: clientX });
  };

  const handleSingleSpeakeModal = () => {
    setSingleSpeaker(true);
    setModalOpenSpeaker(true);
    setMutlipleSpeaker(false);
    handleClose();
  }
  const handleMultipleSpeakeModal = () => {
    setMutlipleSpeaker(true);
    setModalOpenSpeaker(true);
    setSingleSpeaker(false);
    handleClose();
  }

  const handleMultipleSpeakerEdit = async () => {
    setMutlipleSpeaker(false);
    setModalOpenSpeaker(false);
    setIsSaving(true);
    if (!speakerName) {
      console.error("No speaker name provided for edit");
      return;
    }
    // Deep clone the transcription data to avoid mutation
    const updatedTranscriptionData = JSON.parse(JSON.stringify(transcriptionData));
    // Extract the segment from the selected index
    const { segment } = selectedSegmentIndexv2;
    if (!segment || !segment.speakerOnly) {
      console.error("No valid segment or speakerOnly in selectedSegmentIndexv2");
      return;
    }
    let updateCount = 0; // To track how many updates we perform
    // Find and update all segments that match the current speaker label
    updatedTranscriptionData.forEach((transcriptionData, index) => {
      console.log(`Transcription speakerOnly [${index}]:`, transcriptionData.speakerOnly);
      if (transcriptionData.speakerOnly === segment.speakerOnly) {
        transcriptionData.speakerOnly = speakerName; // Assign the new speaker name
        updateCount++;
      }
    });

    if (updateCount === 0) {
      console.log("No segments were updated.");
    } else {
      console.log(`${updateCount} segments updated successfully.`);
    }
    setTranscriptionData(updatedTranscriptionData);
    // Build the fullRawDetails with the updated speaker name
    const fullRawDetails = updatedTranscriptionData
      .map((seg) => {
        const segmentText = seg.speakerText || seg.words.map((word) => word.word).join(" ");

        const wordsWithTime = seg.words
          .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
          .join(", ");

        return `${seg.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
      })
      .join("\n");

    // Uncomment the save logic if needed
    const id = selectedFile.transcriptions[0].id;
    await saveUpdatedTranscription(id, fullRawDetails);
    setRefreshTrigger(prev => !prev);
    setModalOpenSpeaker(false);
    setIsSaving(false);
    handleClose();
  };

  const handleSingleSpeakerEdit = async () => {
    setSingleSpeaker(false);
    setModalOpenSpeaker(false);
    setIsSaving(true);
    if (selectedSegmentIndexv2) {
      const updatedTranscriptionData = JSON.parse(JSON.stringify(transcriptionData));
      const { segment, wordIndex } = selectedSegmentIndexv2;
      const segmentIndex = updatedTranscriptionData.findIndex((seg, index) =>
        seg.speakerOnly === segment.speakerOnly && index === wordIndex
      );
      if (segmentIndex !== -1) {
        updatedTranscriptionData[segmentIndex].speakerOnly = speakerName;

        setTranscriptionData(updatedTranscriptionData);

        const fullRawDetails = updatedTranscriptionData
          .map((seg) => {
            const segmentText = seg.text || seg.words.map(word => word.word).join(' ');

            const wordsWithTime = seg.words
              .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
              .join(', ');

            return `${seg.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
          })
          .join('\n');
        const id = selectedFile.transcriptions[0].id;
        await saveUpdatedTranscription(id, fullRawDetails);
        setRefreshTrigger(prev => !prev);
        setModalOpenSpeaker(false);
      } else {
        setModalOpenSpeaker(false);
        console.error('Segment not found for the selected index');
      }
      setIsSaving(false);
      handleClose(); // Close the menu after action
    }
  };

  // When editing is done and focus leaves the input
  const handleDoubleClick = (segmentIndex, wordIndex, startTime, endTime, event) => {
    event.preventDefault();
    const segment = transcriptionData[segmentIndex].words.find(
      (word) => word.startTime === startTime && word.endTime === endTime
    );

    if (segment) {
      console.log('IsEditing:', segmentIndex, wordIndex, startTime, endTime, event);
      setIsEditing({ segmentIndex, wordIndex, startTime, endTime }); // Track both segment and word index
      console.log('Editable text:', isEditing);
      setEditableTextV2(segment.word);
      setEditValue(segment.word);

      setEditSingleWordModal(true);
    } else {
      console.log('Segment not found');
    }
  };
  const handleSaveSingleWordEdit = async () => {
    let editableTextToSave = editableTextV2;
    if (editableTextToSave === "") {
      editableTextToSave = "[deleted]";
      setEditableTextV2(editableTextToSave);
    }

    if (selectedEditText !== editableTextToSave) {
      setIsSaving(true);
      setEditSingleWordModal(false);
      if (selectedFile && selectedFile.transcriptions.length > 0) {
        const updatedTranscriptionData = [...transcriptionData];
        let transcriptionWordId = 0;
        const id = selectedFile.transcriptions[0].id;
        let originalWord = updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].originalWord;


        originalWord = originalWord == '' ? updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].word : originalWord;
        if (originalWord === '' || originalWord === "[deleted]") {
          originalWord = "[deleted]";
        }
        const combinedTimestamp = '[' + updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].startTime + ' - ' + updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].endTime + ']'
        // console.log('Combined Timestamp:', combinedTimestamp);
        if (selectedFile.transcriptions[0].transcriptionWords.length > 0) {
          let transcriptionWordObj = selectedFile.transcriptions[0].transcriptionWords.filter(f => f.originalWord == originalWord && f.originalTimestamp == combinedTimestamp);

          if (transcriptionWordObj.length > 0) {
            transcriptionWordId = transcriptionWordObj[0].id;
          }
        }

        const payloadTranscriptionWord =
        {
          id: transcriptionWordId,
          transcriptionId: id,
          originalWord: originalWord,
          originalTimestamp: combinedTimestamp,
          customWord: editableTextToSave,
          customTimestamp: combinedTimestamp,
          email: user.email,
          restore: isRestoreBtnClicked
        }
        console.log('Payload:', payloadTranscriptionWord);
        updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].word = editableTextToSave;

        // Build the fullRawDetails with speaker and words along with their time segments in the format:
        const fullRawDetails = updatedTranscriptionData
          .map((segment) => {
            const segmentText = segment.text || segment.words
              .map((word) => word.word === '' || word.word === "[deleted]" ? "[deleted]" : word.word) // Replace empty or deleted words
              .join(' ');

            const wordsWithTime = segment.words
              .map((word) => {
                // If the word is [deleted], keep it as [deleted], otherwise show it as the original word
                const wordWithSpace = word.word === "[deleted]" || word.word === '' ? "[deleted]" : word.word;
                return `${wordWithSpace} [${word.startTime}s - ${word.endTime}s]`;
              })
              .join(', ');

            return `${segment.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
          })
          .join('\n');
        // Assuming you have a save function to handle this
        let result = await UpsertTranscriptionWord(payloadTranscriptionWord);
        await new Promise(resolve => setTimeout(resolve, 100));
        await saveUpdatedTranscription(id, fullRawDetails); // Uncomment your save logic

        const transcriptionWords = result.data;
        const parsedSegments = parseTranscription(fullRawDetails, transcriptionWords);

        setTranscriptionData(parsedSegments);
        setIsEditing(null);
      }
    }
    else {
      setEditSingleWordModal(false);
    }
    setIsEditing(null);
    setIsSaving(false); // Reset saving flag
  };

  const saveUpdatedTranscription = async (id, updatedRawDetails) => {
    try {
      const token = getCookie('jwtToken');
      //const apiUrl = 'https://localhost:7218'
      const response = await fetch(process.env.REACT_APP_API_URL + "/api/Transcription/UpdateTranscriptionRawDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: id,
          rawDetails: updatedRawDetails,
        }),
      });

      if (response.ok) {
        //alert("Transcription updated successfully!");
        setRefreshTrigger(prev => !prev);
        setIsEditing(false);
      } else {
        alert("Failed to update transcription.");
      }
    } catch (error) {
      console.error("Error updating transcription:", error);
    }
  };

  const loadAudioFile = async (audioUrl) => {
    if (!audioContextRef.current) {
      try {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      } catch (e) {
        console.error('Error creating audio context:', e);
        return;
      }
    }

    try {


      const response = await fetch(audioUrl);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const arrayBuffer = await response.arrayBuffer();
      let audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
      setAudioBuffer(audioBuffer);

    } catch (error) {
      console.error('Error loading audio:', error);
    } finally {
      setIsLoading(false); // Loading complete
    }
  };

  const playAudioForWord = (word, startTime, endTime) => {
    if (!audioUrl) {
      return;
    }

    if (isRestoreBtnClicked) {
      isRestoreBtnClicked = false;
      return;
    }
    if (audioBuffer) {
      playSegment(startTime, endTime);
      audioPlayerRef.current.audio.current.currentTime = startTime;
    }
    else {
      alert('Please Note:Audio is not loaded yet. This message will only appear the first time you click the word. Please wait a moment while we prepare the audio for playback.');
      loadAudioFile(audioUrl).then(() => {
        //setIsLoading(true); // Hide loading indicator
        playSegment(startTime, endTime);
      });
    }
  };

  const playSegment = (startTime, endTime) => {
    const duration = endTime - startTime;

    if (!audioBuffer) {
      return;
    }


    // Create a new audio source from the buffer
    const source1 = audioContextRef.current.createBufferSource();

    source1.buffer = audioBuffer;
    source1.connect(audioContextRef.current.destination);
    source1.start(0, startTime, duration);
    source1.onended = () => {
      console.log('Playback ended after word');
    };
    setIsLoading(false);
  };



  const handleClick = (event, file) => {
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorElSpeaker(null);
    setSelectedSegmentIndex(null);
  };

  const parseTranscription = (rawDetails, transcriptionWords) => {
    const segments = [];

    if (rawDetails == '') return segments;

    rawDetails = rawDetails.replace(/\n+/g, '\n').trim();

    //const speakerRegex = /([\w\s]+):\s(.*?)(?:<ts#0x1>(.*?)<\/ts#0x1>)/gs;
    const speakerRegex = /([\w\s'’]+):\s(.*?)(?:<ts#0x1>(.*?)<\/ts#0x1>)/gs;


    let combinedMatch;

    while ((combinedMatch = speakerRegex.exec(rawDetails)) !== null) {
      const speakerOnly = combinedMatch[1].trim(); // Get speaker name
      const speakerText = combinedMatch[2].trim(); // Get speaker's spoken text
      const segmentDetails = combinedMatch[3] ? combinedMatch[3].trim() : ""; // Segment details if available

      let startTime;
      let segmentMatch;
      let originalParagraph;
      let isModifiedParagraph = false;
      let paragraphTranscriptionWordId;
      //const segmentRegex = /([^,]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s]/g;
      const segmentRegex = /([^,]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s]/g;
      const speakerTextWithSpace = (speakerText === "[deleted]" || speakerText === "[deletedline]") ? "" : speakerOnly;
      // Create a temporary object to store the speaker and their words
      const speakerSegments = {
        speakerOnly: speakerTextWithSpace,
        speakerText,
        startTime,
        originalParagraph,
        isModifiedParagraph,
        paragraphTranscriptionWordId,
        words: [] // Store all words for this speaker
      };

      while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
        const word = segmentMatch[1].trim();  // Extract the word
        const wordStartTime = parseFloat(segmentMatch[2]);
        const wordEndTime = parseFloat(segmentMatch[4]);
        const combinedTimestamp = '[' + wordStartTime + ' - ' + wordEndTime + ']';
        const foundWord = transcriptionWords.filter(item => item.customTimestamp == combinedTimestamp);
        let hasEditedWord = foundWord.length > 0;
        if (foundWord.length > 0) {
          // if flagged as modified paragraph make it false to prevent duplicate undo button
          hasEditedWord = foundWord[0].isModifiedParagraph ? false : hasEditedWord
        } // If empty, make it a regular space
        const wordWithSpace = (word === "[deleted]" || word === "[deletedline]") ? "" : word;

        speakerSegments.words.push({
          word: wordWithSpace,
          startTime: wordStartTime,
          endTime: wordEndTime,
          isEdited: hasEditedWord,
          originalWord: hasEditedWord ? foundWord[0].originalWord : '',
          transcriptionWordId: hasEditedWord ? foundWord[0].id : 0
        });
      }

      // Push the speaker and their words as one entry
      speakerSegments.startTime = timeConverter(speakerSegments.words[0].startTime);

      const hasModifiedParagraph = transcriptionWords.filter(item => item.isModifiedParagraph);

      if (hasModifiedParagraph.length > 0) {
        const combinedParagraphTimestamp = '[' + speakerSegments.words[0].startTime + ' - ' + speakerSegments.words[speakerSegments.words.length - 1].endTime + ']';

        const originalParagraph = transcriptionWords.filter(item => item.originalTimestamp == combinedParagraphTimestamp);

        if (originalParagraph.length > 0) {
          speakerSegments.originalParagraph = originalParagraph[0].originalWord;
          speakerSegments.paragraphTranscriptionWordId = originalParagraph[0].id;
          speakerSegments.isModifiedParagraph = true;
        }
      }

      segments.push(speakerSegments);
    }

    return segments;
  };

  const onClickRetranscribe = async () => {
    if (selectedFile && selectedFile.transcriptions.length > 0) {
      const transcriptionId = selectedFile.transcriptions[0].id;
      console.log('Transcription ID:', transcriptionId);
      //await reTranscribe(selectedFile.id, transcriptionId);
      setModalRetranscribe(true);
      handleClose();
      setRefreshTrigger(prev => !prev);
    }
  }



  const handleTranscriptionClick = async () => {
    const transcriptionStatus = selectedFile.transcriptions.length > 0
      ? selectedFile.transcriptions[0].transcriptionStatus.name
      : "No transcription";
    if (transcriptionStatus === "Re Transcribing" || transcriptionStatus === "Transcribing" || transcriptionStatus === "Converting") {
      alert("Transcription is currently being processed. Please wait for the process to complete.");
      return;
    }
    if (selectedFile && selectedFile.transcriptions.length > 0) {
      const isTranscriptionModified = selectedFile.transcriptions[0].modifiedDetails !== '';

      const rawDetails = isTranscriptionModified ? selectedFile.transcriptions[0].modifiedDetails : selectedFile.transcriptions[0].rawDetails;
      const transcriptionWords = selectedFile.transcriptions[0].transcriptionWords;
      console.log('Transcription Words:', transcriptionWords);
      const parsedSegments = parseTranscription(rawDetails, transcriptionWords);
      const transcriptionId = selectedFile.transcriptions[0].id;

      try {
        setIsLoading(true); // Show loading indicator
        setModalOpen(true);
        const data = await getFileWithSasUrl(selectedFile.id);
        const savedButtons = await GetCustomButtons(user.email);
        console.log('Saved buttons:', savedButtons);
        const newSavedButtons = savedButtons.map((btn) => {
          const selectedIconObj = iconList.find((iconObj) => iconObj.name === btn.icon);
          return { icon: selectedIconObj ? selectedIconObj.icon : null, text: btn.text, id: btn.id };
        });
        setCustomButtons(newSavedButtons);

        fetchChatHistory(transcriptionId);

        if (!data || !data.sasUrl) {
          console.error('Failed to retrieve SAS URL');
          return; // Early exit if SAS URL is not retrieved
        }

        setTranscriptionData(parsedSegments);
        if (selectedFile.convertedFileUrl) {
          setAudioUrl(data.sasUrl);
          loadAudioFile(data.sasUrl);
        } else {
          console.error('convertedFileUrl is missing in selectedFile');
        }
      } catch (error) {
        console.error('Error getting file with SAS URL:', error);
      }
    } else {
      setTranscriptionData([]);
      setModalOpen(true);
    }
    handleClose();
  };


  const handleDeleteAudio = async () => {
    try {
      // Call the delete API
      const id = selectedFile.id;
      const url = selectedFile.url;
      const deleteResponse = await deleteAudio(id, url);
      console.log("ResponseDelete:", deleteResponse);
      setTableData(prevData => prevData.filter(fileRow => fileRow[6].id !== id));
      setAnchorEl(null);
      setOpenSuccessDeleteDialog(true);
    } catch (error) {
      console.error("Error deleting audio:", error);
    }
  };
  const handleModalClose = () => {
    setIsLoadingData(false);
    setModalOpen(false);
    setAudioUrl(null);
    setAudioBuffer(null);
    setHighlightedSegmentIndex(null);
    setshowTranscribeTextTimestamp(false);
    setValue(0);
    setMessages([]);
  };

  const handleModalCloseSpeaker = () => {
    setModalOpenSpeaker(false);
  };
  const handleModalCloseEditParagraphModal = () => {
    setEditParagraphModalOpen(false);
  };
  const handleModalCloseEditSingleWordModal = () => {
    setEditSingleWordModal(false);
  };

  const handleModalRetranscribeClose = () => {
    console.log('Modal Retranscribe Closed');
    setModalRetranscribe(false);
  };


  const updateStatusRetranscribe = async () => {
    if (!selectedFile?.id) {
      console.warn('Selected file or ID is missing');
      return;
    }

    const updateData = tableData.map((fileRow) => {
      if (fileRow[6]?.id === selectedFile.id) {
        return [
          ...fileRow.slice(0, 5),
          "Re Transcribing",
          fileRow[5]
        ];
      }
      return fileRow;
    });

    try {
      setTableData(updateData);
      await checkRunningBatchReTranscription(user.email);
    } catch (error) {
      console.error('Error updating re-transcription status:', error);
    }
  };


  useEffect(() => {
    // Backup table data when it's initially loaded or updated
    setTableDataBackup(tableData);
  }, [tableData]);
  useEffect(() => {
    if (highlightedSegmentIndex) {
      const container = transcriptionContainerRef.current;
      const activeSegment = document.getElementById(`segment-${highlightedSegmentIndex.startTime}`);

      if (activeSegment && activeSegment.offsetHeight > 0) {
        const segmentOffsetTop = activeSegment.offsetTop;
        const segmentHeight = activeSegment.offsetHeight;
        const containerHeight = container.clientHeight;
        const containerScrollTop = container.scrollTop;
        const segmentBottom = segmentOffsetTop + segmentHeight;
        const containerBottom = containerScrollTop + containerHeight;
        const bottomThreshold = 300;
        const isNearBottom = segmentBottom >= containerBottom - bottomThreshold;

        if (isNearBottom) {
          activeSegment.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }
      } else {
        console.log('Active segment is either not rendered or has no height yet');
      }
    }
  }, [highlightedSegmentIndex]);


  useEffect(() => {

    const fetchFilesOnly = async () => {

      if (!user || !user.email) {
        console.error("User is not defined or email is missing.");
        return;
      }
      if (!hasSelectedNode) {
        try {
          setIsLoadingData(true);
          const files = await fetchFilesByEmail(user.email);
          console.log('Files:', files);
          setUserFiles(files);
          if (files.length > 0) {
            setIsLoadingData(false);
          }
          if (files.length == 0) {
            setIsLoadingData(false);
          }
          if (!Array.isArray(files)) {
            throw new Error("Expected files to be an array");
          }

          // Extract unique file types & statuses
          const uniqueFileTypes = [...new Set(files.map((file) => file.type || "Unknown"))];
          const uniqueStatuses = [...new Set(
            files.map((file) => file.transcriptions.length > 0
              ? file.transcriptions[0].transcriptionStatus.name
              : "No transcription"
            )
          )];

          setFileTypes(uniqueFileTypes);
          setStatuses(uniqueStatuses);

          const formattedData = files.map((file) => {
            const transcriptionStatus = file.transcriptions.length > 0
              ? file.transcriptions[0].transcriptionStatus.name
              : "No transcription";
            return [
              file.audioTitleName || "Untitled",
              formatDate(file.uploadedDate) || "Unknown date",
              file.formattedDuration || "Unknown duration",
              file.type || "Unknown",
              file.formattedSize || "Unknown size",
              transcriptionStatus,
              file
            ];
          });
          setTableData(formattedData);
        }
        catch (error) {
          console.error("Error fetching data:", error);
          handleModalCloseTokenExpired(true);
        }
      }
    };
    fetchFilesOnly();

    const fetchData = async () => {
      if (!user || !user.email) {
        console.error("User is not defined or email is missing.");
        return;
      }
      try {
        if (hasSelectedNode) return;
        const filesAndFolders = await GetFilesAndFolders(user.email);
        const transformedData = transformDataToTree(filesAndFolders);
        setTreeData(transformedData);
        // Fetch converted file status and batch transcription only after files are fetched
        await checkConvertedFile(user.email);
        await checkRunningBatchReTranscription(user.email);
        await checkRunningBatchTranscription(user.email);

        //(files);
      } catch (error) {
        console.error("Error fetching data:", error);
        handleModalCloseTokenExpired(true);
      }

    };
    const updateInputWidth = () => {
      const input = inputRef.current;
      const span = spanRef.current;

      if (span && input) {
        // Set the input width based on the span width
        input.style.width = `${span.offsetWidth + 10}px`; // Add some padding
      }
    };

    const updateParagraphInputWidth = () => {
      if (paragraphInputRef.current) {
        const newWidth = Math.max(50, paragraphInputRef.current.scrollWidth + 10);
        paragraphInputRef.current.style.width = `${newWidth}px`;
      }
    };

    fetchData();
    updateInputWidth();
    updateParagraphInputWidth();
    // Only log selectedSegmentIndex if it's not null
    if (selectedSegmentIndex !== null) {
      setSelectedSegmentIndexv2(selectedSegmentIndex);
    }


    const intervalId = setInterval(() => {
      fetchData();
    }, 10000); // 10 seconds

    // Clean up the interval when the component unmounts or dependencies change
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      clearInterval(intervalId);
    }

  }, [refreshTrigger, user, selectedEditText, selectedSegmentIndex, hasSelectedNode]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const fetchFilesOnly = async () => {

        if (!user || !user.email) {
          console.error("User is not defined or email is missing.");
          return;
        }

        if (hasSelectedNode) return;

        const files = await fetchFilesByEmail(user.email);
        if (!Array.isArray(files)) {
          throw new Error("Expected files to be an array");
        }
        // Extract unique file types & statuses
        const uniqueFileTypes = [...new Set(files.map((file) => file.type || "Unknown"))];
        const uniqueStatuses = [...new Set(
          files.map((file) => file.transcriptions.length > 0
            ? file.transcriptions[0].transcriptionStatus.name
            : "No transcription"
          )
        )];

        setFileTypes(uniqueFileTypes);
        setStatuses(uniqueStatuses);
        const formattedData = files.map((file) => {
          const transcriptionStatus = file.transcriptions.length > 0
            ? file.transcriptions[0].transcriptionStatus.name
            : "No transcription";
          return [
            file.audioTitleName || "Untitled",
            formatDate(file.uploadedDate) || "Unknown date",
            file.formattedDuration || "Unknown duration",
            file.type || "Unknown",
            file.formattedSize || "Unknown size",
            transcriptionStatus,
            file
          ];
        });
        setTableData(formattedData);
      };
      fetchFilesOnly();
    }, 10000);
    return () => clearInterval(intervalId);
  }, [user, hasSelectedNode]);

  const handleKeyDown = (event) => {
    if (transcriptionContainerRef.current) {
      if (event.key === "ArrowDown") {
        transcriptionContainerRef.current.scrollBy({ top: 20, behavior: "smooth" });
      } else if (event.key === "ArrowUp") {
        transcriptionContainerRef.current.scrollBy({ top: -20, behavior: "smooth" });
      }
    }
  };

  const handleShowTranscribedTextTimestamp = (event) => {
    setshowTranscribeTextTimestamp(event.target.checked);
  }

  const timeConverter = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    // Format with leading zero if needed
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const onRestoreOriginalWord = async (transcriptionWordId, originalWord, segmentIndex, wordIndex, isRestoreParagraph = false) => {
    isRestoreBtnClicked = true;
    setIsSaving(true);
    const updatedTranscriptionData = [...transcriptionData];
    const transcriptionId = selectedFile.transcriptions[0].id;


    if (isRestoreParagraph) {
      updatedTranscriptionData[segmentIndex].speakerText = originalWord;
    }
    else {
      updatedTranscriptionData[segmentIndex].words[wordIndex].word = originalWord;
    }

    const parseTranscriptionDetails = await Promise.all(updatedTranscriptionData
      .map(async (segment) => {
        // Construct text from words if segment.text is undefined
        let segmentText = '';
        let wordsWithTime = '';

        if (isRestoreParagraph && segment.isModifiedParagraph) {
          segmentText = segment.speakerText;
          const paragraphStartTime = segment.words[0].startTime;
          const paragraphEndTime = segment.words[segment.words.length - 1].endTime;

          let originalSpeakerTimestamp = await getTranscriptionDetailsByTimestamp(transcriptionId, paragraphStartTime, paragraphEndTime);
          wordsWithTime = originalSpeakerTimestamp;

          return `${segment.speakerOnly}: ${segmentText} ${wordsWithTime}`;

        }
        else {
          segmentText = segment.text || segment.words.map(word => word.word).join(' ');
          wordsWithTime = segment.words
            .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
            .join(', ');

          return `${segment.speakerOnly}: ${segmentText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
        }

      }));

    const fullRawDetails = parseTranscriptionDetails.join('\n');

    // call api to delete transcriptionWord by id
    if (transcriptionWordId > 0) {
      var transcriptionWords = await deleteTranscriptionWord(transcriptionWordId, transcriptionId)

      await saveUpdatedTranscription(transcriptionId, fullRawDetails);

      const parsedSegments = parseTranscription(fullRawDetails, transcriptionWords);

      setTranscriptionData(parsedSegments);
      setIsSaving(false);
      // Trigger refresh
      setRefreshTrigger(prev => !prev);
    }
  }

  const handleEditParagraph = (segment, segmentIndex) => {
    const display = true;
    console.log('Segment:', segment);
    const cleanedSpeakerText = segment.speakerText
      .split(' ')
      .filter(word => word !== "[deleted]")
      .join(' ');
    setEditParagraphModalOpen(display);
    setIsParagraphEditing({ display });
    setEditableParagraphV2(cleanedSpeakerText);
    setIsSegmentIndexParagraph(segmentIndex);
    setIsSegmentParagraph(segment);
  }

  const handleCloseEditParagraph = () => {
    setIsParagraphEditing(false); // Track both segment and word index
    setEditableParagraph(null);
  }

  const handleSaveParagraph = async (selectedSegment, segmentIndex) => {
    setsaveBntDisabled(true);
    if (isSaving) return; // Prevent multiple calls
    setIsSaving(true);
    const updatedTranscriptionData = [...transcriptionData];
    let transcriptionWordId = 0;
    const id = selectedFile.transcriptions[0].id;

    let originalWord = updatedTranscriptionData[segmentIndex].speakerText;

    originalWord = originalWord == '' ? updatedTranscriptionData[segmentIndex].speakerText : originalWord;

    const combinedTimestamp = '[' + updatedTranscriptionData[segmentIndex].words[0].startTime + ' - ' + updatedTranscriptionData[segmentIndex].words[updatedTranscriptionData[segmentIndex].words.length - 1].endTime + ']'

    if (selectedFile.transcriptions[0].transcriptionWords.length > 0) {
      let transcriptionWordObj = selectedFile.transcriptions[0].transcriptionWords.filter(f => f.originalWord == originalWord && f.originalTimestamp == combinedTimestamp);

      if (transcriptionWordObj.length > 0) {
        transcriptionWordId = transcriptionWordObj[0].id;
      }
    }

    const payloadTranscriptionWord =
    {
      id: transcriptionWordId,
      transcriptionId: id,
      originalWord: originalWord,
      originalTimestamp: combinedTimestamp,
      customWord: editableParagraphv2,
      customTimestamp: combinedTimestamp,
      email: user.email,
      IsModifiedParagraph: true
    }

    let transcriptionWordResult = await UpsertTranscriptionWord(payloadTranscriptionWord);

    const speakerOnly = selectedSegment.speakerOnly;
    const speakerText = editableParagraphv2;
    const startTime = selectedSegment.startTime;

    const speakerSegments = {
      speakerOnly,
      speakerText,
      startTime,
      words: []
    };

    speakerSegments.words = selectedSegment.words;

    updatedTranscriptionData[segmentIndex] = speakerSegments;

    const fullRawDetails = updatedTranscriptionData
      .map((segment) => {

        const totalWords = segment.speakerText.split(' ').length;
        const paragraphStartTime = segment.words[0].startTime;
        const paragraphEndTime = segment.words[segment.words.length - 1].endTime;

        const durationPerWord = totalWords > 0 ? (paragraphEndTime - paragraphStartTime) / totalWords : 0;
        let wordsWithTime = '';

        if (updatedTranscriptionData[segmentIndex].words[0].startTime == paragraphStartTime &&
          updatedTranscriptionData[segmentIndex].words[updatedTranscriptionData[segmentIndex].words.length - 1].endTime == paragraphEndTime) {

          wordsWithTime = segment.speakerText.split(' ')
            .map((word, index) => {
              const wordStartTime = (paragraphStartTime + index * durationPerWord).toFixed(2);
              const wordEndTime = (paragraphStartTime + (index + 1) * durationPerWord).toFixed(2);

              return `${word} [${wordStartTime}s - ${wordEndTime}s]`
            })
            .join(', ');
        }
        else {
          wordsWithTime = segment.words
            .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
            .join(', ');
        }

        return `${segment.speakerOnly}: ${segment.speakerText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
      })
      .join('\n');


    await saveUpdatedTranscription(id, fullRawDetails);

    const transcriptionWords = transcriptionWordResult.data;

    const parsedSegments = parseTranscription(fullRawDetails, transcriptionWords, true);
    setTranscriptionData(parsedSegments);

    setRefreshTrigger(prev => !prev);
    handleCloseEditParagraph();
    setsaveBntDisabled(false);
    setIsSaving(false);
  }
  const handleSaveParagraphV2 = async () => {
    setsaveBntDisabled(true);
    if (isSaving) return; // Prevent multiple calls
    setIsSaving(true);
    setEditParagraphModalOpen(false);
    let editableParagraphToSave = editableParagraphv2;

    if (editableParagraphToSave === "") {
      editableParagraphToSave = "[deletedline]";
      setEditableTextV2(editableParagraphToSave);
      console.log(editableParagraph)
    }

    const segmentIndex = isSegmentIndexParagraph;
    const selectedSegment = isSegmentParagraph;

    const updatedTranscriptionData = [...transcriptionData];

    let transcriptionWordId = 0;
    const id = selectedFile.transcriptions[0].id;

    let originalWord = updatedTranscriptionData[segmentIndex].speakerText;

    originalWord = originalWord == '' ? updatedTranscriptionData[segmentIndex].speakerText : originalWord;

    const combinedTimestamp = '[' + updatedTranscriptionData[segmentIndex].words[0].startTime + ' - ' + updatedTranscriptionData[segmentIndex].words[updatedTranscriptionData[segmentIndex].words.length - 1].endTime + ']'

    if (selectedFile.transcriptions[0].transcriptionWords.length > 0) {
      let transcriptionWordObj = selectedFile.transcriptions[0].transcriptionWords.filter(f => f.originalWord == originalWord && f.originalTimestamp == combinedTimestamp);

      if (transcriptionWordObj.length > 0) {
        transcriptionWordId = transcriptionWordObj[0].id;
      }
    }

    const payloadTranscriptionWord =
    {
      id: transcriptionWordId,
      transcriptionId: id,
      originalWord: originalWord,
      originalTimestamp: combinedTimestamp,
      customWord: editableParagraphToSave,
      customTimestamp: combinedTimestamp,
      email: user.email,
      IsModifiedParagraph: true
    }

    let transcriptionWordResult = await UpsertTranscriptionWord(payloadTranscriptionWord);

    const speakerOnly = selectedSegment.speakerOnly;
    const speakerText = editableParagraphToSave;
    const startTime = selectedSegment.startTime;

    const speakerSegments = {
      speakerOnly,
      speakerText,
      startTime,
      words: []
    };

    speakerSegments.words = selectedSegment.words;

    updatedTranscriptionData[segmentIndex] = speakerSegments;

    const fullRawDetails = updatedTranscriptionData
      .map((segment) => {

        const totalWords = segment.speakerText.split(' ').length;
        const paragraphStartTime = segment.words[0].startTime;
        const paragraphEndTime = segment.words[segment.words.length - 1].endTime;

        const durationPerWord = totalWords > 0 ? (paragraphEndTime - paragraphStartTime) / totalWords : 0;
        let wordsWithTime = '';

        if (updatedTranscriptionData[segmentIndex].words[0].startTime == paragraphStartTime &&
          updatedTranscriptionData[segmentIndex].words[updatedTranscriptionData[segmentIndex].words.length - 1].endTime == paragraphEndTime) {

          wordsWithTime = segment.speakerText.split(' ')
            .map((word, index) => {
              const wordStartTime = (paragraphStartTime + index * durationPerWord).toFixed(2);
              const wordEndTime = (paragraphStartTime + (index + 1) * durationPerWord).toFixed(2);

              return `${word} [${wordStartTime}s - ${wordEndTime}s]`
            })
            .join(', ');
        }
        else {
          wordsWithTime = segment.words
            .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
            .join(', ');
        }

        return `${segment.speakerOnly}: ${segment.speakerText} <ts#0x1>${wordsWithTime}</ts#0x1>`;
      })
      .join('\n');


    await saveUpdatedTranscription(id, fullRawDetails);

    const transcriptionWords = transcriptionWordResult.data;

    const parsedSegments = parseTranscription(fullRawDetails, transcriptionWords, true);
    setTranscriptionData(parsedSegments);

    setRefreshTrigger(prev => !prev);
    handleCloseEditParagraph();
    handleModalCloseEditParagraphModal(false);
    setsaveBntDisabled(false);
    setIsSaving(false);
  }
  const onMoveFileToFolder = () => {
    if (selectedFile && selectedFile.transcriptions.length > 0) {

      setOpenMoveFileModal(true);

      setRefreshTrigger(prev => !prev);
      console.log(isOpenMoveFileModal);
    }
  }

  const handleCloseMoveFileModal = () => {
    setOpenMoveFileModal(false);
  }

  const filteredFiles = tableData.filter((fileRow) =>
    fileRow.some((cell, index) => {
      if (index >= 6) return false; // Only check the first 6 columns

      const lowerCaseCell = cell?.toString().toLowerCase(); // Handle potential undefined/null values
      const lowerCaseSearchTerm = searchTerm?.toLowerCase() || "";
      const lowerCaseFileType = fileType?.toLowerCase() || "";
      const lowerCaseFilesStatus = status?.toLowerCase() || "";
      const lowerCaseFilesUploadedDate = uploadDate?.toLowerCase() || "";

      return (
        lowerCaseCell.includes(lowerCaseSearchTerm) &&
        (fileType === "View All" || lowerCaseCell.includes(lowerCaseFileType)) &&
        (status === "View All" || lowerCaseCell.includes(lowerCaseFilesStatus)) &&
        (uploadDate === "" || lowerCaseCell.includes(lowerCaseFilesUploadedDate))
      );
    })
  );


  const transformDataToTree = (data) => {
    const idToNodeMap = new Map();
    const roots = [];

    data.forEach((item) => {
      if (item.isParent) {
        idToNodeMap.set(item.id, {
          title: item.name,
          key: item.id.toString(),
          fileId: item.fileId,
          isParent: item.isParent,
          children: [],
          files: item.files || [],
          isLeaf: false,
        });
      }
    });

    data.forEach((item) => {
      if (item.isParent) {
        const key = item.id;
        const node = idToNodeMap.get(key);

        if (item.parentId !== null) {
          const parentKey = item.parentId;
          const parent = idToNodeMap.get(parentKey);

          if (parent) {
            parent.children.push(node);
          }
        } else {
          roots.push(node);
        }

      }
    });

    setFilteredTreeData(roots);

    return roots;
  };

  const onExpand = (keys) => {
    setExpandedKeys(keys);
  };

  const onSelect = (selectedKeys, { selected, node }) => {
    //setIsLoadingData(false);

    setHasSelectedNode(selectedKeys.length > 0);

    if (!node) {
      console.warn("Node is undefined");
      return;
    }

    if (selected && node.isParent) {
      const files = node.files || [];

      const formattedData = files.map((file) => [
        file.name || "Untitled",
        file.uploadedDate || "Unknown date",
        file.formattedDuration || "Unknown duration",
        file.type || "Unknown",
        file.formattedSize || "Unknown size",
        file.transcriptionStatus || "No transcription",
        file,
      ]);

      setTableData(formattedData);

      updateBreadcrumbs(node);
    } else if (selected && !node.isParent) {
      let files = [];

      const file = node.data || userFiles.filter(f => f.id == node.fileId)[0];
      files.push(file);

      const formattedData = files.map((file) => {
        const transcriptionStatus = file.transcriptions.length > 0
          ? file.transcriptions[0].transcriptionStatus.name
          : "No transcription";
        return [
          file.audioTitleName || "Untitled",
          formatDate(file.uploadedDate) || "Unknown date",
          formatDuration(file.duration) || "Unknown duration",
          file.type || "Unknown",
          file.formattedSize || "Unknown size",
          transcriptionStatus,
          file
        ];
      });

      setTableData(formattedData);
      // setTableData([node.details || []]);
      updateBreadcrumbs(node);
    } else {
      setTableData([]);
    }
  };

  const updateBreadcrumbs = (node) => {
    const path = [];
    let currentNode = node;
    while (currentNode) {
      path.unshift({ title: currentNode.title, key: currentNode.key });
      currentNode = currentNode.parent;
    }
    //setBreadcrumbs([{ title: "Files", key: null }, ...path]);
  };

  // const handleBreadcrumbClick = (key) => {
  //   setIsLoadingData(false);

  //   if (key === null) {
  //     setHasSelectedNode(false);
  //     //  setTableData([]);
  //     setBreadcrumbs([{ title: "Files", key: null }]);
  //   } else {
  //     setHasSelectedNode(true);

  //     const findNode = (nodes, key) =>
  //       nodes.find((node) => node.key === key || (node.children && findNode(node.children, key)));

  //     const node = findNode(treeData, key);
  //     if (node) {
  //       const files = node.files;
  //       const formattedData = files.map((file) => {
  //         const transcriptionStatus = file.transcriptions.length > 0
  //           ? file.transcriptions[0].transcriptionStatus.name
  //           : "No transcription";
  //         return [
  //           file.audioTitleName || "Untitled",
  //           file.type || "Unknown",
  //           file.formattedSize || "Unknown size",
  //           formatDuration(file.duration) || "Unknown duration",
  //           formatDate(file.uploadedDate) || "Unknown date",
  //           transcriptionStatus,
  //           file
  //         ];
  //       });

  //       setTableData(formattedData);
  //       updateBreadcrumbs(node);
  //     }
  //   }
  // };

  const handleSearch = (e) => {
    const searchString = e.target.value.toLowerCase();
    setSearchTerm(searchString);

    if (!searchString.trim()) {
      // Reset to original data when search input is empty
      setFilteredTreeData(treeData);
      setTableData(tableDataBackup);
      return;
    }

    // Filter tree data
    const filteredTree = filterTree(treeData, searchString);
    setFilteredTreeData(filteredTree);

    // Filter table data
    const filteredFiles = tableData.filter((row) =>
      row.some((cell, index) =>
        index < 6 && typeof cell === "string" && cell.toLowerCase().includes(searchString)
      )
    );
    setTableData(filteredFiles);
  };

  const handleFileTypeChange = (e) => {
    const selectedFileType = e;
    setFileType(selectedFileType);
    console.log('tableDataBackup:', tableDataBackup);

    if (selectedFileType === "View All") {
      setTableData(tableDataBackup);
    }
    else {
      const filteredFiles = tableData.filter((row) =>
        row.some((cell, index) =>
          index < 6 && typeof cell === "string" && cell == selectedFileType
        )
      );

      setTableData(filteredFiles);
    }
  };


  const handleStatusChange = (e) => {
    const selectedStatus = e;
    setStatus(selectedStatus);

    if (selectedStatus === "View All") {
      setTableData(tableDataBackup);
      return;
    }

    const filteredFiles = tableData.filter((row) =>
      row.some((cell, index) =>
        index < 6 && typeof cell === "string" && cell == selectedStatus
      )
    );

    setTableData(filteredFiles);
  };
  const handleUploadDateChange = (e) => {
    const selectedDate = e;
    setUploadDate(selectedDate);

    if (!selectedDate) {
      setTableData(tableDataBackup);
      return;
    }

    const filteredFilesUpdateDate = tableData.filter((row) =>
      row.some((cell, index) =>
        index < 6 && typeof cell === "string" && cell.toLowerCase().includes(selectedDate)
      )
    );

    setTableData(filteredFilesUpdateDate);
  };
  const filterTree = (nodes, term) => {
    const filteredNodes = nodes.map((node) => {
      const matches = node.title.toLowerCase().includes(term);
      const filteredChildren = Array.isArray(node.children)
        ? filterTree(node.children, term)
        : [];
      if (matches || filteredChildren.length > 0) {
        return { ...node, children: filteredChildren };
      }
      return null;
    });

    return filteredNodes.filter(Boolean); // Remove null nodes
  };

  const handleAddParentFolder = async () => {
    const payload =
    {
      id: 0,
      name: folderTxtValue,
      isParent: true,
      parentId: null,
      userId: 0,
      email: user.email,
      fileId: 0
    };

    await addParentFolder(payload);
    setDisplayFolderTxtField(false);
    setFolderTxtValue('');
    setRefreshTrigger(prev => !prev);

  }

  const handledisplayFolderTxtField = async (showTxtField) => {
    setDisplayFolderTxtField(showTxtField);

    if (!showTxtField) {
      setFolderTxtValue('');
    }
  }
  const handleSort = (columnIndex) => {
    if (orderBy === columnIndex) {
      setOrder(order === "desc" ? "asc" : "desc"); // Toggle sorting direction
    } else {
      setOrder("asc"); // New column should start as "desc"
      setOrderBy(columnIndex);
    }
  };

  const getSortIcon = (columnIndex) => {
    if (orderBy === columnIndex) {
      return order === "desc" ? <ArrowDropDown fontSize="small" /> : <ArrowDropUp fontSize="small" />;
    }
    return <ArrowDropDown fontSize="small" style={{ opacity: 0.3 }} />;
  };

  const parseFileSize = (sizeStr) => {
    const units = { B: 1, KB: 1024, MB: 1024 ** 2, GB: 1024 ** 3 };
    const match = sizeStr.match(/([\d.]+)\s*(B|KB|MB|GB)/i);

    if (!match) return 0; // Default to 0 if format is incorrect

    const value = parseFloat(match[1]); // Extract number
    const unit = match[2].toUpperCase(); // Extract unit (MB, GB, etc.)

    return value * (units[unit] || 1); // Convert to bytes
  };

  const sortedFiles = [...filteredFiles].sort((a, b) => {
    if (orderBy === null) return 0;

    let valA = a[orderBy] ?? "";
    let valB = b[orderBy] ?? "";

    // Handle File Size Sorting
    if (orderBy === 5) {
      valA = parseFileSize(valA);
      valB = parseFileSize(valB);
    }

    // Handle Number Sorting
    if (!isNaN(valA) && !isNaN(valB)) {
      return order === "desc" ? valB - valA : valA - valB;
    }

    // Handle Text Sorting
    return order === "desc"
      ? valB.toString().localeCompare(valA.toString())
      : valA.toString().localeCompare(valB.toString());
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const paginatedFiles = sortedFiles.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(sortedFiles.length / rowsPerPage);
  const listHeight = transcriptionData.length > 20 ? 320 : transcriptionData.length * 25;
  const shouldScroll = listHeight >= 400;

  return (
    <div>
      {isLoadingData && (
        <div style={{ top: '47%', left: '47.5%', zIndex: 1, position: 'absolute' }}>
          <CircularProgress size={50} />
        </div>
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ marginTop: '2px' }}>
            {/* <CardHeader color="primary">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <h4 className={classes.cardTitleWhite}>Files</h4>
                  <p className={classes.cardCategoryWhite}>
                    List of transcriptions
                  </p>
                </div>
                <div>
                  <Button startIcon={<CloudUpload />} className="btnUpload" variant="contained" onClick={UploadModalV2}>Upload</Button>
                </div>
              </div>

            </CardHeader> */}
            <div style={{ display: 'none' }}>
              <TextField
                label="Folder Name"
                size="small"
                variant="outlined"
                value={folderTxtValue}
                onChange={(event) => { setFolderTxtValue(event.target.value) }}
                style={{ display: !displayFolderTxtField ? 'none' : '' }}>

              </TextField>
              <Tooltip title='Add new folder' arrow placement='bottom'
                style={{ display: displayFolderTxtField ? 'none' : '', marginTop: '-2px' }}>
                <IconButton onClick={() => handledisplayFolderTxtField(true)}>
                  <CreateNewFolderRounded fontSize='large' />
                </IconButton>
              </Tooltip>
            </div>

            <div style={{
              display: !displayFolderTxtField ? 'none' : '', marginTop: '8px',
              marginRight: '5px', marginLeft: '5px', cursor: 'pointer'
            }}>
              <Tooltip title='Save new folder' arrow placement='bottom' style={{ display: folderTxtValue == '' ? 'none' : '' }}>
                <SaveRounded fontSize='large' color='primary' onClick={handleAddParentFolder} />
              </Tooltip>
              <Tooltip title='Cancel' arrow placement='bottom' >
                <Close fontSize='large' style={{ color: 'rgba(0, 0, 0, 0.54)' }} onClick={() => handledisplayFolderTxtField(false)} />
              </Tooltip>
            </div>
            <CardBody style={{ height: '70vh', padding: '0px' }}>

              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px", background: "linear-gradient(rgb(244 248 255 / 82%) 10%, rgb(255, 255, 255) 90%)", padding: "20px" }}>

                <div style={{ display: "flex", gap: "15px" }}>
                  <Button startIcon={<CloudUpload />} className="btnUpload" variant="contained" onClick={UploadModalV2}>Upload</Button>
                  {isMSAccount && (<Button startIcon={<img src={teamsIcon} alt="Teams" style={{ height: '20px' }} />} className="breadCrummbBtns" variant="outlined" onClick={UploadImportTeams}>Import Teams</Button>)}
                  <Button startIcon={<Mic />} className="breadCrummbBtns" variant="outlined" onClick={RecordNowModal}>Record Now</Button>
                  <Button startIcon={<Folder />} className="breadCrummbBtns" variant="outlined" onClick={FolderTreeViewModal}>Folders</Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "15px", marginBottom: "10px" }}>
                  <TextField className="breadCrummbRight-uploadDate"
                    label="Upload Date"
                    type="date"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    value={uploadDate}
                    onChange={(e) => handleUploadDateChange(e.target.value)}
                    sx={{ minWidth: 150 }}
                  />
                  <FormControl className="breadCrummbRight" size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>File Type</InputLabel>
                    <Select value={fileType} onChange={(e) => handleFileTypeChange(e.target.value)} label="File Type">
                      <MenuItem value="View All">View All</MenuItem>
                      {fileTypes.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="breadCrummbRight" size="small" sx={{ minWidth: 120 }}>
                    <InputLabel>Status</InputLabel>
                    <Select value={status} onChange={(e) => handleStatusChange(e.target.value)} label="Status">
                      <MenuItem value="View All">View All</MenuItem>
                      {statuses.map((status) => (
                        <MenuItem key={status} value={status}>{status}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField className="breadCrummbRight-search"
                    label="Search files and folders"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    InputLabelProps={{ shrink: true }}
                    style={{}}
                  />
                </div>


              </div>
              {/* <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs.map((crumb) =>
                  crumb.key === null ? (
                    <Link
                      style={{ cursor: 'pointer' }}
                      key={crumb.key || "files"}
                      underline="hover"
                      color="inherit"
                      onClick={() => handleBreadcrumbClick(null)}
                    >
                      Files
                    </Link>
                  ) : (
                    <Link
                      style={{ cursor: 'pointer' }}
                      key={crumb.key}
                      underline="hover"
                      color="inherit"
                      onClick={() => handleBreadcrumbClick(crumb.key)}
                    >
                      {crumb.title}
                    </Link>
                  )
                )}
              </Breadcrumbs> */}

              <div style={{ display: "flex", gap: "20px" }}>
                {/* Tree View Section */}
                <div style={{ width: "1px", overflowY: 'hidden' }}>
                  <Tree
                    treeData={filteredTreeData}
                    expandedKeys={expandedKeys}
                    onExpand={onExpand}
                    onSelect={(selectedKeys, info) => {
                      onSelect(selectedKeys, info);
                    }}
                    defaultExpandAll
                  />
                </div>

                <div style={{ marginTop: "-40px", flex: 1, marginLeft: "-20px", padding: "10px" }}>
                  {tableData.length > 0 ? (
                    <>
                      <Table
                        className="files-tbl"
                        tableHeaderColor="primary"
                        tableHead={[
                          "Audio File Name",
                          "Upload Date",
                          "Duration",
                          "File Type",
                          "File Size",
                          "Transaction Status",
                          "Action",
                        ].map((header, index) => (
                          <TableCell key={index} onClick={() => handleSort(index)} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                            {header} {getSortIcon(index)}
                          </TableCell>
                        ))}
                        tableData={paginatedFiles.map((fileRow) => [
                          ...fileRow.slice(0, 5),
                          fileRow[5] === "Converting" || fileRow[5] === "Transcribing" || fileRow[5] === "Re Transcribing" ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {fileRow[5]}
                              <CircularProgress size={20} sx={{ marginLeft: '8px' }} />
                            </Box>
                          ) : (
                            fileRow[5]
                          ),
                          <IconButton key={fileRow[0]} className={classes.iconButton} onClick={(event) => handleClick(event, fileRow[6])}>
                            <MoreVertIcon />
                          </IconButton>,
                        ])}
                      />

                      {/* <Table
                        className="files-tbl"
                        tableHeaderColor="primary"
                        tableHead={[
                          "File Title",
                          "File Type",
                          "File Size",
                          "Duration",
                          "Upload Date",
                          "Status",
                          "Action",
                        ]}
                        tableData={filteredFiles.map((fileRow) => [
                          ...fileRow.slice(0, 5), // Extract all fields except the action column
                          fileRow[5] === "Converting" || fileRow[5] === "Transcribing" || fileRow[5] === "Re Transcribing" ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {fileRow[5]} {/* Display the current status text */}
                      {/* <CircularProgress size={20} sx={{ marginLeft: '8px' }} />
                            </Box>
                          ) : (
                            fileRow[5] // Display the status as text when it's not converting or transcribing
                          ), */}
                      {/* <IconButton
                            key={fileRow[0]}
                            className={classes.iconButton}
                            onClick={(event) => handleClick(event, fileRow[6])}
                          >
                            <MoreVertIcon />
                          </IconButton>,
                        ])}
                      /> */}
                    </>
                  ) : (
                    <div style={{ top: '50%', left: '45%', position: 'absolute' }}>
                      <Typography variant="body1">No content to display</Typography>
                    </div>
                  )}
                </div>
              </div>
              {/* OLD IMPLEMENTATION */}
              {/* Table for displaying fetched files */}
              <div style={{ display: 'none' }}>

                <Table
                  tableHeaderColor="primary"
                  tableHead={[
                    "Audio File Name",
                    "Upload Date",
                    "Duration",
                    "File Type",
                    "File Size",
                    "Transaction Status",
                    "Action",
                  ]}
                  tableData={filteredFiles.map((fileRow) => [
                    ...fileRow.slice(0, 5), // Extract all fields except the action column
                    fileRow[5] === "Converting" || fileRow[5] === "Transcribing" || fileRow[5] === "Re Transcribing" ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {fileRow[5]} {/* Display the current status text */}
                        <CircularProgress size={20} sx={{ marginLeft: '8px' }} />
                      </Box>
                    ) : (
                      fileRow[5] // Display the status as text when it's not converting or transcribing
                    ),
                    <IconButton
                      key={fileRow[0]}
                      className={classes.iconButton}
                      onClick={(event) => handleClick(event, fileRow[6])}
                    >
                      <MoreVertIcon />
                    </IconButton>,
                  ])}
                />
              </div>
              {/* END OF OLD IMPLEMENTATION */}
            </CardBody>
          </Card>
          {tableData.length > 0 && (
            <div className="pagination-container">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
              />
            </div>
          )}
        </GridItem>
        {/* Modal to display transcription */}
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropProps={{
            onClick: (e) => e.stopPropagation(),
          }}
        >
          <div>
            {isSaving && (
              <>
                <div className="loading-overlay"></div>
                <div className="loading-indicator">
                  <CircularProgress size={20} /><span style={{ color: 'green', fontSize: '13px', fontWeight: 'bold', marginLeft: '10px', marginTop: '-5px' }}>Saving...</span>
                </div>
              </>
            )}
            {isLoading ? (
              <div className="modalContent" style={{ width: '300px', marginTop: '10%', top: '10%' }}>
                <p style={{ fontWeight: 'bolder', fontSize: '20px', marginRight: '10px' }}>Loading Audio<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>
              </div>
            ) : (
              <div className="modalContent">
                {/* hidden span to be used for matching the input length to the selectedtext */}
                <span
                  ref={spanRef}
                  style={{
                    visibility: "hidden",
                    position: "absolute",
                    whiteSpace: "pre",
                    fontSize: "16px", // Match the input font style
                  }}>
                  {selectedEditText || " "} {/* Ensure there's a space for empty input */}
                </span>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <div style={{ display: 'none' }}>
                    <Tooltip title={<span style={{ fontSize: '1.5em' }}>Download Transcript</span>}>
                      <GetAppIcon style={{ marginRight: '5px', cursor: 'pointer', color: '#828787' }} onClick={downloadModal} />
                    </Tooltip>
                  </div>

                  <Tooltip title={<span style={{ fontSize: '1.5em' }}>Click on a word to play it, or right click to edit it</span>}>
                    <HelpOutlineIcon style={{ marginRight: '20px', color: '#828787' }} />
                  </Tooltip>
                  <button onClick={handleModalClose} className="modalButton">
                    X
                  </button>
                </div>
                <Box sx={styles}>
                  <Tabs value={value} onChange={handleTabChange} aria-label="tabs example">
                    <Tab label="Transcription" style={{ color: '#00acc1', textTransform: 'capitalize' }}{...a11yProps(0)} />
                    <Tab label="AI Chat" style={{ color: '#00acc1', textTransform: 'capitalize' }} {...a11yProps(1)} />
                  </Tabs>
                  {/* Tab Panels */}
                  <TabPanel value={value} index={0}>
                    {transcriptionData ? (
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px',justifyContent:'center' }}>
                        {/* Audio Icon */}
                        <AudioFileIcon style={{ fontSize: '28px' }} />

                        {/* File Info (Stacked) */}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontWeight: 'bold', fontSize: '13px'}}>
                            {selectedFile?.audioTitleName || "Untitled Audio File"}
                          </span>
                          <span style={{ fontSize: '12px', color: '#666' }}>
                            {selectedFile?.formattedSize}
                          </span>
                        </div>
                      </div>
                    ) : <div />}
                    <div className="audioPlayerContainer">
                      {audioUrl && (
                        <AudioPlayer
                          ref={audioPlayerRef}
                          src={audioUrl}
                          autoPlay={false}
                          controls
                          customAdditionalControls={[]}
                          onPlay={() => {
                            if (audioPlayerRef.current?.audio?.current) {
                              audioPlayerRef.current.audio.current.addEventListener('timeupdate', handleTimeUpdate);
                            }
                          }}
                          onPause={() => {
                            console.log("Pause triggered");
                            if (isDebouncing) return;

                            isDebouncing = true;
                            setTimeout(() => (isDebouncing = false), 200); // Adjust debounce time as needed

                            if (audioPlayerRef.current?.audio?.current) {
                              audioPlayerRef.current.audio.current.removeEventListener('timeupdate', handleTimeUpdate);
                            }
                          }}
                        />
                      )}
                    </div>
                    <Card style={{ marginTop: '40px', padding: '10px',width: '97%', marginBottom: '15px' }}>
                      <div>
                        {transcriptionData ? (
                          <div style={{ marginLeft: '-11px', marginBottom: '5px' }}>
                            <Checkbox
                              checked={showTranscribeTextTimestamp}
                              onChange={handleShowTranscribedTextTimestamp}></Checkbox> <small>Display Timestamp</small>
                          </div>) : (<div />)
                        }
                        {transcriptionData && transcriptionData.length > 0 ? (
                          <div style={{ height: `${listHeight}px`, overflow: shouldScroll ? "auto" : "hidden" }} className="transcriptionContainer" ref={transcriptionContainerRef} onKeyDown={handleKeyDown} onScroll={handleScroll}>
                            <List
                              height={listHeight}
                              itemCount={transcriptionData.length}
                              itemSize={(index) => {
                                const item = transcriptionData[index];
                                const baseHeight = Math.max(25, Math.min(200, item.speakerText.length / 10 * 5));
                                const adjustedHeight = windowWidth < 768 ? baseHeight * 2 : baseHeight;
                                return adjustedHeight;
                              }}
                              width="100%"
                            >
                              {
                                ({ index, style }) => {
                                  const segment = transcriptionData[index];
                                  return (
                                    <div style={style} key={index}>
                                      <Tooltip placement='left' title="Right click to edit the speaker" arrow>
                                        <Typography
                                          className="editParagraph"
                                          variant="h6"
                                          component="span"
                                          onContextMenu={(event) => editSpeaker(segment, index, event)}
                                          style={{
                                            marginTop: '5px', fontWeight: 'bold', clear: 'both', fontSize: '15px',

                                          }} // Ensure label is separated from previous lines
                                        >
                                          {showTranscribeTextTimestamp ? (
                                            <Link onClick={() => playAudioForWord(segment.words[0].word, segment.words[0].startTime, segment.words[0].endTime)}
                                              style={{ cursor: 'pointer', fontWeight: 'normal', fontStyle: 'italic', fontSize: '12px' }}
                                              underline="none">{segment.startTime}&nbsp;
                                            </Link>)
                                            : ''}

                                          {segment.speakerOnly}&nbsp;

                                        </Typography>
                                      </Tooltip>

                                      {/* Display words for the current speaker */}
                                      <div style={{
                                        display: 'inline',
                                        maxWidth: '700px',
                                        color: segment.isModifiedParagraph ? 'rgba(255,64,129,1)' : '#3c4858',
                                        fontWeight: segment.isModifiedParagraph ? 'bold' : 'normal',
                                      }}
                                        className='transcriptionSegmentParagraph'>
                                        {isParagraphEditing?.segmentIndex === index ? (
                                          <div style={{ display: 'inline-block' }}>
                                            <div style={{
                                              display: 'inline-block',
                                              position: 'relative', float: 'right', marginTop: '-5px'
                                            }}>
                                              <Tooltip
                                                title='Cancel'
                                                placement="bottom"
                                                arrow>
                                                <span style={{ cursor: 'pointer' }}>
                                                  <IconButton size="small" style={{ margin: '1px' }}>
                                                    <Close size="small" onClick={handleCloseEditParagraph} style={{ color: 'rgb(233, 30, 99)', padding: '1px', width: '20px', marginRight: '1px' }}></Close>
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                              <Tooltip
                                                title='Save'
                                                placement="bottom"
                                                arrow>
                                                <span style={{ cursor: 'pointer' }}>
                                                  <IconButton size="small" disabled={saveBntDisabled} style={{ margin: '1px' }}>
                                                    <Check size="small" style={{ color: 'rgb(46, 125, 50)', padding: '1px', width: '20px', marginRight: '1px', marginLeft: '1px' }}
                                                      onClick={async () => await handleSaveParagraph(segment, index)} />
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                            </div>

                                          </div>
                                        )
                                          :
                                          (
                                            segment.words.map((wordSegment, wordIndex) => (
                                              <span key={wordIndex} style={{ display: 'inline-block' }}>
                                                <Tooltip
                                                  title={
                                                    wordSegment.isEdited
                                                      ? 'Original: ' + wordSegment.originalWord
                                                      : segment.isModifiedParagraph
                                                        ? 'Original: ' + segment.originalParagraph
                                                        : ''
                                                  }
                                                  arrow
                                                >
                                                  <span>
                                                    <Typography
                                                      key={wordSegment.startTime}
                                                      id={`segment-${wordSegment.startTime}`}
                                                      variant="body2"
                                                      component="span"
                                                      onContextMenu={(event) =>
                                                        handleDoubleClick(index, wordIndex, wordSegment.startTime, wordSegment.endTime, event)
                                                      }
                                                      style={{
                                                        display: 'inline',
                                                        color: wordSegment.isEdited || segment.isModifiedParagraph ? 'rgba(255,64,129,1)' : '#3c4858',
                                                        fontWeight: wordSegment.isEdited || segment.isModifiedParagraph ? 'bold' : 'normal',
                                                      }}
                                                      className={`transcriptionSegment ${highlightedSegmentIndex &&
                                                        highlightedSegmentIndex.startTime <= wordSegment.endTime &&
                                                        highlightedSegmentIndex.endTime >= wordSegment.startTime
                                                        ? 'highlighted'
                                                        : ''
                                                        }`}
                                                      onClick={() => {
                                                        // Only play audio if the word is not being edited
                                                        if (!(isEditing?.segmentIndex === index && isEditing?.wordIndex === wordIndex)) {
                                                          playAudioForWord(wordSegment.word, wordSegment.startTime, wordSegment.endTime);
                                                        }
                                                      }}
                                                    >
                                                      <>
                                                        {wordSegment.word}
                                                        {wordSegment.isEdited && (
                                                          <Tooltip arrow placement="right" title="Undo">
                                                            <span>
                                                              <Undo
                                                                className="word-icon"
                                                                fontSize="small"
                                                                onClick={() =>
                                                                  onRestoreOriginalWord(
                                                                    wordSegment.transcriptionWordId,
                                                                    wordSegment.originalWord,
                                                                    index,
                                                                    wordIndex
                                                                  )
                                                                }
                                                              />
                                                            </span>
                                                          </Tooltip>
                                                        )}
                                                      </>
                                                      &nbsp;
                                                    </Typography>
                                                  </span>
                                                </Tooltip>
                                              </span>
                                            ))
                                          )}
                                        {
                                          (segment.isModifiedParagraph ?
                                            <Tooltip arrow placement="right" title="Undo">
                                              <span>
                                                <Undo className="paragraph-icon" fontSize="small" onClick={() =>
                                                  onRestoreOriginalWord(segment.paragraphTranscriptionWordId, segment.originalParagraph, index, 0, true)}
                                                ></Undo>
                                              </span>
                                            </Tooltip>
                                            : '')
                                        }
                                        {(isParagraphEditing?.segmentIndex === index ? '' :
                                          <Tooltip arrow placement="right" title="Edit the line" >
                                            <span>
                                              <Edit className="edit-paragraph-icon"
                                                fontSize="small"
                                                size="small"
                                                onClick={() => handleEditParagraph(segment, index)}></Edit>
                                            </span>
                                          </Tooltip>)}
                                      </div>

                                    </div>
                                  );
                                }
                              }
                            </List>
                          </div>
                        ) : (
                          <Typography variant="body2">No transcription available</Typography>
                        )}
                      </div>
                    </Card>
                    <div style={{ float: 'right' }}>
                      <Button variant="contained" appearance='primary' className='audioly-primary-btn' onClick={downloadModal} style={{ marginTop: '10px' }}>Download Transcript</Button>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div>
                      <b>Note:</b>
                      <span style={{ marginLeft: '5px', fontSize: '14px', color: '#8f534e' }}>
                        Right click to edit or delete AI Quick Question
                      </span>
                    </div>
                    <div style={{ display: 'flex', margin: '10px', marginBottom: '40px' }}>
                      <div className='AutocompleteAIQuestion'>
                        <Autocomplete
                          disablePortal
                          options={customButtons}
                          autoComplete={false}
                          getOptionLabel={(option) => option.text || ""}
                          value={customButtons.find((btn) => btn.text === selectedButton) || null}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              handleSelectChangev2(event, newValue);
                            }
                          }}
                          PopperComponent={(props) => (
                            <Popper
                              {...props}
                              disablePortal
                              style={{ zIndex: 9999 }}
                              onMouseDown={(e) => e.stopPropagation()} // ✅ Prevents dropdown from closing when clicking inside
                            />
                          )}
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "preventOverflow",
                                  options: {
                                    boundary: "window",
                                  },
                                },
                              ],
                            },
                            paper: {
                              style: { zIndex: 9999 },
                            },
                          }}
                          ListboxProps={{


                            onContextMenu: (event) => {
                              console.log("Right-click detected, button:", event.button);
                              if (event.button === 2) {
                                event.preventDefault();
                                event.stopPropagation();
                                const item = event.target.closest("li");
                                if (item) {
                                  const optionText = item.textContent.trim();
                                  const option = customButtons.find((btn) => btn.text === optionText);
                                  if (option) {
                                    handleEditDeleteCustomButtonV2(event, option);
                                  }
                                }
                              }
                            },
                          }}
                          renderInput={(params) => <TextField {...params} placeholder="Select AI Quick Question" />}
                        />
                      </div>
                      <div style={{ float: 'right', right: '8%', display: 'inline-block', position: 'absolute' }}>
                        <Tooltip style={{ fontSize: '14px' }} title="Add AI Button">
                          <Button variant="contained" appearance='primary' startIcon={<AddIcon />} onClick={handleCreateNewCustomButton} className="newCustomBtn"></Button>
                        </Tooltip>
                      </div>
                    </div>



                    <Box sx={{ width: 'auto', margin: '20px auto' }}>
                      {/* Display created buttons */}
                      <Box sx={{ marginTop: 10 }}>
                        {/* <div style={{ justifyContent: 'space-between', alignItems: 'center', margin: '10px', display: 'none' }}>
                          <div >
                            <Typography component="span" variant="subtitle1">AI Buttons</Typography>
                            <div>
                              <b>Note:</b>
                              <span style={{ marginLeft: '5px', fontSize: '14px', color: '#8f534e' }}>
                                Right click to edit or delete AI button
                              </span>
                            </div>
                          </div>
                          <div>
                            <Tooltip style={{ fontSize: '14px' }} title="Add AI Button">
                              <Button variant="contained" appearance='primary' startIcon={<AddIcon />} onClick={handleCreateNewCustomButton} className="newCustomBtn"></Button>
                            </Tooltip>
                          </div>
                        </div> */}
                        <div className={`custom-btn-list ${customButtons.length === 0 ? 'no-border' : ''}`} style={{ display: 'none' }}>
                          {customButtons.map((btn, index) => (
                            <Tooltip style={{ fontSize: '14px' }} key={index} title={btn.text}>
                              <Button
                                key={index}
                                onClick={() => handleButtonClick(btn)}
                                onContextMenu={(e) => handleEditDeleteCustomButton(e, btn, index)}
                                variant="contained"
                                color="cyan"
                                appearance="primary"
                                style={{ backgroundColor: '#37d1e7', color: '#3b3939', marginRight: '5px', marginBottom: '5px', textTransform: 'capitalize', padding: '4px' }}
                              >
                                {btn.icon}
                              </Button>
                            </Tooltip>
                          ))}
                        </div>
                        {/*  */}
                        <div className='select-model-upload' style={{ display: 'none' }}>
                          <FormControl fullWidth margin="normal">
                            <InputLabel id="selectAIPrompts">Select AI Button</InputLabel>
                            <Select
                              labelId="selectAIPrompts"
                              id="selectAIPrompts"
                              value={selectedButton}
                              onChange={handleSelectChange}>
                              {customButtons.map((btn, index) => (
                                <MenuItem key={index} value={btn.text}
                                  onContextMenu={(e) => handleEditDeleteCustomButton(e, btn, index)} >
                                  {btn.icon} {btn.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {/* Searchable Dropdown */}

                        </div>


                      </Box>

                      {/* Chat Box */}
                      <hr style={{ marginBottom: '40px' }}></hr>
                      <div>
                        <span className="clearChatBtn" onClick={clearChat}>Clear Chat</span>
                        <Box sx={{ marginTop: 10, padding: '10px', borderRadius: '5px', maxHeight: '400px', overflow: 'auto' }}>
                          {messages.map((message, index) => (
                            <Box key={index} style={{ margin: '10px 0' }}>
                              {/* Display User Question */}
                              {message.type === 'user' && (
                                <Box style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0' }}>
                                  <div>
                                    <Typography variant="caption" style={{ marginLeft: '10px', alignSelf: 'center', display: 'block', fontSize: '8px' }}>
                                      {new Date(message.timestamp).toLocaleString()}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      style={{
                                        backgroundColor: '#00acc1',
                                        color: 'rgb(255 248 248)',
                                        padding: '5px 10px',
                                        borderRadius: '20px',
                                        fontSize: '11px',
                                      }}
                                    >
                                      {message.content}
                                    </Typography>
                                  </div>
                                </Box>
                              )}

                              {/* Display Azure Response */}
                              {message.type === 'azure' ? (
                                <Box style={{ display: 'block', justifyContent: 'flex-start', margin: '10px 0' }}>
                                  <Typography variant="caption" style={{ marginLeft: '10px', alignSelf: 'center', fontSize: '8px' }}>
                                    {new Date(message.timestamp).toLocaleString()}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    style={{
                                      backgroundColor: 'rgb(255 255 255)',
                                      color: '#000',
                                      paddingLeft: '10px',
                                      borderRadius: '10px',
                                      maxWidth: '70%',
                                      fontSize: '11px',
                                      // border: '1px solid #5454',
                                      // boxShadow: '1px 1px #7878'
                                    }}
                                  >
                                    <ReactMarkdown>{message.response}</ReactMarkdown>
                                  </Typography>
                                </Box>
                              ) : index === messages.length - 1 && isResponseChat ? (
                                <Box style={{ display: 'flex', justifyContent: 'flex-start', margin: '10px 0' }}>
                                  <div className="dotloading">...</div>
                                </Box>
                              ) : null}
                            </Box>
                          ))}
                          <div ref={messageEndRef} />
                        </Box>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                          <TextField
                            placeholder="Type your prompt here..."
                            fullWidth
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            onKeyDown={(e) => e.key === "Enter" && handleSend()}
                            InputProps={{ sx: { padding: "10px" } }}
                          />
                          <Button
                            style={{ background: 'none', boxShadow: 'none', color: '#919391' }}
                            variant="contained"
                            color="primary"
                            onClick={handleSend}
                            sx={{
                              ml: 2,
                              height: "56px",
                              minWidth: "56px", // Ensures square shape
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <SendIcon style={{ marginLeft: '20px' }} />
                          </Button>
                        </div>
                      </div>
                    </Box>
                  </TabPanel>
                </Box>
              </div>
            )}
          </div>
        </Modal>
        {/* Context menu for Edit and Delete */}
        <Menu
          open={contextMenu.mouseY !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu.mouseY !== null && contextMenu.mouseX !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleEdit}>
            <EditIcon style={{ marginRight: '10px' }} /> Edit
          </MenuItem>
          {/* <MenuItem onClick={handleDelete}>
            <DeleteIcon style={{ marginRight: '10px' }} /> Delete
          </MenuItem> */}
          <MenuItem onClick={handleDeleteVe}>
            <DeleteIcon style={{ marginRight: '10px' }} /> Delete
          </MenuItem>
        </Menu>
        {/* Modal for editing the button */}
        <Modal
          open={isEditModalOpen}
          onClose={handleCloseEditModal}
          aria-labelledby="edit-button-modal-title"
          aria-describedby="edit-button-modal-description"
        >
          <div className="modalContent" style={{ width: '400px' }}>

            <Box sx={{
              position: 'absolute', top: '50%', left: '50%',
              transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper',
              boxShadow: 24, p: 4
            }}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <button onClick={handleCloseEditModal} className="modalButton">
                  X
                </button>
              </div>
              <Typography id="edit-button-modal-title" variant="h6" component="h2">
                Edit Button Text
              </Typography>
              <TextField
                fullWidth
                value={newButtonText}
                onChange={(e) => setNewButtonText(e.target.value)}
                sx={{ mt: 2 }}
              />
              <Button sx={{ mt: 2 }} className="btnUpload" variant="contained" style={{ marginTop: '10px', float: 'right' }} onClick={handleSaveEdit}>
                Update
              </Button>
            </Box>
          </div>

        </Modal>
        {/* Context Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleTranscriptionClick}>
            View Transcription
          </MenuItem>
          <MenuItem onClick={onClickRetranscribe}>
            Re-Transcribe
          </MenuItem>
          <MenuItem onClick={onMoveFileToFolder}>
            Move to Folder
          </MenuItem>
          <MenuItem onClick={handleDeleteAudio}>
            Delete
          </MenuItem>
        </Menu>
        {/* Context Menu Edit Speaker*/}
        <Menu
          anchorEl={anchorElSpeaker}
          open={Boolean(anchorElSpeaker)}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            anchorElSpeaker ? { top: anchorElSpeaker.top, left: anchorElSpeaker.left } : undefined
          }
        >

          <MenuItem onClick={handleSingleSpeakeModal}>
            Edit Single Speaker
          </MenuItem>
          <MenuItem onClick={handleMultipleSpeakeModal}>
            Edit All Speakers
          </MenuItem>
        </Menu>
        {/* Edit paragraph modal */}
        {modalOpenSpeaker && (<Modal
          open={modalOpenSpeaker}
          onClose={handleModalCloseSpeaker}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropProps={{
            onClick: (e) => e.stopPropagation(),
          }}
        >
          <div className="modalContent" style={{ width: '400px' }}>
            <div>
              <h4>
                {isSingleSpeaker ? "Edit Single Speaker" : isMutlipleSpeaker ? "Edit All Same Speaker" : null}
              </h4>
            </div>
            <TextField
              autoFocus
              margin="dense"
              label="Speaker Name"
              type="text"
              fullWidth
              variant="outlined"
              value={speakerName}
              onChange={(e) => setSpeakerName(e.target.value)}
            />
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button className="btnCancel" variant="contained" onClick={handleModalCloseSpeaker} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button className="btnUpload" variant="contained" onClick={() => {
                if (isSingleSpeaker) {
                  handleSingleSpeakerEdit();
                } else if (isMutlipleSpeaker) {
                  handleMultipleSpeakerEdit();
                }
              }} >
                Save
              </Button>
            </div>
          </div>
        </Modal>
        )}
        {/* Edit Single Word */}
        {isEditModalOpenSingleWord && (<Modal
          open={isEditModalOpenSingleWord}
          onClose={handleModalCloseEditSingleWordModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropProps={{
            onClick: (e) => e.stopPropagation(),
          }}
        >
          <div className="modalContent" style={{ width: '400px' }}>
            <div>
              <h4>
                Edit Word
              </h4>
            </div>
            <TextField
              autoFocus
              margin="dense"
              label="Paragraph"
              type="text"
              fullWidth
              variant="outlined"
              value={editableTextV2}
              onChange={(e) => setEditableTextV2(e.target.value)}
            />
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button className="btnCancel" variant="contained" onClick={handleModalCloseEditSingleWordModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button className="btnUpload" variant="contained" onClick={async () => await handleSaveSingleWordEdit()} >
                Save
              </Button>
            </div>
          </div>
        </Modal>
        )}
        {/* Edit Paragraph*/}
        {isEditParagraphModalOpen && (<Modal
          open={isEditParagraphModalOpen}
          onClose={handleModalCloseEditParagraphModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          BackdropProps={{
            onClick: (e) => e.stopPropagation(),
          }}
        >
          <div className="modalContent" style={{ width: '600px' }}>
            <div>
              <h4>
                Edit paragraph
              </h4>
            </div>
            <TextField
              autoFocus
              margin="dense"
              label="Paragraph"
              type="text"
              fullWidth
              variant="outlined"
              value={editableParagraphv2}
              onChange={(e) => setEditableParagraphV2(e.target.value)}
            />
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
              <Button className="btnCancel" variant="contained" onClick={handleModalCloseEditParagraphModal} style={{ marginRight: '10px' }}>
                Cancel
              </Button>
              <Button className="btnUpload" variant="contained" onClick={async () => await handleSaveParagraphV2()} >
                Save
              </Button>
            </div>
          </div>
        </Modal>
        )}
        {/* new custom button modal */}
        <Modal
          open={modalOpenNewCustomButton}
          onClose={handleModalCloseNewCustomButton}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className="modalContent aiButtonModal">
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <button onClick={handleModalCloseNewCustomButton} className="modalButton">
                X
              </button>
            </div>
            <Typography>Create a Question / Suggestion Button</Typography>

            {/* Icon Dropdown */}
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Select an Icon</InputLabel>
              <Select value={selectedIcon} onChange={handleIconChange} label="Select an Icon">
                {iconList.map((iconObj, index) => (
                  <MenuItem key={index} value={iconObj.name}>
                    {iconObj.icon} {iconObj.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* TextField for Question or Action */}
            <TextField
              fullWidth
              label="Enter your question or suggestion"
              value={questionText}
              onChange={handleTextChange}
              sx={{ marginBottom: 10 }}
            />

            {/* Button to create a new button */}
            <div style={{ marginTop: '10px', boxShadow: '1px 1px #dbd8db', borderRadius: '5px' }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleCreateButton}
                disabled={!selectedIcon || !questionText}
              >
                Create Button
              </Button>
            </div>
          </div>
        </Modal>
        <Dialog
          open={openSuccessDialog}
          onClose={() => setOpenSuccessDialog(false)}>

          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ display: 'inline-flex' }}>
                <Avatar style={{ backgroundColor: '#8bc34a' }}>
                  <Check />
                </Avatar>
                <span style={{ marginTop: '10px', marginLeft: '10px' }}>File uploaded successfully!</span>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSuccessDialog(false)} autoFocus>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSuccessDeleteDialog}
          onClose={() => setOpenSuccessDeleteDialog(false)}>

          <DialogTitle></DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div style={{ display: 'inline-flex' }}>
                <Avatar style={{ backgroundColor: '#8bc34a' }}>
                  <Check />
                </Avatar>
                <span style={{ marginTop: '10px', marginLeft: '10px' }}>Audio deleted successfully!</span>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenSuccessDeleteDialog(false)} autoFocus>Close</Button>
          </DialogActions>
        </Dialog>
        {showExpiredModal && (
          <div className="modaltokenexpire">
            <div className="modal-contenttokenexpire">
              <h2>Session Expired</h2>
              <p>Your session has expired. Please log in again to continue.</p>
              <button onClick={handleModalCloseTokenExpired}>Ok</button>
            </div>
          </div>
        )}
        <div>
          <UploadFile isOpen={modalUploadv2} handleClose={handleModalUploadCloseV2} selectedFile={selectedFile} />
        </div>
        <div>
          <RecordNowAudio isOpenRecordNow={isRecordNow} onRequestCloseRecordNow={RecordNowClose} selectedFile={selectedFile} />
        </div>
        <div>
          <ImportTeams isOpenImportTeams={isOpenImportTeams} handleCloseImportTeams={handleUploadImportTeamsModalClose} />
        </div>
        <div>
          <FolderTreeView isOpenFolderTreeview={modalFolderTreeview} handleCloseOpenFolderTreeview={handleModalFolderTreeviewClose} selectedFile={selectedFile} />
        </div>
        <div>
          {selectedFile && (
            <DownloadTranscirption isOpenDownload={isOpenDownloadModal} handleCloseDownload={handleModalDownloadClose} selectedFile={selectedFile} tableData={tableData} />

          )}
        </div>
        <div>
          {selectedFile && (
            <ReTranscribe isOpenRetranscribe={modalRetranscribe} handleCloseRetranscribe={handleModalRetranscribeClose} selectedFile={selectedFile} updateStatusRetranscribe={updateStatusRetranscribe} />

          )}
        </div>
        <div>
          {selectedFile && (
            <MoveFile
              isOpenMoveFile={isOpenMoveFileModal}
              handleCloseMoveFile={handleCloseMoveFileModal}
              selectedFile={selectedFile}
            />
          )}
        </div>
      </GridContainer>
    </div>
  );
}
