import React from 'react';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import {
    FormControl, InputLabel, Select, MenuItem, CircularProgress, TextField, Button, Typography, Box
} from '@material-ui/core';
import Dropzone from 'react-dropzone-uploader';
import { useState, useEffect, useRef } from 'react';
import { getModelTranscriptionResource1, getUsersFolder, addParentFolder, updateFolderName } from "services/api";
import { useAuth } from '../../services/authContext';
import { getCookie } from "components/Utils/cookies";
import { Folder, LibraryMusic } from "@material-ui/icons";
import { TreeView, TreeItem } from '@material-ui/lab';
//import { Delete } from '@material-ui/icons';
import { ArrowRight, ArrowDropDown } from '@material-ui/icons';
import CustomFolderOpenIcon from "assets/img/FolderOpenIcon.png";
const FolderOpenIcon = () => (
    <img src={CustomFolderOpenIcon} alt="Open Folder" style={{ width: 20, height: 20 }} />
);
const CHUNK_SIZE = 1024 * 1024;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
// Add PropTypes validation for TabPanel component
TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
};


const UploadFile = ({ isOpen, handleClose }) => {
    const [selectedModel, setSelectedModel] = useState('');
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setUpLoading] = useState(false);
    const dropzoneRef = useRef(null);
    const { user } = useAuth();
    const [usersFolder, setUsersFolder] = useState([]);
    const [selectedFolderId, setSelectedFolderId] = useState(0);
    const [treeData2, setTreeData2] = useState([]);
    const [expanded, setExpanded] = useState([]);
    //const [parentFolderValue2, setParentFolderValue2] = useState("");
    const [editingFolderId2, setEditingFolderId2] = useState(null);
    const [newFolderName, setNewFolderName] = useState("");
    const [addingFolderId, setAddingFolderId] = useState(null);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };





    const LoadingIndicator = () => (
        <div className="uploading-indicator">
            {isUploading && <p style={{ fontWeight: 'bolder', fontSize: '20px' }}>Uploading<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>}
        </div>
    );
    const fetchModels = async () => {
        try {
            setLoading(true);
            const modelsData = await getModelTranscriptionResource1();
            const sortedModels = modelsData.sort((a, b) =>
                b.displayName.localeCompare(a.displayName)
            );

            setModels(sortedModels);
            console.log("Fetched models:", sortedModels);
            setSelectedModel(sortedModels[2]?.self || '');
            console.log("Selected model:", selectedModel);
        } catch (error) {
            console.error("Error fetching models:", error);
        } finally {
            setLoading(false);
        }
    };



    useEffect(() => {
        fetchModels();

    }, []);

    const uploadFile = async ({ file }) => {
        setUpLoading(true);
        //const apiUrl = "https://localhost:7218" // process.env.REACT_APP_API_URL;
        const fileSize = file.size;
        const fileName = file.name;
        const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
        let completedChunks = 0;

        const token = getCookie('jwtToken');

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, fileSize);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append("chunk", chunk);
            formData.append("fileName", fileName);
            formData.append("fileSize", fileSize);
            formData.append("chunkIndex", chunkIndex);
            formData.append("totalChunks", totalChunks);

            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/UploadChunk", {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    console.error("Failed to upload chunk:", chunkIndex);
                    setUpLoading(false);
                    setUploadProgress(0); // Reset progress on failure
                    return; // Stop if chunk upload fails
                }

                completedChunks++;
                const progressPercentage = Math.floor((completedChunks / totalChunks) * 100);
                setUploadProgress(progressPercentage); // Update progress
            } catch (error) {
                console.error("Error uploading chunk:", error);
                setUpLoading(false);
                setUploadProgress(0); // Reset progress on error
                return; // Stop on error
            }
        }

        const completeResponse = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/CompleteUpload", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                FileName: fileName,
                FileSize: fileSize,
                AudioTitleName: "My Audio Title",
                UserEmail: user.email,
                UserName: user.name,
                CustomModel: selectedModel,
                FolderId: selectedFolderId,
                IsUploadFromOneDrive: false,
                FileUrl: ""
            })
        });
        console.log("Complete response1:", completeResponse);
        if (!completeResponse.ok) {
            handleClose();
            console.error("Failed to complete upload");
            return;
        }
        const responseData = await completeResponse.json();
        console.log("Complete response:", responseData);
        setUploadProgress(0);
        return { url: 'https://httpbin.org/post' };
    };
    const handleChangeStatus = ({ meta, file }, status) => {
        console.log('File status:', status, 'Meta:', meta, 'File:', file);
        if (status === 'done') {
            setUpLoading(false);
            setShowProgressBar(false);
            if (dropzoneRef.current) {
                if (typeof dropzoneRef.current.removeAllFiles === 'function') {
                    dropzoneRef.current.removeAllFiles();
                } else {
                    removeFile(file);
                }
            }
            handleClose();
            setTimeout(() => {
                const progressBar = document.querySelector('.dz-progress');
                if (progressBar) {
                    progressBar.style.display = 'none';
                }
            }, 100);
        }
        if (status === 'uploading') {
            handleClose();
            setUpLoading(false);
            setShowProgressBar(false);
        }
    };
    const removeFile = (file) => {
        const fileElement = document.querySelector(`[data-dz-file="${file.name}"]`);
        if (fileElement) {
            fileElement.remove();
        }
    };

    useEffect(() => {
        const fetchUsersFolder = async () => {
            setLoading(true);

            try {
                if (user == null) {
                    return console.error('user is empty');
                }

                const result = await getUsersFolder(user.email);
                setTreeData2(buildTree2(result));
                setUsersFolder(result);
            }
            catch (error) {
                console.error("Error fetching folders:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchUsersFolder();
    }, [user,isOpen]);
    useEffect(() => {
        const transformedData = buildTree2(usersFolder);
        setTreeData2(transformedData);
    }, [usersFolder, editingFolderId2])


    // -- New Treeview Folder
    function buildTree2(data) {
        const idToNodeMap = {};
        const resultTree = [];

        // First, map all items (folders) into a dictionary
        data.forEach((item) => {
            idToNodeMap[item.id] = {
                id: item.id,
                name: item.name,
                isParent: item.isParent,
                children: [],
            };

            // If this item has files, add them to children
            if (Array.isArray(item.files) && item.files.length > 0) {
                item.files.forEach((file) => {
                    idToNodeMap[item.id].children.push({
                        id: file.id,
                        name: file.name || `File ${file.id}`,
                        isParent: false,
                    });
                });
            }
        });

        // Second, assign folders to their parents
        data.forEach((item) => {
            if (item.parentId && idToNodeMap[item.parentId]) {
                idToNodeMap[item.parentId].children.push(idToNodeMap[item.id]);
            } else {
                resultTree.push(idToNodeMap[item.id]);
            }
        });

        return resultTree;
    }

    const addFolder2 = async (parentId) => {
        setAddingFolderId(parentId);
        setNewFolderName("");
    };
    const handleSaveFolder = async (parentId) => {
        if (!newFolderName.trim()) return;

        const newFolder = {
            id: 0,
            name: newFolderName,
            isParent: true,
            parentId: parentId,
            userId: 0,
            email: user.email,
            fileId: 0
        };

        try {
            await addParentFolder(newFolder);
            const updatedFolders = await getUsersFolder(user.email); // Fetch latest data
            setTreeData2(buildTree2(updatedFolders));
            setAddingFolderId(null);
        } catch (error) {
            console.error("Error adding folder:", error);
        }
    };
    // const handleDeleteFolder2 = async (folderId) => {
    //     try {
    //         //await deleteFolder(folderId);
    //         console.log("Folder deleted:", folderId);
    //         const result = await getUsersFolder(user.email);
    //         setTreeData2(buildTree2(result));
    //     } catch (error) {
    //         console.error("Error deleting folder:", error);
    //     }
    // };

    const renderTree2 = (nodes) =>
        nodes.map((node) => (
            <TreeItem
                key={node.id}
                nodeId={node.id.toString()}
                label={
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        {/* Expand/Collapse Arrow - Default Click Behavior */}
                        <span
                            onClick={(e) => e.stopPropagation()} // Prevents propagation to prevent unwanted expand/collapse
                            style={{ cursor: "pointer" }}
                        >
                            {node.isParent ? (
                                expanded.includes(node.id) ? (
                                    <Folder style={{ color: "#6c757d" }} />
                                ) : (
                                    <FolderOpenIcon style={{ color: "#6c757d" }} />
                                )
                            ) : (
                                <LibraryMusic
                                    style={{
                                        color: node.name.endsWith(".mp3")
                                            ? "purple"
                                            : node.name.endsWith(".mp4")
                                                ? "blue"
                                                : node.name.endsWith(".wav")
                                                    ? "indigo"
                                                    : "teal",
                                    }}
                                />
                            )}
                        </span>

                        {/* Folder Name - Prevent Expand/Collapse & Capture Click */}
                        <span
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents the default expand/collapse behavior
                                if (node.isParent) {
                                    console.log("Clicked folder ID:", node.id);
                                    setSelectedFolderId(node.id);// Capture Folder ID only
                                }// Capture Folder ID on Click
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault(); // Prevent default right-click menu
                                setEditingFolderId2(node.id);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {editingFolderId2 === node.id ? (
                                <TextField
                                    defaultValue={node.name}
                                    size="small"
                                    onBlur={(e) => handleRenameFolder2(e, node.id)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") handleRenameFolder2(e, node.id);
                                    }}
                                    autoFocus
                                    onClick={(e) => e.stopPropagation()}
                                />
                            ) : (
                                node.name
                            )}
                        </span>

                        {node.isParent && (
                            <>
                                {addingFolderId === node.id ? (
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={newFolderName}
                                        onChange={(e) => setNewFolderName(e.target.value)}
                                        onBlur={() => handleSaveFolder(node.id)}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") handleSaveFolder(node.id);
                                        }}
                                        autoFocus
                                    />
                                ) : (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="green"
                                        style={{ marginLeft: "auto", fontSize: "10px", padding: "2px 4px" }}
                                        onClick={() => addFolder2(node.id)}
                                    >
                                        ➕
                                    </Button>
                                )}
                                {/* <IconButton size="small" color="warning" onClick={() => handleDeleteFolder2(node.id)}>
                                    <Delete />
                                </IconButton> */}
                            </>
                        )}
                    </div>
                }
            >
                {Array.isArray(node.children) && node.children.length > 0 && renderTree2(node.children)}
            </TreeItem>
        ));

    const handleRenameFolder2 = async (e, folderId) => {
        const newName = e.target.value.trim();
        if (!newName) {
            setEditingFolderId2(null);
            return;
        }

        try {
            const updatedFolder = await updateFolderName(folderId, newName);

            if (updatedFolder) {
                setTreeData2(prevData => updateFolderNameRecursively(prevData, folderId, newName));
            }
        } catch (error) {
            console.error("Error renaming folder:", error);
        }

        setEditingFolderId2(null);
    };
    const updateFolderNameRecursively = (folders, folderId, newName) => {
        return folders.map(folder => {
            if (folder.id === folderId) {
                return { ...folder, name: newName };
            }
            if (folder.children && folder.children.length > 0) {
                return { ...folder, children: updateFolderNameRecursively(folder.children, folderId, newName) };
            }
            return folder;
        });
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <div className="modalContainer">
                    <div className="modalContentUpload" style={{ width: "60%", background: "#f5f2f2", borderRadius: "20px", maxWidth: "800px", left: "56%", top: "45vh" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <div>
                                <span className='upload-modal-header'>Upload your audio file</span>
                            </div>
                            <div>
                                <button onClick={handleClose} className="modalBtnClose" style={{ fontSize: "30px", color: "rgb(57 56 56)" }}>
                                    &times;
                                </button>
                            </div>
                        </div>


                        <div style={{ margin: "20px" }}>
                            <div className='select-model-upload'>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="modelSelectLabel">Select Custom Model</InputLabel>
                                    <Select
                                        labelId="modelSelectLabel"
                                        id="modelSelect"
                                        value={selectedModel}
                                        onChange={(e) => setSelectedModel(e.target.value)}
                                    >
                                        {loading ? (
                                            <MenuItem disabled>
                                                <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                                Loading models...
                                            </MenuItem>
                                        ) : (
                                            models.map((model) => (
                                                <MenuItem key={model.self} value={model.self}>
                                                    {model.displayName}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                            {/* <TextField
                                size="small"
                                variant="outlined"
                                value={parentFolderValue2}
                                onChange={(event) => setParentFolderValue2(event.target.value)}
                                label="Folder Name"
                            /> */}
                            {/* <div style={{ display: "inline-flex", marginTop: "5px" }}>
                                <Tooltip title="Clear" arrow placement="bottom">
                                    <IconButton size="small" onClick={() => setParentFolderValue2("")}> <ClearRounded /> </IconButton>
                                </Tooltip>
                                <Tooltip title="Save new folder" arrow placement="bottom">
                                    <IconButton size="small" color="primary" onClick={handleAddParentFolder2}> <SaveRounded /> </IconButton>
                                </Tooltip>
                            </div> */}
                            <div>
                                <span className='text-wrapper-upload-modal'>Choose or add a Folder</span>
                            </div>
                            <div className="folderTreeView">
                                {loading ? "Loading..." : (
                                    <TreeView
                                        expanded={expanded}
                                        onNodeToggle={handleToggle}
                                        defaultCollapseIcon={<ArrowDropDown />}
                                        defaultExpandIcon={<ArrowRight />}
                                    >
                                        {renderTree2(treeData2)}
                                    </TreeView>
                                )}
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>Note:</span> <span style={{ fontSize: '15px', padding: '5px' }}>Selecting a folder is optional, but if you want your file uploaded to a specific folder, please select it first.</span>
                            </div>
                        </div>
                        <div style={{ margin: "20px", marginLeft: "20px", marginRight: "20px" }}>
                            <Dropzone
                                maxFiles={10}
                                getUploadParams={uploadFile}
                                onChangeStatus={handleChangeStatus}
                                accept="audio/*"
                                inputContent="Drag Audio Files or Click to Upload"
                                submitButtonContent="Upload"
                                styles={{
                                    dropzone: { minHeight: 200, maxHeight: 250, borderColor: '#00acc1 !important', borderStyle: 'dashed' },
                                    dropzoneActive: { borderColor: 'green' },
                                    progressBar: { display: showProgressBar },
                                }}
                                inputWithFilesContent="Add More Files"
                                previewStyle="inline"
                                autoUpload
                                ref={dropzoneRef}
                            />
                        </div>
                        <div>{isUploading && <LoadingIndicator />}</div>
                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <div style={{ marginTop: '10px' }}>
                                <progress value={uploadProgress} max={100} style={{ width: '100%' }}>
                                    {uploadProgress}%
                                </progress>
                                <span>{uploadProgress}%</span>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>

        </div>
    );
};
// Define prop types
UploadFile.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedFile: PropTypes.object,
};
export default UploadFile;