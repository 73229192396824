import React from 'react';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import Dropzone from 'react-dropzone-uploader';
import { useState, useEffect, useRef } from 'react';
import { getModelTranscriptionResource1 } from "services/api";
import { useAuth } from '../../services/authContext';
const CHUNK_SIZE = 1024 * 1024; 

const UploadFile = ({ isOpen, handleClose }) => {
    const [selectedModel, setSelectedModel] = useState('');
    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(true);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setUpLoading] = useState(false);
    const dropzoneRef = useRef(null);
    const { user } = useAuth();


    const LoadingIndicator = () => (
        <div className="uploading-indicator">
          {isUploading && <p style={{ fontWeight: 'bolder', fontSize: '20px' }}>Uploading<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>}
        </div>
      );
    const fetchModels = async () => {
        try {
            setLoading(true);
            const modelsData = await getModelTranscriptionResource1();
            const sortedModels = modelsData.sort((a, b) =>
                b.displayName.localeCompare(a.displayName)
            );

            setModels(sortedModels);
            console.log("Fetched models:", sortedModels);
            setSelectedModel(sortedModels[2]?.self || ''); 
            console.log("Selected model:", selectedModel);
        } catch (error) {
            console.error("Error fetching models:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchModels();
    }, []);

    const uploadFile = async ({ file }) => {
        setUpLoading(true);
        const fileSize = file.size;
        const fileName = file.name;
        const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
        let completedChunks = 0;

        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, fileSize);
            const chunk = file.slice(start, end);

            const formData = new FormData();
            formData.append("chunk", chunk);
            formData.append("fileName", fileName);
            formData.append("fileSize", fileSize);
            formData.append("chunkIndex", chunkIndex);
            formData.append("totalChunks", totalChunks);

            const response = await fetch(process.env.REACT_APP_API_URL+"/api/Upload/UploadChunk", {
                method: "POST",
                body: formData,
            });

            if (!response.ok) {
                console.error("Failed to upload chunk:", chunkIndex);
                return;
            }
            completedChunks++;
            const progressPercentage = Math.floor((completedChunks / totalChunks) * 100);
            setUploadProgress(progressPercentage);
        }
        const completeResponse = await fetch(process.env.REACT_APP_API_URL+"/api/Upload/CompleteUpload", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                FileName: fileName,
                FileSize: fileSize,
                AudioTitleName: "My Audio Title",
                UserEmail: user.email,
                UserName: user.name,
                CustomModel: selectedModel,
            }),
        });
        console.log("Complete response1:", completeResponse);
        if (!completeResponse.ok) {
            handleClose();
            console.error("Failed to complete upload");
            return;
        }
        const responseData = await completeResponse.json();
        console.log("Complete response:", responseData);
        setUploadProgress(0);
        return { url: 'https://httpbin.org/post' };
    };
    const handleChangeStatus = ({ meta, file }, status) => {
        console.log('File status:', status, 'Meta:', meta, 'File:', file);
        if (status === 'done') {
            setUpLoading(false);
            setShowProgressBar(false);
            if (dropzoneRef.current) {
                if (typeof dropzoneRef.current.removeAllFiles === 'function') {
                    dropzoneRef.current.removeAllFiles();
                } else {
                    removeFile(file);
                }
            }
            handleClose();
            setTimeout(() => {
                const progressBar = document.querySelector('.dz-progress');
                if (progressBar) {
                    progressBar.style.display = 'none';
                }
            }, 100);
        }
        else if (status === 'uploading') {
            handleClose();
            setUpLoading(false);
            setShowProgressBar(false);
            setShowProgressBar(false);
        }
    };
    const removeFile = (file) => {
        const fileElement = document.querySelector(`[data-dz-file="${file.name}"]`);
        if (fileElement) {
          fileElement.remove();
        }
      };
    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div>
                    <div className="modalContentUpload">
                        <div>
                            <button
                                onClick={handleClose}
                                className="modalBtnClose"
                            >
                                &times;
                            </button>
                        </div>
                        {/* Dropdown for models using Material-UI */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ marginBottom: '10px', width: '50%' }}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="modelSelectLabel">Select Custom Model</InputLabel>
                                    <Select
                                        labelId="modelSelectLabel"
                                        id="modelSelect"
                                        value={selectedModel}
                                        onChange={(e) => setSelectedModel(e.target.value)}
                                    >
                                        {loading ? (
                                            <MenuItem disabled>
                                                <CircularProgress size={24} style={{ marginRight: '10px' }} />
                                                Loading models...
                                            </MenuItem>
                                        ) : (
                                            models.map((model) => (
                                                <MenuItem key={model.self} value={model.self}>
                                                    {model.displayName}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <Dropzone
                            maxFiles={10}
                            getUploadParams={uploadFile}
                            onChangeStatus={handleChangeStatus}
                            accept="audio/*"
                            inputContent="Drag Audio Files or Click to Upload"
                            submitButtonContent="Upload"
                            styles={{
                                dropzone: { minHeight: 200, maxHeight: 250 },
                                dropzoneActive: { borderColor: 'green' },
                                progressBar: { display: showProgressBar }
                            }}
                            inputWithFilesContent="Add More Files"
                            previewStyle="inline"
                            autoUpload
                            ref={dropzoneRef}
                        />
                        <div>
                            {isUploading && <LoadingIndicator />}
                        </div>
                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <div style={{ marginTop: '10px' }}>
                                <progress value={uploadProgress} max={100} style={{ width: '100%' }}>
                                    {uploadProgress}%
                                </progress>
                                <span>{uploadProgress}%</span>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};
// Define prop types
UploadFile.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};
export default UploadFile;