import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom"; 
import { jwtDecode } from 'jwt-decode'; 

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

const useTokenExpirationCheck = () => {
  const history = useHistory();
  const [showExpiredModal, setShowExpiredModal] = useState(false); 

  const handleGlobalClick = () => {
    const token = getCookie('jwtToken');

    if (!token) {
      setShowExpiredModal(true); 
      console.log("No token found. Skipping redirection.");
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; 
      if (decodedToken.exp < currentTime) {
        document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
        setShowExpiredModal(true); 
      }
    } catch (error) {
      document.cookie = 'jwtToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/'; 
      setShowExpiredModal(true); 
    }
  };

  const handleModalCloseTokenExpired = () => {
    localStorage.removeItem('isFirstLoginShown');

    localStorage.removeItem('isMSAccountLoggedIn');
    localStorage.removeItem('msToken');

    setShowExpiredModal(false);
    setTimeout(() => {
      history.push("/login"); 
    }, 100); 

    console.log("Redirecting to login after session expired.");
  };

  useEffect(() => {
    //const expiredToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHBpcmVkX3N0YW5kYXJkX2RhdGUiOiJleHBpcmVkX3N0YW5kYXJkIiwiZXhwIjoxLjY3ODc0cXV5b1hlbGVuNzEvR3lZa+q7wqIr8de6fAqH4"; // Simulated expired token
    //document.cookie = `jwtToken=${expiredToken}; path=/; SameSite=None; Secure;`;
    document.addEventListener('click', handleGlobalClick);
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, []); 

  return { showExpiredModal, handleModalCloseTokenExpired };
};

export default useTokenExpirationCheck;