import React from 'react';
import { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import { TreeView, TreeItem } from '@material-ui/lab'
import { TextField, IconButton, Tooltip } from "@material-ui/core";
import { useAuth } from '../../services/authContext';
//import { Delete } from '@material-ui/icons';
import { ArrowRight, ArrowDropDown,Check } from '@material-ui/icons';
import { Folder, LibraryMusic } from '@material-ui/icons';
import { Delete } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { getUsersFolder, addParentFolder, updateFolderName, deleteFolder } from '../../services/api';
import { SaveRounded } from '@material-ui/icons';
import CustomFolderOpenIcon from "assets/img/FolderOpenIcon.png";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Avatar from '@material-ui/core/Avatar';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'

const FolderOpenIcon = () => (
    <img src={CustomFolderOpenIcon} alt="Open Folder" style={{ width: 20, height: 20 }} />
);

const FolderTreeView = ({ isOpenFolderTreeview, handleCloseOpenFolderTreeview }) => {
    const { user } = useAuth();
    const [treeData2, setTreeData2] = useState([]);
    const [parentFolderValue2, setParentFolderValue2] = useState("");
    const [editingFolderId2, setEditingFolderId2] = useState(null);
    const [newFolderName, setNewFolderName] = useState("");
    const [addingFolderId, setAddingFolderId] = useState(null);
    const [usersFolder, setUsersFolder] = useState([]);
    const [loading, setLoading] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const [openSuccessDeleteDialog, setOpenSuccessDeleteDialog] = useState(false);
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    useEffect(() => {
        const fetchUsersFolder = async () => {
            console.log(isOpenFolderTreeview);
            if (isOpenFolderTreeview) return;
            setLoading(true);

            try {
                if (user == null) {
                    return console.error('user is empty');
                }

                const result = await getUsersFolder(user.email);
                setTreeData2(buildTree2(result));
                setUsersFolder(result);
            }
            catch (error) {
                console.error("Error fetching folders:", error);
            }
            finally {
                setLoading(false);
            }
        };

        fetchUsersFolder();
    }, [user, isOpenFolderTreeview]);
    useEffect(() => {
        const transformedData = buildTree2(usersFolder);
        setTreeData2(transformedData);
    }, [usersFolder, editingFolderId2])
    useEffect(() => {
        console.log("Updated usersFolder:", usersFolder);
    }, [treeData2, usersFolder]);
    // -- New Treeview Folder
    function buildTree2(data) {
        const idToNodeMap = {};
        const resultTree = [];

        // First, map all items (folders) into a dictionary
        data.forEach((item) => {
            idToNodeMap[item.id] = {
                id: item.id,
                name: item.name,
                isParent: item.isParent,
                children: [],
            };

            // If this item has files, add them to children
            if (Array.isArray(item.files) && item.files.length > 0) {
                item.files.forEach((file) => {
                    idToNodeMap[item.id].children.push({
                        id: file.id,
                        name: file.name || `File ${file.id}`,
                        isParent: false,
                    });
                });
            }
        });

        // Second, assign folders to their parents
        data.forEach((item) => {
            if (item.parentId && idToNodeMap[item.parentId]) {
                idToNodeMap[item.parentId].children.push(idToNodeMap[item.id]);
            } else {
                resultTree.push(idToNodeMap[item.id]);
            }
        });

        return resultTree;
    }

    const addFolder2 = async (parentId) => {
        setAddingFolderId(parentId);
        setNewFolderName("");
    };
    const handleSaveFolder = async (parentId) => {
        if (!newFolderName.trim()) return;

        const newFolder = {
            id: 0,
            name: newFolderName,
            isParent: true,
            parentId: parentId,
            userId: 0,
            email: user.email,
            fileId: 0
        };

        try {
            await addParentFolder(newFolder);
            const updatedFolders = await getUsersFolder(user.email); // Fetch latest data
            setTreeData2(buildTree2(updatedFolders));
            setAddingFolderId(null);
        } catch (error) {
            console.error("Error adding folder:", error);
        }
    };

    const handleDeleteFolder2 = async (folderId) => {
        try {
            console.log("Deleting folder ID:", folderId);
            await deleteFolder(folderId);

            // Fetch updated folders
            const updatedFolders = await getUsersFolder(user.email);

            console.log("Updated folders:", updatedFolders); 
            setUsersFolder(updatedFolders);
            setTreeData2(buildTree2(updatedFolders));
            setOpenSuccessDeleteDialog(true);
        } catch (error) {
            console.error("Error deleting folder:", error);
        }
    };

    const renderTree2 = (nodes) =>
        nodes.map((node) => (
            <TreeItem
                key={node.id}
                nodeId={node.id.toString()}
                label={
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        {/* Expand/Collapse Arrow - Default Click Behavior */}
                        <span
                            onClick={(e) => e.stopPropagation()} // Prevents propagation to prevent unwanted expand/collapse
                            style={{ cursor: "pointer" }}
                        >
                            {node.isParent ? (
                                expanded.includes(node.id) ? (
                                    <Folder style={{ color: "#6c757d" }} />
                                ) : (
                                    <FolderOpenIcon style={{ color: "#6c757d" }} />
                                )
                            ) : (
                                <LibraryMusic
                                    style={{
                                        color: node.name.endsWith(".mp3")
                                            ? "purple"
                                            : node.name.endsWith(".mp4")
                                                ? "blue"
                                                : node.name.endsWith(".wav")
                                                    ? "indigo"
                                                    : "teal",
                                    }}
                                />
                            )}
                        </span>

                        {/* Folder Name - Prevent Expand/Collapse & Capture Click */}
                        <span
                            onClick={(e) => {
                                e.stopPropagation(); // Prevents the default expand/collapse behavior
                                if (node.isParent) {
                                    console.log("Clicked folder ID:", node.id);
                                    //setSelectedFolderId(node.id);// Capture Folder ID only
                                }// Capture Folder ID on Click
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault(); // Prevent default right-click menu
                                setEditingFolderId2(node.id);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {editingFolderId2 === node.id ? (
                                <TextField
                                    defaultValue={node.name}
                                    size="small"
                                    onBlur={(e) => handleRenameFolder2(e, node.id)}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") handleRenameFolder2(e, node.id);
                                    }}
                                    autoFocus
                                    onClick={(e) => e.stopPropagation()}
                                />
                            ) : (
                                node.name
                            )}
                        </span>

                        {node.isParent && (
                            <>
                                {addingFolderId === node.id ? (
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={newFolderName}
                                        onChange={(e) => setNewFolderName(e.target.value)}
                                        onBlur={() => handleSaveFolder(node.id)}
                                        onKeyPress={(e) => {
                                            if (e.key === "Enter") handleSaveFolder(node.id);
                                        }}
                                        autoFocus
                                    />
                                ) : (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        color="green"
                                        style={{ marginLeft: "auto", fontSize: "10px", padding: "2px 4px" }}
                                        onClick={() => addFolder2(node.id)}
                                    >
                                        ➕
                                    </Button>
                                )}
                                <IconButton size="small" color="warning" onClick={() => handleDeleteFolder2(node.id)}>
                                    <Delete />
                                </IconButton>
                            </>
                        )}
                    </div>
                }
            >
                {Array.isArray(node.children) && node.children.length > 0 && renderTree2(node.children)}
            </TreeItem>
        ));

    const handleRenameFolder2 = async (e, folderId) => {
        const newName = e.target.value.trim();
        if (!newName) {
            setEditingFolderId2(null);
            return;
        }

        try {
            const updatedFolder = await updateFolderName(folderId, newName);

            if (updatedFolder) {
                setTreeData2(prevData => updateFolderNameRecursively(prevData, folderId, newName));
            }
        } catch (error) {
            console.error("Error renaming folder:", error);
        }

        setEditingFolderId2(null);
    };
    const updateFolderNameRecursively = (folders, folderId, newName) => {
        return folders.map(folder => {
            if (folder.id === folderId) {
                return { ...folder, name: newName };
            }
            if (folder.children && folder.children.length > 0) {
                return { ...folder, children: updateFolderNameRecursively(folder.children, folderId, newName) };
            }
            return folder;
        });
    };

    const handleAddParentFolder2 = async () => {
        const payload = {
            id: 0,
            name: parentFolderValue2,
            isParent: true,
            parentId: null,
            userId: 0,
            email: user.email,
            fileId: 0
        };

        const response = await addParentFolder(payload);
        setTreeData2(buildTree2(response));
        //setParentFolderValue2("");
    };

    return (
        <div>
            <Modal
                open={isOpenFolderTreeview}
                onClose={handleCloseOpenFolderTreeview}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                BackdropProps={{
                    onClick: (e) => e.stopPropagation(),
                }}
            >
                <div className="modalContainer">
                    <div className="modalContentUpload" style={{ width: "60%", background: "#FFFF", borderRadius: "20px", maxWidth: "600px", left: "56%", top: "45vh" }}>

                        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                            <div>
                                <span className='upload-modal-header'>Folders</span>
                            </div>
                            <div>
                                <button onClick={handleCloseOpenFolderTreeview} className="modalBtnClose" style={{ fontSize: "30px", color: "rgb(57 56 56)" }}>
                                    &times;
                                </button>
                            </div>
                        </div>
                        <div style={{ margin: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "45px" }}>
                                <div className='createfolderInput'>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        value={parentFolderValue2}
                                        onChange={(event) => setParentFolderValue2(event.target.value)}
                                        label="Enter Folder Name"
                                        style={{ width: "400px", background: "#efeded", borderRadius: "10px" }}
                                    />
                                </div>

                                <div style={{ display: "inline-flex" }}>
                                    {/* <Tooltip title="Clear" arrow placement="bottom">
                                        <IconButton size="small" onClick={() => setParentFolderValue2("")}> <ClearRounded /> </IconButton>
                                    </Tooltip> */}
                                    <Tooltip title="Save new folder" arrow placement="bottom">
                                        <IconButton size="small" color="primary" onClick={handleAddParentFolder2}></IconButton>
                                    </Tooltip>
                                    <Button
                                        className='createfolderBtn'
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<SaveRounded />}
                                        onClick={handleAddParentFolder2}
                                    >
                                        Create Folder
                                    </Button>
                                </div>
                            </div>

                            <div className="folderTreeView">
                                {loading ? "Loading..." : (
                                    <TreeView
                                        expanded={expanded}
                                        onNodeToggle={handleToggle}
                                        defaultCollapseIcon={<ArrowDropDown />}
                                        defaultExpandIcon={<ArrowRight />}
                                    >
                                        {renderTree2(treeData2)}
                                    </TreeView>
                                )}
                            </div>
                            <div style={{ marginLeft: '15px' }}>
                                <b>Note:</b>
                                <span style={{ marginLeft: '5px', fontSize: '14px', color: '#f44336' }}>
                                    To rename the folder, right-click on the folder name and enter the new name.
                                </span>
                                <span style={{ marginLeft: '5px', fontSize: '14px', color: '#f44336' }}>
                                    To browse the folder, click on the arrow.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Dialog
                open={openSuccessDeleteDialog}
                onClose={() => setOpenSuccessDeleteDialog(false)}>

                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{ display: 'inline-flex' }}>
                            <Avatar style={{ backgroundColor: '#8bc34a' }}>
                                <Check />
                            </Avatar>
                            <span style={{ marginTop: '10px', marginLeft: '10px' }}>Folder deleted successfully!</span>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSuccessDeleteDialog(false)} autoFocus>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
FolderTreeView.propTypes = {
    isOpenFolderTreeview: PropTypes.bool,
    handleCloseOpenFolderTreeview: PropTypes.func,
};
export default FolderTreeView;
