import React from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { useState, useEffect } from 'react';
import { useAuth } from '../../services/authContext';
import { Button } from '@material-ui/core';
import { SaveRounded } from "@material-ui/icons";
import { getUsersFolder, updateFileFolder } from '../../services/api';
import 'rc-tree/assets/index.css';
import { TreeView, TreeItem } from '@material-ui/lab'
//import { Delete } from '@material-ui/icons';
import { ArrowRight, ArrowDropDown } from '@material-ui/icons';
import { Folder, LibraryMusic } from '@material-ui/icons';
import CustomFolderOpenIcon from "assets/img/FolderOpenIcon.png";
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';
import 'rc-tree/assets/index.css';
const FolderOpenIcon = () => (
  <img src={CustomFolderOpenIcon} alt="Open Folder" style={{ width: 20, height: 20 }} />
);
const MoveFile = ({ isOpenMoveFile, handleCloseMoveFile, selectedFile }) => {
  const { user } = useAuth();
  const [usersFolder, setUsersFolder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [editingFolderId2, setEditingFolderId2] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [treeData2, setTreeData2] = useState([]);
  const [parentFolderValue, setParentFolderValue] = useState('');
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  useEffect(() => {
    const fetchUsersFolder = async () => {
      setLoading(true);

      try {
        if (user == null) {
          return console.error('user is empty');
        }

        const result = await getUsersFolder(user.email);
        console.log(result);
        setTreeData2(buildTree2(result));
        setUsersFolder(result);
      }
      catch (error) {
        console.error("Error fetching folders:", error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchUsersFolder();
  }, [user]);
  useEffect(() => {
    const transformedData = buildTree2(usersFolder);
    setTreeData2(transformedData);
  }, [usersFolder, editingFolderId2])


  function buildTree2(data) {
    console.log(data);
    const idToNodeMap = {};
    const resultTree = [];

    // First, map all items (folders) into a dictionary
    data.forEach((item) => {
      idToNodeMap[item.id] = {
        id: item.id,
        name: item.name,
        isParent: item.isParent,
        children: [],
      };

      // If this item has files, add them to children
      if (Array.isArray(item.files) && item.files.length > 0) {
        item.files.forEach((file) => {
          idToNodeMap[item.id].children.push({
            id: file.id,
            name: file.name || `File ${file.id}`,
            isParent: false,
          });
        });
      }
    });

    // Second, assign folders to their parents
    data.forEach((item) => {
      if (item.parentId && idToNodeMap[item.parentId]) {
        idToNodeMap[item.parentId].children.push(idToNodeMap[item.id]);
      } else {
        resultTree.push(idToNodeMap[item.id]);
      }
    });

    return resultTree;
  }

  const handleUpdateFileFolder = async () => {
    if (selectedFolderId == '') {
      console.log('No selected folder');
      return;
    }

    const payload =
    {
      id: selectedFolderId,
      name: parentFolderValue,
      isParent: false,
      parentId: null,
      userId: 0,
      email: user.email,
      fileId: selectedFile.id
    };

    var response = await updateFileFolder(payload);

    setUsersFolder(response);

    const updatedTreeData = buildTree2(response);
    setTreeData2(updatedTreeData);
  }
  const renderTree2 = (nodes) =>
    nodes.map((node) => (
      <TreeItem
        key={node.id}
        nodeId={node.id.toString()}
        label={
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {/* Expand/Collapse Arrow - Default Click Behavior */}
            <span
              onClick={(e) => e.stopPropagation()} // Prevents propagation to prevent unwanted expand/collapse
              style={{ cursor: "pointer" }}
            >
              {node.isParent ? (
                expanded.includes(node.id) ? (
                  <Folder style={{ color: "#6c757d" }} />
                ) : (
                  <FolderOpenIcon style={{ color: "#6c757d" }} />
                )
              ) : (
                <LibraryMusic
                  style={{
                    color: node.name.endsWith(".mp3")
                      ? "purple"
                      : node.name.endsWith(".mp4")
                        ? "blue"
                        : node.name.endsWith(".wav")
                          ? "indigo"
                          : "teal",
                  }}
                />
              )}
            </span>

            {/* Folder Name - Prevent Expand/Collapse & Capture Click */}
            <span
              onClick={(e) => {
                e.stopPropagation(); // Prevents the default expand/collapse behavior
                if (node.isParent) {
                  console.log("Clicked folder ID:", node.id);
                  setSelectedFolderId(node.id);// Capture Folder ID only
                  setParentFolderValue(node.name);
                }// Capture Folder ID on Click
              }}
              onContextMenu={(e) => {
                e.preventDefault(); // Prevent default right-click menu
                setEditingFolderId2(node.id);
              }}
              style={{ cursor: "pointer" }}
            >
              {node.name}
            </span>
          </div>
        }
      >
        {Array.isArray(node.children) && node.children.length > 0 && renderTree2(node.children)}
      </TreeItem>
    ));



  return (
    <Modal
      open={isOpenMoveFile}
      onClose={handleCloseMoveFile}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropProps={{
        onClick: (e) => e.stopPropagation(),
      }}
    >

      <div className="modalContainer">
        <div className="modalContentUpload" style={{ width: "60%", background: "#FFFF", borderRadius: "20px", maxWidth: "600px", left: "56%", top: "45vh" }}>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <div>
              <span className='upload-modal-header'>Move your files</span>
            </div>
            <div>
              <button onClick={handleCloseMoveFile} className="modalBtnClose" style={{ fontSize: "30px", color: "rgb(57 56 56)" }}>
                &times;
              </button>
            </div>
          </div>
          <div style={{ margin: "40px" }}>

            <div className="folderTreeView">
              {loading ? "Loading..." : (
                <TreeView
                  expanded={expanded}
                  onNodeToggle={handleToggle}
                  defaultCollapseIcon={<ArrowDropDown />}
                  defaultExpandIcon={<ArrowRight />}
                >
                  {renderTree2(treeData2)}
                </TreeView>
              )}
            </div>
          </div>
          <div style={{ float: 'right', marginTop: '20px' }}>
            {treeData2.length == 0 ? '' :
             <Button
             className='createfolderBtn'
             variant="contained"
             color="primary"
             size="small"
             startIcon={<SaveRounded />}
             onClick={handleUpdateFileFolder}
           >
            Save
           </Button>
            }
          
          </div>
        </div>
      </div>
    </Modal>
  );
}

MoveFile.propTypes = {
  isOpenMoveFile: PropTypes.bool.isRequired,
  handleCloseMoveFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.object.isRequired
};

export default MoveFile;