export const  parseTranscription = (rawDetails, transcriptionWords) => {
    const segments = [];

    if (rawDetails == '') return segments;

    rawDetails = rawDetails.replace(/\n+/g, '\n').trim();

    const timeConverter = (seconds) => {
        if (seconds > 60) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}m ${remainingSeconds}s`;
        }
        return `${seconds}s`;
    };

    //const speakerRegex = /([\w\s]+):\s(.*?)(?:<ts#0x1>(.*?)<\/ts#0x1>)/gs;
    const speakerRegex = /([\w\s'’]+):\s(.*?)(?:<ts#0x1>(.*?)<\/ts#0x1>)/gs;
    let combinedMatch;

    while((combinedMatch = speakerRegex.exec(rawDetails)) !== null)
    {
      const speakerOnly = combinedMatch[1].trim(); // Get speaker name
      const speakerText = combinedMatch[2].trim(); // Get speaker's spoken text
      const segmentDetails = combinedMatch[3] ? combinedMatch[3].trim() : ""; // Segment details if available

      let startTime;
      let segmentMatch;
      const segmentRegex = /([^,]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s]/g;

      // Create a temporary object to store the speaker and their words
      const speakerSegments = {
        speakerOnly,
        speakerText,
        startTime,
        words: [] // Store all words for this speaker
      };

      while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
        const word = segmentMatch[1].trim(); // Extract the word
        const wordStartTime = parseFloat(segmentMatch[2]);
        const wordEndTime = parseFloat(segmentMatch[4]);

        const combinedTimestamp = '[' + wordStartTime + ' - ' + wordEndTime + ']';
        const foundWord = transcriptionWords.filter(item => item.customTimestamp == combinedTimestamp);
        const hasEditedWord = foundWord.length > 0;

        speakerSegments.words.push({
          word,
          startTime: wordStartTime,
          endTime: wordEndTime,
          isEdited: hasEditedWord,
          originalWord: hasEditedWord ? foundWord[0].originalWord : '',
          transcriptionWordId: hasEditedWord ? foundWord[0].id : 0
        });
      }

      // Push the speaker and their words as one entry
      speakerSegments.startTime = timeConverter(speakerSegments.words[0].startTime);
      segments.push(speakerSegments);
    }
 
    // Regex to capture speaker's label, text, and segment details (optional)
    // const speakerRegex = /([^:]+):\s*([^([]+)(?:\(([^)]+)\))?/g;
    // const speakerRegex = /([^:]+):\s*([^(]+)(?:\(([^)]+)\))?/g;
    // let combinedMatch;
    // while ((combinedMatch = speakerRegex.exec(rawDetails)) !== null) {
    //   //console.log('Combined Match:', combinedMatch);
    //   const speakerOnly = combinedMatch[1].trim(); // Get speaker name
    //   const speakerText = combinedMatch[2].trim(); // Get speaker's spoken text
    //   const segmentDetails = combinedMatch[3] ? combinedMatch[3].trim() : ""; // Segment details if available

    //   // eslint-disable-next-line no-useless-escape
    //   // const segmentRegex = /([^,\[\]()]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s\]/g;
    //   const segmentRegex = /(\[?[^[\],()]+\]?)\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s\]/g;

    //   let startTime;
    //   let segmentMatch;

    //   // Create a temporary object to store the speaker and their words
    //   const speakerSegments = {
    //     speakerOnly,
    //     speakerText,
    //     startTime,
    //     words: [] // Store all words for this speaker
    //   };

    //   while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
    //     const word = segmentMatch[1].trim(); // Extract the word
    //     const wordStartTime = parseFloat(segmentMatch[2]);
    //     const wordEndTime = parseFloat(segmentMatch[4]);

    //     const combinedTimestamp = '[' + wordStartTime + ' - ' + wordEndTime + ']';
    //     const foundWord = transcriptionWords.filter(item => item.customTimestamp == combinedTimestamp);
    //     const hasEditedWord = foundWord.length > 0;

    //     speakerSegments.words.push({
    //       word,
    //       startTime: wordStartTime,
    //       endTime: wordEndTime,
    //       isEdited: hasEditedWord,
    //       originalWord: hasEditedWord ? foundWord[0].originalWord : '',
    //       transcriptionWordId: hasEditedWord ? foundWord[0].id : 0
    //     });
    //   }
    //   // Push the speaker and their words as one entry
    //   speakerSegments.startTime = timeConverter(speakerSegments.words[0].startTime);
    //   segments.push(speakerSegments);
    // }
    return segments;
  };

