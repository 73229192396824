import React from 'react';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { useState, useEffect } from 'react';
import { useAuth } from '../../services/authContext';
import { getModelTranscriptionResource1, getUserTeamsRecordings, getUserMsId } from '../../services/api';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TableContainer, Paper, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { getCookie } from "components/Utils/cookies";
import 'rc-tree/assets/index.css';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';
import 'rc-tree/assets/index.css';


const ImportTeams = ({ isOpenImportTeams, handleCloseImportTeams }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userTeamRecordingOption, setUserTeamRecordingOption] = useState([]);
  const [selecteduserTeamRecordingOption, setSelecteduserTeamRecordingOption] = useState('');
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setUpLoading] = useState(false);

  const LoadingIndicator = () => (
    <div className="uploading-indicator">
      {isUploading && <p style={{ fontWeight: 'bolder', fontSize: '20px' }}>Uploading<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>}
    </div>
  );

  const fetchModels = async () => {
    try {
      setLoading(true);
      const modelsData = await getModelTranscriptionResource1();
      const sortedModels = modelsData.sort((a, b) =>
        b.displayName.localeCompare(a.displayName)
      );

      setModels(sortedModels);
      console.log("Fetched models:", sortedModels);
      setSelectedModel(sortedModels[2]?.self || '');
      console.log("Selected model:", selectedModel);
    } catch (error) {
      console.error("Error fetching models:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRecordedTeamsMeeting = async () => {
    let isMsLoggedIn = localStorage.getItem('isMSAccountLoggedIn');
    setLoading(true);

    try {
      if (isMsLoggedIn === 'true') {
        let token = localStorage.getItem('msToken');
        console.log(token);

        let teamsRecording = await getUserTeamsRecordings(token);
        console.log(teamsRecording);

        if (teamsRecording !== 'itemNotFound') {
          const userTeamRecordingDetails = await Promise.all(
            teamsRecording.value.map(async (item) => {
              const organizer = await getUserMsId(token, item.source?.meetingOrganizerId);

              return {
                name: item.name,
                size: item.size,
                date: item.createdDateTime,
                organizerId: item.source?.meetingOrganizerId || "Unknown",
                organizerName: organizer?.displayName || "Unknown",
                organizerEmail: organizer?.mail || "Unknown",
                webUrl: item['@microsoft.graph.downloadUrl']
              };
            })
          );

          setUserTeamRecordingOption(userTeamRecordingDetails);
          console.log("Final User Team Recordings:", userTeamRecordingDetails);
        } else {
          console.log("Recordings folder not found.");
        }
      } else {
        console.log("Microsoft account not logged in.");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // const fetchRecordedTeamsMeeting = async () => {
  //   let isMsLoggedIn = localStorage.getItem('isMSAccountLoggedIn');

  //   setLoading(true);
  //   try {

  //     if (isMsLoggedIn == 'true') {
  //       let token = localStorage.getItem('msToken');
  //       let teamsRecording = await getUserTeamsRecordings(token);

  //       if (teamsRecording != 'fetchRecordedTeamsMeeting') {
  //         console.log(teamsRecording);

  //         const userTeamRecordingDetails = teamsRecording.value.map((item) => ({
  //           name: item.name,
  //           size: item.size,
  //           webUrl: item['@microsoft.graph.downloadUrl']

  //         }));

  //         setUserTeamRecordingOption(userTeamRecordingDetails);

  //       }
  //       else {
  //         console.log("Recordings folder not found.");
  //       }
  //     }
  //     else {
  //       console.log("Microsoft account not logged in.");
  //     }
  //   }
  //   catch (err) {
  //     console.error(err);
  //   }
  //   finally {
  //     setLoading(false);
  //   }
  // }


  useEffect(() => {
    fetchModels();

    fetchRecordedTeamsMeeting();
    console.log("User Team Recordings:", userTeamRecordingOption);
  }, []);
  const uploadUserTeamsRecording = async () => {
    setUpLoading(true);
    console.log("Selected user team recording:", selecteduserTeamRecordingOption);
    const token = getCookie('jwtToken');
    const completeResponse = await fetch(process.env.REACT_APP_API_URL + "/api/Upload/CompleteUpload", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        FileName: selecteduserTeamRecordingOption.name,
        FileSize: selecteduserTeamRecordingOption.size,
        AudioTitleName: "My Audio Title",
        UserEmail: user.email,
        UserName: user.name,
        CustomModel: selectedModel,
        FolderId: null,
        IsUploadFromOneDrive: true,
        FileUrl: selecteduserTeamRecordingOption.webUrl
      })
    });

    console.log("Complete response1:", completeResponse);
    if (!completeResponse.ok) {
      handleCloseImportTeams();
      console.error("Failed to complete upload");
      return;
    }
    const responseData = await completeResponse.json();
    console.log("Complete response:", responseData);
    setUpLoading(false);
    setUploadProgress(0);
    handleCloseImportTeams();
    return { url: 'https://httpbin.org/post' };
    //call api to upload it
  }

  return (
    <Modal
      open={isOpenImportTeams}
      onClose={handleCloseImportTeams}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropProps={{
        onClick: (e) => e.stopPropagation(),
      }}
    >

      <div className="modalContainer">
        <div className="modalContentUpload" style={{ width: "60%", background: "#FFFF", borderRadius: "20px", maxWidth: "600px", left: "56%", top: "45vh" }}>

          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
            <div>
              <span className='upload-modal-header'>Import Teams</span>
            </div>
            <div>
              <button onClick={handleCloseImportTeams} className="modalBtnClose" style={{ fontSize: "30px", color: "rgb(57 56 56)" }}>
                &times;
              </button>
            </div>
          </div>
          <div style={{ margin: "20px" }}>
            <div className='select-model-upload'>
              <FormControl fullWidth margin="normal">
                <InputLabel id="modelSelectLabel">Select Custom Model</InputLabel>
                <Select
                  labelId="modelSelectLabel"
                  id="modelSelect"
                  value={selectedModel}
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  {loading ? (
                    <MenuItem disabled>
                      <CircularProgress size={24} style={{ marginRight: '10px' }} />
                      Loading models...
                    </MenuItem>
                  ) : (
                    models.map((model) => (
                      <MenuItem key={model.self} value={model.self}>
                        {model.displayName}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div style={{ margin: "20px" }}>

            <FormControl fullWidth margin="normal">
              <div style={{ display: 'none' }}>
                <InputLabel id="teamRecordingsSelectLabel">Select Teams Recording</InputLabel>
                {/* <Select
                value={selecteduserTeamRecordingOption}
                onChange={(e) => setSelecteduserTeamRecordingOption(e.target.value)}
                labelId="teamRecordingsSelectLabel">

                {loading ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} style={{ marginRight: '10px' }} />
                    Loading team recordings...
                  </MenuItem>
                ) : (

                  userTeamRecordingOption.map((teamRecording, index) => (
                    <MenuItem key={index} value={teamRecording}>
                      {teamRecording.name}
                    </MenuItem>
                  ))

                )}
              </Select> */}

                <Select
                  value={selecteduserTeamRecordingOption ? JSON.stringify(selecteduserTeamRecordingOption) : ""}
                  onChange={(e) => setSelecteduserTeamRecordingOption(JSON.parse(e.target.value))}
                  labelId="teamRecordingsSelectLabel"
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) return "";
                    const selectedObject = JSON.parse(selected);
                    return selectedObject.name; // ✅ Display only file name after selection
                  }}
                >
                  {/* Table Header inside dropdown */}
                  <MenuItem disabled style={{ fontWeight: '500', display: 'flex', justifyContent: 'space-between', background: '#dbeff5', fontSize: '14px' }}>
                    <span style={{ width: '100px' }}>Organizer</span>
                    <span style={{ width: '100px' }}>Subject</span>
                    <span style={{ width: '100px' }}>File Size</span>
                    <span style={{ width: '100px' }}>Date</span>
                    <span style={{ width: '100px' }}>File Name</span>
                  </MenuItem>

                  {/* List of Team Recordings */}
                  {userTeamRecordingOption.map((teamRecording, index) => (
                    <MenuItem
                      key={index}
                      value={JSON.stringify(teamRecording)} // Store full object as a string
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background:
                          selecteduserTeamRecordingOption && selecteduserTeamRecordingOption.name === teamRecording.name
                            ? "#f0f0f0"
                            : "white"
                      }}
                    >
                      <span style={{ marginLeft: '-5px', width: '100px', whiteSpace: 'pre-wrap', fontSize: '12px' }}>{teamRecording.organizerName}</span>
                      <span style={{ marginLeft: '-5px', width: '100px', whiteSpace: 'pre-wrap', fontSize: '12px' }}>{teamRecording.name.split('-')[0]}</span>
                      <span style={{ marginLeft: '-5px', width: '100px', whiteSpace: 'pre-wrap', fontSize: '12px' }}>{(teamRecording.size / (1024 * 1024)).toFixed(2)} MB</span>
                      <span style={{ marginLeft: '-5px', width: '100px', whiteSpace: 'pre-wrap', fontSize: '12px' }}>
                        {teamRecording.date
                          ? new Date(teamRecording.date).toISOString().split('T')[0]
                          : "N/A"}
                      </span>
                      <span style={{ marginLeft: '-5px', width: '100px', whiteSpace: 'pre-wrap', fontSize: '12px' }}>{teamRecording.name || "N/A"}</span>
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <div style={{ maxHeight: "50vh", height: "49vh", overflowY: "auto" }}>
                <TableContainer component={Paper}>
                  <Table size="small">
                    {/* Table Header */}
                    <TableHead>
                      <TableRow style={{ background: "#dbeff5", fontWeight: "bold" }}>
                        <TableCell className='tableHeaderImportTeams'>Organizer</TableCell>
                        <TableCell className='tableHeaderImportTeams'>Subject</TableCell>
                        <TableCell className='tableHeaderImportTeams'>File Size</TableCell>
                        <TableCell className='tableHeaderImportTeams'>Date</TableCell>
                      </TableRow>
                    </TableHead>

                    {/* Table Body */}
                    <TableBody>
                      {userTeamRecordingOption.map((teamRecording, index) => (
                        <TableRow
                          key={index}
                          onClick={() => setSelecteduserTeamRecordingOption(teamRecording)}
                          style={{
                            cursor: "pointer",
                            background:
                              selecteduserTeamRecordingOption?.name === teamRecording.name
                                ? "#f0f0f0"
                                : "white"
                          }}
                        >
                          <TableCell className='tableCellImportTeams'>{teamRecording.organizerName}</TableCell>
                          <TableCell className='tableCellImportTeams'>{teamRecording.name.split("-")[0]}</TableCell>
                          <TableCell className='tableCellImportTeams' style={{ width: '70px' }}>{(teamRecording.size / (1024 * 1024)).toFixed(2)} MB</TableCell>
                          <TableCell className='tableCellImportTeams' style={{ width: '70px' }}>
                            {teamRecording.date
                              ? new Date(teamRecording.date).toLocaleDateString('en-GB') // Formats as DD/MM/YYYY
                              : "N/A"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>


              <Button
                style={{
                  marginTop: '20px',
                  backgroundColor: '#009688',
                  color: '#FAFAFA',
                  display: isUploading ? 'none' : ''
                }}
                variant='outlined'
                onClick={uploadUserTeamsRecording}
                startIcon={<CloudUpload />}>Upload</Button>
            </FormControl>
          </div>
          <div>{isUploading && <LoadingIndicator />}</div>
          {uploadProgress > 0 && uploadProgress < 100 && (
            <div style={{ marginTop: '10px' }}>
              <progress value={uploadProgress} max={100} style={{ width: '100%' }}>
                {uploadProgress}%
              </progress>
              <span>{uploadProgress}%</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

ImportTeams.propTypes = {
  isOpenImportTeams: PropTypes.bool.isRequired,
  handleCloseImportTeams: PropTypes.func.isRequired
};

export default ImportTeams;