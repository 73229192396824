import React, { useEffect, useState } from "react";
import { Button, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { useHistory } from "react-router-dom"; 
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
//  import Warning from "@material-ui/icons/Warning";
import Info from "@material-ui/icons/Info";

import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import { ArrowUpward, ArrowDownward, DragHandle } from "@material-ui/icons";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
// import BugReport from "@material-ui/icons/BugReport";
// import Code from "@material-ui/icons/Code";
// import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Table from "components/Table/Table.js";
// import Tasks from "components/Tasks/Tasks.js";
// import CustomTabs from "components/CustomTabs/CustomTabs.js";
// import Danger from "components/Typography/Danger.js";
// import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import { getDashboardDetails, GetRemainigDaysTrial, CreateCheckoutSession } from "services/api";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";
// import { bugs, website, server } from "variables/general.js";
import { useAuth } from '../../services/authContext';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  useTokenExpirationCheck();
  const { showExpiredModal, handleModalCloseTokenExpired } = useTokenExpirationCheck();
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false);
  const [subscriptionDatav2, setSubscriptionData] = useState(null);
  const [isTrialExpiredModalOpen, setIsTrialExpiredModalOpen] = useState(false);
  const [isSubscriptionExpired, setSubscriptionExpired] = useState(null);

  const graphOptions = {
    low: 0,
    showArea: true
  };

  useEffect(() => {
    if (user === null) {
      setIsLoadingData(true);
    }
    let isMounted = true;
    const initializeData = async () => {
      try {
        let email = user?.email || JSON.parse(localStorage.getItem("user"))?.email || "";
        if (!email) return;
        const subscriptionData = await GetRemainigDaysTrial(email);
        const isTrial = subscriptionData?.subscription?.isTrial;
        const isActiveSubscription = subscriptionData?.subscription?.isActiveSubscription;
        const remaininggDaysTrial = subscriptionData?.remaininggDaysTrial;
        const IsSubscriptionExpired = subscriptionData?.isSubscriptionExpired;
        console.log('userData.subscription.IsTrial:', isTrial);
        setSubscriptionData(subscriptionData);

        const isFirstLoginShown = localStorage.getItem("isFirstLoginShown");

        if (isTrial && !isFirstLoginShown) {
          if (remaininggDaysTrial > 0) {
            localStorage.setItem("isFirstLoginShown", "true");
            localStorage.setItem("isTrial", "true");
            setIsTrialModalOpen(true);
          }
          else {
            setIsTrialExpiredModalOpen(true);
            setIsTrialModalOpen(false);
            localStorage.removeItem("isFirstLoginShown");
          }
        }
        else if (isActiveSubscription) {
          localStorage.setItem("isTrial", "false");
          if (IsSubscriptionExpired) {
            setSubscriptionExpired(true);
            setIsTrialExpiredModalOpen(false);
            setIsTrialModalOpen(false);
          }
        }
        const data = await getDashboardDetails(email);
        if (isMounted) {
          setDashboardData(data);
          setIsLoadingData(false);
        }
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 403) {
          handleModalCloseTokenExpired(true); // Token expired or unauthorized
        } else {
          console.error("An unexpected error occurred:", error);
        }
        console.error("Failed to fetch dashboard data:", error);
      }
    };

    if (user) initializeData();

    return () => {
      isMounted = false;
    };
  }, [user]);
  useEffect(() => {
    console.log("dashboardData updated:", subscriptionDatav2);
    const isFirstLoginShown = localStorage.getItem("isFirstLoginShown");
    const isTrial = subscriptionDatav2?.subscription?.isTrial;
    const isActiveSubscription = subscriptionDatav2?.subscription?.isActiveSubscription;
    const remaininggDaysTrial = subscriptionDatav2?.remaininggDaysTrial;
    const IsSubscriptionExpired = subscriptionDatav2?.isSubscriptionExpired;
    if (isTrial && !isFirstLoginShown) {
      if (remaininggDaysTrial > 0) {
        setIsTrialModalOpen(true);
        localStorage.setItem("isFirstLoginShown", "true");
        localStorage.setItem("isTrial", "true");
      }
      else {
        setIsTrialExpiredModalOpen(true);
        localStorage.removeItem("isFirstLoginShown");
      }
    }
    else if (isActiveSubscription) {
      localStorage.setItem("isTrial", "false");
      if (IsSubscriptionExpired) {
        setSubscriptionExpired(true);
      }
    }
  }, [subscriptionDatav2]);
  if (!user) return <CircularProgress />;
  const handleCloseTrialModal = () => {
    setIsTrialModalOpen(false);
  }
  const handleCloseTrialExpiredModal = () => {
    setIsTrialExpiredModalOpen(false);
    localStorage.removeItem('isFirstLoginShown');

    localStorage.removeItem('isMSAccountLoggedIn');
    localStorage.removeItem('msToken');

    setTimeout(() => {
      history.push("/login"); 
    }, 100); 
  }
  const handleCloseisSubscriptionExpired = () => {
    setSubscriptionExpired(false);
  }
  const Subscribe = async () => {
    try {
      const sessionUrl = await CreateCheckoutSession(user.email); 
      window.location.href = sessionUrl; 
      setSubscriptionExpired(false);
      setIsTrialExpiredModalOpen(false);
    } catch (error) {
      console.error("Error during subscription:", error);
      alert("Failed to initiate subscription. Please try again later.");
    }
  };
  return (
    <div>
      {isLoadingData && (
        <div style={{ top: '40%', left: '50%', zIndex: 99999, position: 'absolute' }}>
          <CircularProgress size={100} />
        </div>
      )}
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Used Space</p>
              <h3 className={classes.cardTitle}>
                {dashboardData ?
                  (
                    <span>{dashboardData.totalUsedStorageSpace}/5 <small>GB</small></span>
                  ) :
                  (
                    <LinearProgress />
                  )}

                {/* azure storage account type Standard V2 size is 5PB*/}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Info />
                User available space
                {/* <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Get more space
                </a> */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Saved Time</p>
              {dashboardData ? (
                <h3 className={classes.cardTitle}>{dashboardData.totalSavedTime}</h3>
              )
                :
                (
                  <LinearProgress />
                )}

            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                All Time
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Transcribed Files</p>
              <h3 className={classes.cardTitle}>
                {dashboardData ?
                  (
                    <span>{dashboardData.totalFiles}</span>
                  ) :
                  (
                    <LinearProgress />
                  )}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                This Month
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Shared Files</p>
              <h3 className={classes.cardTitle}>N/A</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                {/* Just Updated */}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="success">
              {/* <ChartistGraph
                className="ct-chart"
                data={dailySalesChart.data}
                type="Line"
                options={dailySalesChart.options}
                listener={dailySalesChart.animation}
              /> */}
              {dashboardData ? (
                <ChartistGraph
                  className="ct-chart"
                  data={dashboardData.dailyTranscription}
                  type="Line"
                  options={graphOptions}
                  listener={dailySalesChart.animation}
                />
              ) :
                (
                  <CircularProgress color="inherit" />
                )}
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Transcriptions</h4>
              <p className={classes.cardCategory}>
                {/* <span className={classes.successText}>
                  <ArrowUpward className={classes.upArrowCardCategory} /> 55%
                </span>{" "}
                increase in today transcriptions. */}
                {dashboardData ?
                  (
                    dashboardData.dailyTranscription.isEqualValue ?
                      (<span className={classes.defaultText}>
                        <DragHandle className={classes.upArrowCardCategory} /> No movement in today transcriptions.
                      </span>)
                      :
                      dashboardData.dailyTranscription.increased ?
                        (<span className={classes.successText}>
                          <ArrowUpward className={classes.upArrowCardCategory} /> {dashboardData.dailyTranscription.value}% increase in today transcriptions.
                        </span>
                        )
                        :
                        (
                          <span className={classes.dangerText}>
                            <ArrowDownward className={classes.upArrowCardCategory} /> {dashboardData.dailyTranscription.value}% decrease in today transcriptions.
                          </span>
                        )
                  ) :
                  ''
                }

              </p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> updated a minute ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="warning">
              {/* <ChartistGraph
                className="ct-chart"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              /> */}
              {dashboardData ? (
                <ChartistGraph
                  className="ct-chart"
                  data={dashboardData.monthlyTranscription}
                  type="Bar"
                  options={graphOptions}
                  responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                  listener={emailsSubscriptionChart.animation}
                />
              ) :
                (
                  <CircularProgress color="inherit" />
                )}
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Monthly Transcriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent a minute ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card chart>
            <CardHeader color="danger">
              {dashboardData ? (
                <ChartistGraph
                  className="ct-chart"
                  // data={completedTasksChart.data}
                  data={dashboardData.completedWords}
                  type="Line"
                  options={graphOptions}
                  // options={completedTasksChart.options}
                  listener={completedTasksChart.animation}
                />
              ) :
                (
                  <CircularProgress color="inherit" />
                )}

            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Completed Words</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> campaign sent a minute ago
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {/* <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: "My Tasks",
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                ),
              },
              {
                tabName: "Team Tasks",
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                ),
              },
              {
                tabName: "Company Tasks",
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                ),
              },
            ]}
          />
        </GridItem> */}
        {/* <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Pending Transcriptions</h4>
              <p className={classes.cardCategoryWhite}>
                Assigned in the last 30 days
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={["ID", "Description", "Assigned To", "Until", "Status"]}
                tableData={[
                  ["1", "Trascribe interview with John", "Dakota Rice", "30.09.2024", "Active"],
                  ["2", "Translate interview with Stefanie", "Dakota Rice", "01.10.2024", "Active"],
                  ["3", "Summarize interview with Wesley", "Dakota Rice", "03.10.2024", "Active"],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem> */}
        <div>
          {isTrialModalOpen && <Modal
            open={isTrialModalOpen}
            onClose={handleCloseTrialModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
              onClick: (e) => e.stopPropagation(),
            }}
          >
            <div className="modalContent"
              style={{
                width: '400px',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                margin: 'auto',
              }}>
              <div>
              </div>
              <div>
                <div style={{ marginBottom: '10px', width: '100%', backgroundColor: '#ededed', padding: '1px', borderRadius: '8px' }}>
                  <div className="remaining-days-container">
                    <div style={{ textAlign: 'center', fontSize: '40px', color: '#44c7f5', marginTop: '15px' }}> {subscriptionDatav2.remaininggDaysTrial}</div>
                    <div style={{ textAlign: 'center', color: '#4f6675', fontSize: '15px' }}><p>days left.</p></div>
                  </div>
                  <div>
                    <Typography variant="h6" style={{ textAlign: 'center', color: '#4f6675', fontSize: '11px', textTransform: 'capitalize' }}>Click <b>Subscribe</b> to upgrade your account.</Typography>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button className="btnUpload" variant="contained" onClick={Subscribe} >
                  Subscribe
                </Button>
                <Button className="btnUpload" variant="contained" onClick={handleCloseTrialModal} >
                  Continue Trial
                </Button>
              </div>
            </div>
          </Modal>
          }
        </div>
        <div>
          {isTrialExpiredModalOpen && <Modal
            open={isTrialExpiredModalOpen}
            onClose={handleCloseTrialExpiredModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
              onClick: (e) => e.stopPropagation(),
            }}
          >
            <div className="modalContent"
              style={{
                width: '300px',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                margin: 'auto',
              }}>
              <div>
              <button onClick={handleCloseTrialExpiredModal} className="modalBtnClose" style={{ fontSize: "30px", color: "rgb(57 56 56)" }}>
                &times;
              </button>
              </div>
              <div >
                <div style={{ marginBottom: '10px', width: '100%', backgroundColor: '#ededed', padding: '1px', borderRadius: '8px',marginTop:'20px' }}>
                  <div >
                    <div style={{ textAlign: 'center', color: '#4f6675', fontSize: '15px' }}><p>Trial subscription Expired</p></div>
                    <div style={{ textAlign: 'center', color: '#4f6675', fontSize: '15px' }}><p>Click <b>Subscribe</b> to upgrade your account.</p></div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="btnUpload" variant="contained" onClick={Subscribe} >
                  Subscribe
                </Button>
              </div>
            </div>
          </Modal>
          }
        </div>

        <div>
          {isSubscriptionExpired && <Modal
            open={isSubscriptionExpired}
            onClose={handleCloseisSubscriptionExpired}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            BackdropProps={{
              onClick: (e) => e.stopPropagation(),
            }}
          >
            <div className="modalContent"
              style={{
                width: '300px',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                margin: 'auto',
              }}>
              <div>
              </div>
              <div >
                <div style={{ marginBottom: '10px', width: '100%', backgroundColor: '#ededed', padding: '1px', borderRadius: '8px' }}>
                  <div >
                    <div style={{ textAlign: 'center', color: '#4f6675', fontSize: '15px' }}><p>Subscription Expired!</p></div>
                    <div style={{ textAlign: 'center', color: '#4f6675', fontSize: '15px' }}><p>Click <b>Subscribe</b> and renew your subscription.</p></div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button className="btnUpload" variant="contained" onClick={Subscribe} >
                  Subscribe
                </Button>
              </div>
            </div>
          </Modal>
          }
        </div>
        {showExpiredModal && (
          <div className="modaltokenexpire">
            <div className="modal-contenttokenexpire">
              <h2>Session Expired</h2>
              <p>Your session has expired. Please log in again to continue.</p>
              <button onClick={handleModalCloseTokenExpired}>Ok</button>
            </div>
          </div>
        )}

      </GridContainer>
    </div>
  );
}