import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Typography } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss'
import { parseTranscription } from 'components/Utils/ParseTranscription';


const DownloadTranscirption = ({ isOpenDownload, handleCloseDownload, selectedFile, tableData }) => {
  const [isToggledLines, setIsToggledLines] = useState(false);
  const [isToggledSpeaker, setIsToggledSpeaker] = useState(true);
  const [isToggledTimeStamp, setIsToggledTimeStamp] = useState(false);

  useEffect(() => {
    if (!isOpenDownload) {
      setIsToggledLines(false);
      setIsToggledSpeaker(true);
      setIsToggledTimeStamp(false);
    }
  }, [isOpenDownload]);

  const handleToggleLines = () => {
    setIsToggledLines(!isToggledLines);
  };
  const handleToggleIncludeSpeaker = () => {
    setIsToggledSpeaker(!isToggledSpeaker);
  };
  const handleToggleIncludeTimeStamp = () => {
    setIsToggledTimeStamp(!isToggledTimeStamp);
  };

  const exportTranscription = async () => {
    if (isToggledLines) {
      handleDownloadTranscriptionV2();
    }
    else {
      handleDownloadTranscription();

    }
  }
  const handleDownloadTranscription = () => {
    if (selectedFile && selectedFile.transcriptions.length > 0) {
      const transdata = tableData.map((row) => row[6].transcriptions[0]);
      const filteredTranscription = transdata.filter((transcription) => transcription.id === selectedFile.transcriptions[0].id);
      console.log('Filtered Transcription:', filteredTranscription);
      const rawDetails = filteredTranscription[0].rawDetails;
      const transcriptionWords = selectedFile.transcriptions[0].transcriptionWords;
      console.log('transcriptionWords:', transcriptionWords);
      const parsedSegments = parseTranscription(rawDetails, transcriptionWords);

      const docChildren = [];
      let lastSpeaker = '';
      let combinedText = '';
      // Helper function to format the paragraph text based on toggle settings
      const formatText = (speaker, text, timeStamp) => {
        let formattedText = '';
        if (isToggledTimeStamp) {
          formattedText += `[${timeStamp}] `;
        }
        if (isToggledSpeaker) {
          formattedText += `${speaker}: `;
        }
        formattedText += text;
        return formattedText;
      };
      // Helper function to add paragraphs to docChildren
      const addParagraphToDoc = (text) => {
        const paragraph = new Paragraph({
          children: [new TextRun({ text, bold: false })],
          spacing: { after: 200 },
        });
        docChildren.push(paragraph);
      };
      parsedSegments.forEach((segment, index) => {
        //console.log('Segment:', segment);
        const timeStamp = segment.startTime
        if (segment.speakerOnly === lastSpeaker) {
          combinedText += ' ' + segment.speakerText;
        } else {
          if (lastSpeaker !== '') {
            const textToAdd = formatText(lastSpeaker, combinedText, timeStamp);
            addParagraphToDoc(textToAdd);
          }
          lastSpeaker = segment.speakerOnly;
          combinedText = segment.speakerText;
        }
        if (index === parsedSegments.length - 1) {
          const textToAdd = formatText(lastSpeaker, combinedText, timeStamp);
          addParagraphToDoc(textToAdd);
        }
      });
      const doc = new Document({
        sections: [{
          properties: {},
          children: docChildren,
        }],
      });

      const now = new Date();
      const fileName = selectedFile.audioTitleName;
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
      const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      const uniqueFileName = `${fileName}_transcription_${formattedDate}_${formattedTime}.docx`;

      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, uniqueFileName);
      }).catch((error) => {
        console.error("Error creating document:", error);
      });
    } else {
      console.error("No transcription available");
    }
  };
  const handleDownloadTranscriptionV2 = () => {
    if (selectedFile && selectedFile.transcriptions.length > 0) {
      const transdata = tableData.map((row) => row[6].transcriptions[0]);
      const filteredTranscription = transdata.filter((transcription) => transcription.id === selectedFile.transcriptions[0].id);
      const rawDetails = filteredTranscription[0].rawDetails;
      const transcriptionWords = selectedFile.transcriptions[0].transcriptionWords;
      const parsedSegments = parseTranscription(rawDetails, transcriptionWords);
      const docChildren = [];
      parsedSegments.forEach((segment) => {
        // const textContent = isToggledSpeaker
        // ? `${segment.speakerOnly}: ${segment.speakerText}`
        // : segment.speakerText;
        const textContent = `${isToggledTimeStamp ? `[${segment.startTime}] ` : ''}${isToggledSpeaker ? `${segment.speakerOnly}: ` : ''}${segment.speakerText}`;

        const combinedParagraph = new Paragraph({
          children: [
            new TextRun({
              text: textContent,
              bold: false,
            }),
          ],
          spacing: { after: 200 },
        });
        docChildren.push(combinedParagraph);
      });
      const doc = new Document({
        sections: [{
          properties: {},
          children: docChildren,
        }],
      });
      const now = new Date();
      const fileName = selectedFile.audioTitleName;
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
      const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;
      const uniqueFileName = `${fileName}_transcription_${formattedDate}_${formattedTime}.docx`;
      Packer.toBlob(doc).then((blob) => {
        saveAs(blob, uniqueFileName);
      }).catch((error) => {
        console.error("Error creating document:", error);
      });
    } else {
      console.error("No transcription available");
    }
  };

  return (
    <div>
      <Modal
        open={isOpenDownload}
        onClose={handleCloseDownload}
        aria-labelledby="export-modal-title"
        aria-describedby="export-modal-description"
      >
        <div
          className="modalContent"
          style={{
            width: '300px',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            margin: 'auto',
          }}
        >
          {/* Close Button */}
          <button
            onClick={handleCloseDownload}
            className="modalBtnClose"
          >
            &times;
          </button>

          {/* Title */}
          <Typography variant="h6" component="h2" style={{ marginBottom: '20px', textAlign: 'center', fontWeight: 'bold' }}>
            Export Transcription
          </Typography>

          {/* Options Section */}
          <Box sx={{ display: 'block', justifyContent: 'space-between', alignItems: 'center', margin: '10px' }}>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="switchToggledLines"
                checked={isToggledLines}
                onChange={handleToggleLines}
              />
              <label htmlFor="switchToggledLines" className="switch-label">
                <span className="switch-button"></span>
              </label>
              <p> Separate lines</p>
            </div>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="switchToggleSpeaker"
                checked={isToggledSpeaker}
                onChange={handleToggleIncludeSpeaker}
              />
              <label htmlFor="switchToggleSpeaker" className="switch-label">
                <span className="switch-button"></span>
              </label>
              <p> Include Speaker Label</p>
            </div>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="switchToggleTimeStamp"
                checked={isToggledTimeStamp}
                onChange={handleToggleIncludeTimeStamp}
              />
              <label htmlFor="switchToggleTimeStamp" className="switch-label">
                <span className="switch-button"></span>
              </label>
              <p> Include TimeStamp</p>
            </div>
          </Box>

          {/* Download Button */}
          <Button
            variant="contained"
            startIcon={<GetAppIcon />}
            onClick={exportTranscription}
            style={{
              backgroundColor: '#1e771e',
              color: '#fff',
              fontSize: '14px',
              marginTop: '30px',
              width: '100%',
              padding: '10px',
              textTransform: 'capitalize',
            }}
          >
            Download Transcription
          </Button>
        </div>
      </Modal>
    </div>
  );
};
DownloadTranscirption.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpenDownload: PropTypes.bool.isRequired,
  handleCloseDownload: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
  tableData: PropTypes.array,
};
export default DownloadTranscirption;