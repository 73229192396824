import React,{useEffect, useState} from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import { PlayArrow } from "@material-ui/icons";
import '../../assets/css/audiolystyles.css';
import '../../assets/css/styles.scss';
// @material-ui/icons
//import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import { useAuth } from '../../services/authContext';
import {Log,CreateCheckoutSession,GetRemainigDaysTrial} from "services/api";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const { user } = useAuth();
  const classes = useStyles();
  const [openNotification, setOpenNotification] = useState(null);
  const [openProfile, setOpenProfile] = useState(null);
  const [isTrial, setIsTrial] = useState(false);
  // const handleClickNotification = (event) => {
  //   if (openNotification && openNotification.contains(event.target)) {
  //     setOpenNotification(null);
  //   } else {
  //     setOpenNotification(event.currentTarget);
  //   }
  // };
  const storedIsTrial = localStorage.getItem("isTrial");
  useEffect(() => {
    console.log("user",user)
    const initializeData = async () => {
          const subscriptionData = await GetRemainigDaysTrial(user.email);
          const isTrial = subscriptionData?.subscription?.isTrial;
    console.log("subscriptionData", isTrial);
    if (isTrial === true || isTrial === "true") {
      setIsTrial(true);
    }
    else{ setIsTrial(false);}
    }
    if (user) initializeData();
  }, [storedIsTrial,isTrial,user]);
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogout = async () => {
    // Perform logout logic here, e.g., clearing tokens, etc.
    // Then redirect to the login page
    const payload = 
    {
      id: 0,
      action: 'Logout',
      details: 'Email:' + user.email,
      statusDetails: 'Success',
      email: user.email
    };
    await Log(payload);

    window.location.href = "/login";
    localStorage.removeItem('isFirstLoginShown');
    localStorage.removeItem('isMSAccountLoggedIn');
    localStorage.removeItem('msToken');
    localStorage.removeItem('isTrial');
  };

  const handleGoToSettings = () => {
    window.location.href = "/admin/user";
  };
  const Subscribe = async () => {
    try {
      const sessionUrl = await CreateCheckoutSession(user.email); // Get the URL from the backend
      window.location.href = sessionUrl; // Redirect the user
    } catch (error) {
      console.error("Error during subscription:", error);
      alert("Failed to initiate subscription. Please try again later.");
    }
  };
  return (
    <div>
      <div className={classes.manager}>
        <Poppers
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openNotification }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      John Smith accessed your XYZ transcription
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseNotification}
                      className={classes.dropdownItem}
                    >
                      You have 5 new transcriptions
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>

      <div className={classes.manager}>
        {isTrial && <Button  startIcon={<PlayArrow style={{marginRight:'2px'}}/>} className="subscribBtn" variant="contained" onClick={Subscribe}>Subscribe Now</Button>}
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <a href="" onClick={(e) => e.preventDefault()} style={{paddingRight: '17px', marginLeft: '-16px'}}>
            <img src={user?.profilePicture} alt="..." style={{height:'25px',borderRadius:'50%'}} />
          </a>
          <Hidden mdUp implementation="css">
            <p className={classes.linkText} style={{textTransform: 'none'}}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleGoToSettings}
                      className={classes.dropdownItem}
                    >
                      Settings
                    </MenuItem>
                    <Divider light />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
